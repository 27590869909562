import React from 'react';
import './calendarSetup.scss';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { TreatmentLog } from '../../../models';
import { Treatment } from '../../../types';
import { uuidv4 } from '../../../util/helpers';
import { withSession } from '../../shared/global';
import { withSessionProp } from '../../shared/global/withSession';
import { FrequencyUnit } from '../../../types/enums';
import { RATING_PULSE_VALUES } from '../../../util';

type CalendarDayBlockProps = {
  treatmentLog: TreatmentLog;
  currentDisplayMonth: moment.Moment;
  dayNumber: number;
  onClick: (log: TreatmentLog) => void;
};

const CalendarDayBlock: React.FC<CalendarDayBlockProps & withSessionProp> = ({
  treatmentLog: log,
  currentDisplayMonth,
  dayNumber,
  onClick,
  session: [sessionInfo],
}) => {
  const {
    cycleDayNumber,
    isUnavailable,
    isTemporaryLog,
    isWithinPeriodMargin,
    isExpectedBleedPeriod,
    isFrozen,
  } = log;

  const todaysDate = moment().startOf('day');
  const isFutureLog = moment.utc(log.date).isSameOrAfter(todaysDate);

  // TODO: is there a more efficient way
  const isSameMonth = todaysDate.month() === currentDisplayMonth.month();
  const isSameYear = todaysDate.year() === currentDisplayMonth.year();
  const todaysNumDate = +todaysDate.format('D');

  const treatmentBlocks = log
    .sortTreatments()
    // Use Frequency 0 to filter out treatments that arent in use
    .filter((t: Treatment) => t.frequency > 0)
    .map((l: Treatment) => (
      <div className="block-text" key={uuidv4()} data-substance={l.substance}>
        {l.substance[0].toUpperCase()}
        {l.frequencyUnit !== FrequencyUnit.day && (
          <>({l.frequencyUnit})</>
        )}= {l.dosage}
      </div>
    ));

  if (isFrozen || isUnavailable) {
    return (
      <div
        className={classNames('day calendar-block', {
          today: todaysNumDate === dayNumber && isSameMonth && isSameYear,
          unavailable: isUnavailable,
          'frozen-day': isFrozen,
        })}
      >
        <div className="calendar-text">
          <span className="block-day">{dayNumber}</span>
          <div className="block-text">
            {isFrozen && <i className="fas fa-lock" />}

            {isUnavailable && 'N/A'}
          </div>
        </div>
        <div className="calendar-highlights" />
      </div>
    );
  }

  const emoji = RATING_PULSE_VALUES.find(([number]) => number === log.pulse);

  return (
    <div
      className={classNames('day calendar-block', {
        today: todaysNumDate === dayNumber && isSameMonth && isSameYear,
        saved: !isTemporaryLog,
        'period-margin': isWithinPeriodMargin,
      })}
      // eslint-disable-next-line react/no-array-index-key
      onClick={() => onClick(log)}
    >
      <div className="calendar-text">
        <div className="indicators">
          <span className="top-right">
            {(log.patientNotes || log.doctorNotes) && (
              <i className="fa fa-sticky-note note" />
            )}
            {sessionInfo.canAccessAdditionalViews &&
              log.hasOneTimeDosageChanges && (
                <i className="fa fa-bolt one-time-change" />
              )}
            {log.hasBloodDrawAppointment && (
              <i className="fas fa-syringe blood-draw" />
            )}
          </span>
          <span className="top-left">
            {!!log.symptoms.length && <span className="symptom" />}
          </span>
          <span className="bottom-left">
            {!isTemporaryLog && <i className="fas fa-save saved-log" />}
            {!!log.pulse && emoji && <i className={`${emoji[1]}`} />}
          </span>
          <span className="bottom-right" />
        </div>
        <span className="block-day">{dayNumber}</span>
        <div className="block-text">
          {/* The special intermediatary days between cycles */}
          Day {cycleDayNumber === 0 ? '1*' : cycleDayNumber}
        </div>
        {treatmentBlocks}
      </div>
      <div className="calendar-highlights">
        {!!log.hasPeriod() && <span className="bar period" />}
        {isExpectedBleedPeriod && isFutureLog && (
          <span className="bar expected-period" />
        )}
      </div>
    </div>
  );
};

export default withSession(CalendarDayBlock);
