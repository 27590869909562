import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  onSuccessToast,
  onApiErrorToast,
} from '../../../services/toasts.service';
import { downloadJSONAsCSV } from '../../../util/helpers';
import { deleteAccount } from '../../../services';
import { User } from '../../../models';
import { Button, ConfirmModal } from '../../shared';
import { Modal } from '../../shared/modal/modal';
import { WhyAreYouLeaving } from './whyAreYouLeaving';

type DeleteAccountProps = {
  user: User;
};

export const DeleteAccount: React.FC<DeleteAccountProps> = ({ user }) => {
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const [isDeleteFinalConfirmModalOpen, setIsDeleteFinalConfirmModalOpen] =
    useState(false);
  const [showReasonsWhyModal, setShowReasonsWhyModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const deleteUserAccount = (reasonsWhy?: string[], explained?: string) => {
    setIsDeleting(true);
    deleteAccount(user.getId(), reasonsWhy, explained)
      .then((logs) => {
        if (user.isPatient() && user.hasCompletedRegistration) {
          const filename = `Full Treatment History ${moment().format(
            'YYYY-MM-DD'
          )}.csv`;

          downloadJSONAsCSV(
            logs!.logs!.map((l) => l.convertToCSVReadable()),
            logs!.fields!,
            filename
          );
        }
        onSuccessToast('User Account Deleted Successfully');
        // Move them back to login cuz this user no more
        navigate('/logout');
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Button
        isLoading={isDeleting}
        className="danger"
        onClick={() => setIsDeleteAccountModalOpen(true)}
      >
        Delete account and wipe data
      </Button>
      <ConfirmModal
        onCancel={() => {
          setIsDeleteAccountModalOpen(false);
          setIsDeleteFinalConfirmModalOpen(true);
        }}
        onConfirm={() => {
          setIsDeleteAccountModalOpen(false);
        }}
        isOpen={isDeleteAccountModalOpen}
        cancelText="Delete my account"
        confirmText="Go Back"
        title="Are you sure you want to delete?"
        reverseOrder
      >
        <p className="text-centered">
          <strong>Did you mean to cancel your subscription instead?</strong>
        </p>
        <p>
          Cancelling your subscription will pause payments but keep your account
          and data saved in case you need it later.
        </p>
      </ConfirmModal>
      <ConfirmModal
        onCancel={() => {
          setIsDeleteFinalConfirmModalOpen(false);
          setShowReasonsWhyModal(true);
        }}
        onConfirm={() => {
          setIsDeleteFinalConfirmModalOpen(false);
        }}
        isOpen={isDeleteFinalConfirmModalOpen}
        cancelText="Yes, Please Delete My Account"
        confirmText="Go Back"
        title="Permanently Delete my Account"
        reverseOrder
      >
        <p className="text-centered">
          <strong>
            Are you sure you want to permanently delete your account and data?
          </strong>
        </p>
        <p>
          All treatment history, doses, notes and symptoms will be gone forever,
          any remaining time left on your plan will also be lost. Please
          consider downloading your data from your profile before deleting your
          account.
        </p>
      </ConfirmModal>
      <Modal
        isOpen={showReasonsWhyModal}
        onClose={() => setShowReasonsWhyModal(false)}
        title="Before you go"
      >
        <WhyAreYouLeaving
          onSubmit={(reasonsWhy, explained) => {
            setShowReasonsWhyModal(false);
            deleteUserAccount(reasonsWhy, explained);
          }}
          isDeleting
        />
      </Modal>
    </>
  );
};
