import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import {
  getTimedBasedUserAnalytics,
  onApiErrorToast,
} from '../../../../services';
import { SelectOption } from '../../../../types';
import { Graph } from '../../../shared';
import { GraphData } from '../../../../types/apiResponse.types';

type TimeBasedAnalyticsState = {
  usersRegisteredOverTime: GraphData[];
  treatmentChangelogsCreatedOverTime: GraphData[];
  invitedPatientsOverTime: GraphData[];
  startDate: moment.Moment;
  endDate: moment.Moment;
};

const selectOptions: SelectOption<number>[] = [
  {
    value: 1,
    label: 'Last Month',
  },
  {
    value: 3,
    label: 'Last 3 Months',
  },
  {
    value: 6,
    label: 'Last 6 Months',
  },
  {
    value: 12,
    label: 'Last Year',
  },
];

export class TimeBasedAnalytics extends React.Component<
  Record<string, unknown>,
  TimeBasedAnalyticsState
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {
      usersRegisteredOverTime: [],
      treatmentChangelogsCreatedOverTime: [],
      invitedPatientsOverTime: [],
      startDate: moment.utc().subtract(1, 'months').startOf('month'),
      endDate: moment.utc().startOf('month'),
    };
  }

  componentDidMount() {
    this.getUpdatedData();
  }

  getUpdatedData() {
    const { startDate, endDate } = this.state;
    getTimedBasedUserAnalytics({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    })
      .then((data) => {
        this.setState({
          usersRegisteredOverTime: data.usersRegisteredOverTime,
          treatmentChangelogsCreatedOverTime:
            data.treatmentChangelogsCreatedOverTime,
          invitedPatientsOverTime: data.invitedPatientsOverTime,
        });
      })
      .catch((error) => {
        onApiErrorToast(error);
        this.setState({
          usersRegisteredOverTime: [],
        });
      });
  }

  render() {
    const {
      usersRegisteredOverTime,
      treatmentChangelogsCreatedOverTime,
      invitedPatientsOverTime,
    } = this.state;
    return (
      <>
        <label className="date-range">
          <strong>Time Period</strong>
          <Select
            classNamePrefix="react-select"
            className="react-select-container"
            options={selectOptions}
            defaultValue={selectOptions[0]}
            onChange={(option: any) => {
              this.setState(
                {
                  startDate: moment
                    .utc()
                    .subtract(option.value, 'month')
                    .startOf('month'),
                  endDate: moment.utc().startOf('months'),
                },
                () => {
                  this.getUpdatedData();
                }
              );
            }}
          />
        </label>
        <section>
          <Graph
            labels={usersRegisteredOverTime.map((u) =>
              moment.utc(u.date).format("MMM 'YY")
            )}
            datasets={[
              {
                label: 'Registered By Month',
                data: usersRegisteredOverTime.map((u) => u.count),
              },
            ]}
            graphTitle="Users Registered By Month"
          />

          <Graph
            labels={treatmentChangelogsCreatedOverTime.map((u) =>
              moment.utc(u.date).format("MMM 'YY")
            )}
            datasets={[
              {
                label: 'Created By Month',
                data: treatmentChangelogsCreatedOverTime.map((u) => u.count),
              },
            ]}
            graphTitle="Changelogs Created By Month"
          />
          <Graph
            labels={invitedPatientsOverTime.map((u) =>
              moment.utc(u.date).format("MMM 'YY")
            )}
            datasets={[
              {
                label: 'Invited By Month',
                data: invitedPatientsOverTime.map((u) => u.count),
              },
            ]}
            graphTitle="Invited Patients By Month"
          />
        </section>
      </>
    );
  }
}
