import { User } from './user.model';
import { TreatmentType, CalendarType } from '../types';
import { Doctor, BaseModel } from './index';
import { TreatmentSchedule } from './treatmentSchedule.model';

// eslint-disable-next-line no-shadow
export enum PatientStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  TRANSFERRED = 'transferred',
  PENDING = 'pending',
}

export class Patient extends BaseModel {
  user!: User;

  treatmentType!: TreatmentType;

  country!: string;

  state!: string;

  doctorId!: string;

  calendarType!: CalendarType;

  averagePeriodCycleLength!: number;

  averagePeriodDuration!: number;

  lastPeriodDate!: Date;

  hipaaConsentDate!: Date;

  /**
   * Represents how we should determine new cycle day 1s for a personal calendar
   * Can differ from the last period date
   * is only ever set to a new value when a patient saves a log for a cycle day 1 or a doctor resets a log to be a new cycle day 1
   * */
  lastCycleStartDate!: Date;

  doctor?: Doctor;

  // TODO: fill in later
  doctorPatientStatus!: any[];

  createdDate!: Date;

  periodMargin!: number;

  treatmentSchedules?: TreatmentSchedule[];

  selfManagement!: boolean;

  getPatientStatus(): PatientStatus {
    // TODO: double check this against approevd status and multiple statuses
    return this.doctorPatientStatus![0].patientStatus;
  }

  getTreatmentTypeFormatted(): string {
    if (this.treatmentType === TreatmentType.estrogen) {
      return 'bHRT - Women';
    }
    return this.treatmentType;
  }

  hasHipaaConsent(): boolean {
    return !!this.hipaaConsentDate;
  }

  constructor(json?: Patient) {
    super();
    this.country = '';
    this.state = '';
    if (json) {
      const patient = Patient.fromJSON<Patient>(json);
      if (json.user) {
        patient.user = new User(json.user);
      }
      if (json.doctor) {
        patient.doctor = new Doctor(json.doctor);
      }
      return patient;
    }
  }
}
