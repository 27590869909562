import moment from 'moment';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { updateHipaaConsent } from '../../../services/patient.service';
import { setPageTitle } from '../../shared/header/header';
import './hipaaConsent.page.scss';
import { useSessionContext } from '../../shared/global/sessionContext';
import { ElixirSession } from '../../../models';
import {
  onApiErrorToast,
  onSuccessToast,
} from '../../../services/toasts.service';
import { TitleBlock, Checkbox, Button } from '../../shared';

const HipaaConsentPage: React.FC = () => {
  const [sessionInfo, updateSessionContext] = useSessionContext();
  const navigate = useNavigate();
  useEffect(() => {
    setPageTitle('HIPAA Consent');
    document.querySelector('main')!.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);

  const methods = useForm<{
    hipaaConsent: boolean;
  }>();
  const { handleSubmit, watch, setValue } = methods;

  const onSubmit = () => {
    updateHipaaConsent(sessionInfo.patient!.getId())
      .then((result) => {
        const session = new ElixirSession({
          ...sessionInfo,
        });
        session.patient!.hipaaConsentDate = result.date;
        onSuccessToast('Consent Saved.');
        updateSessionContext(session);
        navigate('/onboarding');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  return (
    <section className="hipaa-consent">
      <div className="page-wrapper is-flex-column">
        <TitleBlock title="Your Privacy Matters." />
        <strong>
          AUTHORIZATION, CONSENT, AND RELEASE TO USE AND DISCLOSE HEALTH
          INFORMATION (“Consent”)
        </strong>
        <div className="consent-block">
          <p>
            I authorize my health care provider, whom I identify in the sign-up
            process, and Elixir Health, LLC (“Elixir”) to use and disclose
            health information about me through the ArtemisCalendar application
            (the “App”) that permits communications between and among my health
            care provider, Elixir, and me. The health information to be used,
            disclosed, and shared under this Consent includes my name, contact
            information, date of birth, gender, diagnosis, treatment and dosing
            information, provider notes and instructions, health symptoms,
            appointment and treatment reminders, and other hormone replacement
            therapy information (“Health Information”). The purpose of this
            Consent is to facilitate communications between my health care
            provider and me and is as I request. I acknowledge and agree:
          </p>
          <ul>
            <li>
              I may refuse to sign this Consent. My refusal will not affect my
              treatment, payment for services, enrollment in a health plan, or
              eligibility for benefits, but if I refuse, then I will not be able
              to use the App.
            </li>
            <li>
              I have the right to revoke or withdraw this Consent at any time,
              except for actions already taken in reliance on this Consent. I
              may revoke this Consent by choosing to close my account and delete
              all Health Information and other associated data from the Settings
              page within the App.
            </li>
            <li>
              Unless earlier revoked, this Consent will expire automatically
              when I choose to close my account and delete all Health
              Information and other associated data from the Settings page
              within the App
            </li>
            <li>
              The Health Information shared under this Consent potentially may
              be redisclosed by a recipient and may not be protected by federal
              or state privacy laws, except my health care provider would
              continue to be subject to confidentiality laws.
            </li>
            <li>
              I may have a copy of this Consent after I electronically sign it
              by accessing it from the Settings page within the App. I can view
              the Health Information subject to this Consent through the App.
            </li>
          </ul>
        </div>
        {!sessionInfo.patient?.hasHipaaConsent() && (
          <FormContext {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Checkbox
                isChecked={watch('hipaaConsent')}
                inputName="hipaaConsent"
                isRequired
                reversed
                label={`I have read and understand this Consent. I voluntarily agree to this
            Consent`}
                onChange={(e) => {
                  setValue('hipaaConsent', e.target.checked);
                }}
              />

              <Button
                type="submit"
                className="is-primary"
                isDisabled={!watch('hipaaConsent')}
              >
                Submit
              </Button>
              <Link to="/logout" className="button is-link text-centered">
                I do not consent.
              </Link>
            </form>
          </FormContext>
        )}
        {sessionInfo.patient?.hasHipaaConsent() && (
          <strong className="text-centered">
            You agreed to this consent on{' '}
            {moment(sessionInfo.patient.hipaaConsentDate).format(
              'MMMM DD, YYYY.'
            )}
          </strong>
        )}
      </div>
    </section>
  );
};
export default HipaaConsentPage;
