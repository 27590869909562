import React from 'react';
import './loader.scss';

export type LoaderProps = {
  className?: string;
};

export const Loader: React.FC<LoaderProps> = ({ className }) => (
  <div className={`loader ${className || ''}`} />
);
