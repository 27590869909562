import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import classNames from 'classnames';
import { BillingStatus, Patient } from '../../../models';
import { useSessionContext } from '../../shared/global/sessionContext';

type QuickNavProps = {
  patient: Patient;
};

const Wrapper = styled.section`
  background: white;
  display: grid;
  align-items: center;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;
const LinkWrappers = styled.div`
  @media (max-width: 650px) {
    margin: initial;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
  }

  i {
    margin-right: 10px;
  }
`;

const FrozenWarning = styled.p`
  color: var(--global-color-danger);
  font-weight: bold;
  grid-column: 1/ 3;
  @media (max-width: 650px) {
    grid-column: initial;
  }
`;

export const PatientQuickNav: React.FC<QuickNavProps> = ({ patient }) => {
  const history = createBrowserHistory();
  const [session] = useSessionContext();
  const routes = [
    {
      to: `/patient/${patient.getId()}`,
      icon: 'fas fa-id-card',
      text: 'Profile',
    },
    {
      to: `/patient/${patient.getId()}/calendar`,
      icon: 'fas fa-calendar-alt',
      text: 'Calendar',
    },
    {
      to: `/user/${patient.user.getId()}/notifications`,
      icon: 'fas fa-bell',
      text: 'Notifications',
    },
    {
      to: `/patient/${patient.getId()}/analytics`,
      icon: 'fas fa-chart-bar',
      text: 'Analytics',
    },
  ];
  const shouldShowFrozenMessage =
    !session.isPatientView &&
    patient.user.billing?.billingStatus === BillingStatus.FROZEN;
  const frozenHighlightText = patient.user.billing?.isCancelled
    ? "This patient has cancelled their subscription. You won't be able to change their profile anymore."
    : "This patient's account has been frozen due to missed payments. Changes cannot be made to their profile at this time. Access to the calendar is limited, but past treatment history is still available.";

  return (
    <Wrapper className="grid grid--2 w100">
      <div>
        <h4 className="text-centered">Viewing {patient.user.getFullName()}</h4>
      </div>
      <LinkWrappers className="grid--2 grid--spacing-10 grid">
        {routes.map(({ to, icon, text }) => (
          <Link
            to={to}
            key={text}
            className={classNames('button', {
              active: history.location.pathname === to,
            })}
          >
            <i className={icon} />
            <span className="icon-text">{text}</span>
          </Link>
        ))}
      </LinkWrappers>
      {shouldShowFrozenMessage && (
        <FrozenWarning>{frozenHighlightText}</FrozenWarning>
      )}
    </Wrapper>
  );
};
