import React from 'react';

export function Icons() {
  return (
    <svg
      aria-hidden="true"
      style={{ position: 'absolute', zIndex: -1, pointerEvents: 'none' }}
    >
      <defs>
        <symbol id="plus-square-regular" viewBox="0 0 448 512">
          <title>Plus Square Regular</title>
          <path
            fill="currentColor"
            d="M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
          />
        </symbol>
        <symbol id="arrow-up-from-square" viewBox="0 0 448 512">
          <title>Arrow Up from Square</title>
          <path d="M368,416L368,128L349,128L349,64L368,64C403.3,64 432,92.7 432,128L432,416C432,451.35 403.35,480 368,480L80,480C44.65,480 16,451.35 16,416L16,128C16,92.7 44.7,64 80,64L99,64L99,128L80,128L80,416L368,416ZM144.752,116.72C136,107.969 135.985,93.789 144.752,85.022L207.702,22.072C210.278,19.496 212.784,17.842 215.394,16.787C217.598,16.119 220.912,15.074 223.948,15.039C223.972,15.064 223.997,15.088 224.067,15.059C230.218,15.069 235.329,18.001 239.797,21.577L303.242,85.022C312.009,93.789 312.009,107.954 303.242,116.72C294.476,125.487 280.311,125.487 271.544,116.72L246.434,91.61L246.384,259.362C246.384,271.744 236.379,281.749 223.997,281.749C211.615,281.749 201.61,271.744 201.61,259.362L201.561,91.61L176.45,116.72C167.684,125.487 153.504,125.472 144.752,116.72Z" />
        </symbol>
      </defs>
    </svg>
  );
}
