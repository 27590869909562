/* eslint-disable no-empty */
import { useCallback } from 'react';
import { ElixirSession } from '../models';

declare global {
  interface Window {
    analytics: any;
  }
}

export function useAnalytics() {
  const page = useCallback((pageName: string) => {
    try {
      window.analytics?.page(pageName);
    } catch (_error) {}
  }, []);

  const identify = useCallback((session: ElixirSession) => {
    try {
      const context: Record<string, any> = {
        type: session.userType,
      };
      if (session.isPatientView) {
        context.selfManaged = session.isSelfManagedView;
        context.calendarType = session.patient?.calendarType;
      }

      window.analytics?.identify(session.user.getId(), context);
    } catch (_error) {}
  }, []);

  const track = useCallback((eventName: string, props: Record<any, any>) => {
    try {
      window.analytics?.track(eventName, props);
    } catch (_error) {}
  }, []);

  return {
    identify,
    page,
    track,
  };
}
