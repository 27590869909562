import React from 'react';
import './profile.page.css';
import { useSessionContext } from '../../shared/global/sessionContext';
import PatientProfile from '../../features/profile/patientProfile';
import DoctorProfile from '../../features/profile/doctorProfile';

const ProfilePage: React.FC<{ title: string }> = () => {
  const [sessionContext] = useSessionContext();

  return (
    <section className="profile-page">
      {sessionContext.isDoctorView ? (
        <DoctorProfile doctorId={sessionContext.doctor!.getId()} />
      ) : (
        <PatientProfile patientId={sessionContext.patient!.getId()} />
      )}
    </section>
  );
};
export default ProfilePage;
