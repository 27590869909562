import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormContext, useForm } from 'react-hook-form';
import { useSessionContext } from '../../shared/global/sessionContext';
import { SortDirection, PaginationInfo } from '../../../types';
import { Button, Table, TableHeader, TextField } from '../../shared';
import { InvitedPatientTableItem } from './invitedPatientTableItem';
import { InvitedPatient } from '../../../models';
import {
  onApiErrorToast,
  onSuccessToast,
  onWarningToast,
} from '../../../services/toasts.service';
import { getInvitedPatientsForDoctor } from '../../../services';
import { FORM_VALIDATION } from '../../../util';
import { invitePatientByEmail } from '../../../services/doctor.service';

import './invitePatientsTable.css';

export const InvitePatientsTable: React.FC = () => {
  const [session] = useSessionContext();
  const [paginationInfo, setPaginationInfo] = useState({
    sortDirection: 'DESC',
    sortBy: 'inviteDate',
    pageNumber: 1,
    pageSize: 30,
  } as PaginationInfo);

  const [invitedPatients, setInvitedPatients] = useState(
    [] as InvitedPatient[]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const methods = useForm<{ email: string }>();

  const pageRouteDoctorId = useParams<{ doctorId: string }>().doctorId;

  const doctorIdToUse = session.isAdmin
    ? pageRouteDoctorId
    : session.doctor?.getId();

  const getInvitedPatients = () => {
    setIsLoading(true);
    getInvitedPatientsForDoctor(doctorIdToUse!, paginationInfo)
      .then((patients) => {
        setInvitedPatients(patients.results);
        setPaginationInfo({
          ...paginationInfo,
          total: patients.total,
        });
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInvitedPatients();
  }, [
    paginationInfo.pageNumber,
    paginationInfo.sortBy,
    paginationInfo.sortDirection,
  ]);

  const tableHeaders = [
    { label: 'email', sortKey: 'email' },
    { label: 'date of invite', sortKey: 'inviteDate' },
    { label: 'status', sortKey: 'status' },
    { label: '' },
  ] as TableHeader[];

  const disabled =
    isSendingEmail || (!session.isAdmin && !session.doctor?.isApproved);
  return (
    <Table
      headers={tableHeaders}
      collection={invitedPatients.map((invitedPatient) => ({
        id: invitedPatient.getId(),
        invitedPatient,
      }))}
      component={InvitedPatientTableItem}
      onSort={(sortBy: string, sortDirection: SortDirection) =>
        setPaginationInfo({ ...paginationInfo, sortBy, sortDirection })
      }
      onPageChange={(newPageNumber: number) => {
        setPaginationInfo({
          ...paginationInfo,
          pageNumber: newPageNumber,
        });
      }}
      isFetchingData={isLoading}
      paginationInfo={paginationInfo}
      itemLabel="Invited Patients"
      tableClassName="invite-patients"
    >
      <FormContext {...methods}>
        <form
          onSubmit={methods.handleSubmit(() => {
            const email = methods.getValues('email');
            if (!email) {
              onWarningToast('Please enter an email to send an invite to.');
              return;
            }
            if (!isSendingEmail) {
              setIsSendingEmail(true);
              invitePatientByEmail(doctorIdToUse!, email)
                .then(() => {
                  onSuccessToast('Email Invite Sent!');
                  methods.setValue('email', '');
                  getInvitedPatients();
                })
                .catch((error) => onApiErrorToast(error))
                .finally(() => setIsSendingEmail(false));
            }
          })}
        >
          {!session.isAdmin && !session.doctor?.isApproved && (
            <p className="description">
              Invites by Email will be enabled once you are approved.
            </p>
          )}
          <div className="textfield-with-button">
            <TextField
              name="email"
              type="email"
              placeholder="Invite a patient by email"
              validationOptions={{
                pattern: {
                  value: FORM_VALIDATION.VALID_EMAIL,
                  message: 'invalid email address',
                },
              }}
              disabled={disabled}
            />
            <Button type="submit" isDisabled={disabled}>
              Invite
            </Button>
          </div>
        </form>
      </FormContext>
    </Table>
  );
};
