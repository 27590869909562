import moment from 'moment-timezone';
import { BaseModel, Patient } from '.';
import { CalendarType, Treatment } from '../types';

export class TreatmentLog extends BaseModel {
  date!: Date;

  calendarType!: CalendarType;

  cycleDayNumber!: number;

  patient!: Patient;

  symptoms!: string[];

  treatment!: Treatment[];

  doctorNotes?: string;

  patientNotes?: string;

  // Used to differentiate between actual patient saved logs, and automatically generated logs
  // if a doctor sets some information for a log for the future, its considered a temporaryLog until a patient saves it
  isTemporaryLog!: boolean;

  isWithinPeriodMargin!: boolean;

  isResetCycleDayOne = false;

  pulse?: number;

  /** ------------- FRONT END ONLY ------------- */

  isExpectedBleedPeriod: boolean;

  isUnavailable: boolean; // If this was a log that was before the account was created, or before the provider accepts, we dont want to show anything to avoid any weird data issues

  hasOneTimeDosageChanges: boolean; // Whether or not a specific day has one time changes applied to it

  hasBloodDrawAppointment: boolean; // Checks whether there's been an appointment noted for a blood draw on this day

  isFrozen = false; // Checks whether a log has its data hidden due to a frozen billing account

  constructor(json?: TreatmentLog) {
    super();
    this.isExpectedBleedPeriod = false;
    this.isUnavailable = false;
    this.hasOneTimeDosageChanges = false;
    this.hasBloodDrawAppointment = false;
    this.isResetCycleDayOne = false;
    if (json) {
      const treatmentLog = TreatmentLog.fromJSON<TreatmentLog>(json, {});
      if (json.patient) {
        treatmentLog.patient = new Patient(json.patient);
      }
      return treatmentLog;
    }
  }

  static removeFrontEndOnlyKeys(
    treatmentLog: TreatmentLog
  ): Partial<TreatmentLog> {
    const modifiedLog = treatmentLog as any;
    delete modifiedLog.isExpectedBleedPeriod;
    delete modifiedLog.isUnavailable;
    delete modifiedLog.hasOneTimeDosageChanges;
    delete modifiedLog.hasBloodDrawAppointment;
    delete modifiedLog.isFrozen;
    return modifiedLog;
  }

  canChangeCalendarType(): boolean {
    return (
      (this.patient.calendarType === CalendarType.lunar &&
        this.calendarType === CalendarType.lunar) ||
      (this.patient.calendarType === CalendarType.personal &&
        this.calendarType === CalendarType.personal)
    );
  }

  /**
   * Resets are only available IFF:
   * - the patient is on a personal calendar
   * - the day not a cycle day 1
   * - is not a temporary log
   * (when switching from lunar to personal - if the last lunar day was a bleed date,
   * its not a valid day to reset to cycle day 1 because it techncially already is,
   * though it doesnt appear that way on the calendar)
   */
  canResetCycleDay(): boolean {
    return (
      this.patient.calendarType === CalendarType.personal &&
      this.cycleDayNumber !== 1 &&
      this.calendarType === CalendarType.personal
    );
  }

  /**
   * TODO: maybe add it as abstract base model
   */
  convertToCSVReadable(): Record<string, any> {
    const returnVal = this as any;
    returnVal.symptoms = this.symptoms.join(',');
    returnVal.date = moment.utc(this.date).format('YYYY-MM-DD');
    returnVal.treatment = this.treatment
      .map((t) => JSON.stringify(t))
      .join(',');
    return returnVal;
  }

  hasPeriod(): boolean {
    return (
      this.symptoms.includes('Period Start') || this.symptoms.includes('Bleed')
    );
  }

  sortTreatments(): Treatment[] {
    return this.treatment.sort((a, b) => {
      if (a.substance === b.substance) {
        if (a.frequencyUnit > b.frequencyUnit) {
          return 1;
        }
        if (a.frequencyUnit < b.frequencyUnit) {
          return -1;
        }
        return 0;
      }
      if (a.substance > b.substance) {
        return 1;
      }
      return -1;
    });
  }
}
