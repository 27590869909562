import { BaseModel } from './base.model';

// eslint-disable-next-line no-shadow
export enum BillingStatus {
  FROZEN = 'frozen', // If their currentPeriodAccessEnd is in the past
  HEALTHY = 'healthy', // We're gucci
  PRECONFIRMATION = 'preconfirmation', // Should only be while a doctor hasn't doen their first approval of a patient
  DUNNING = 'dunning', // if if dunning
}

export class Billing extends BaseModel {
  billingStatus!: BillingStatus;

  currentPeriodAccessEnd!: Date;

  isCancelled!: boolean;

  constructor(json?: Billing) {
    super();
    if (json) {
      const billing = Billing.fromJSON<Billing>(json);
      return billing;
    }
  }

  isFrozen() {
    return this.billingStatus === BillingStatus.FROZEN;
  }

  isInDunning() {
    return this.billingStatus === BillingStatus.DUNNING;
  }
}
