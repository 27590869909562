/* eslint-disable jsx-a11y/control-has-associated-label */
import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import { ProspectiveDoctor, ProspectiveDoctorStatus } from '../../../models';
import './prospectiveDoctorItem.css';

type ProspectiveDoctorTableItemProps = {
  prospectiveDoctor: ProspectiveDoctor;
  onStatusChange: (e: ProspectiveDoctorStatus, id: number) => void;
};

export const ProspectiveDoctorTableItem: React.FC<
  ProspectiveDoctorTableItemProps
> = ({ prospectiveDoctor, onStatusChange }) => {
  const selectOptions = [
    ProspectiveDoctorStatus.ACCEPTED,
    ProspectiveDoctorStatus.CONTACTED,
    ProspectiveDoctorStatus.DECLINED,
    ProspectiveDoctorStatus.FOLLOW_UP,
    ProspectiveDoctorStatus.NEW,
    ProspectiveDoctorStatus.NO_RESPONSE,
  ].map((option) => {
    return {
      label: option,
      value: option,
    };
  });

  return (
    <tr className="prospective-doctor-table-item">
      <td className="name">
        {prospectiveDoctor.firstName} {prospectiveDoctor.lastName}
        <br />
        {prospectiveDoctor.email}
      </td>
      <td className="clinic left-align">
        {prospectiveDoctor.clinicName}
        <br />
        {prospectiveDoctor.telephone}
      </td>
      <td className="location left-align">
        {prospectiveDoctor.country},
        <br />
        {prospectiveDoctor.state},
        <br />
        {prospectiveDoctor.city}
      </td>
      <td className="referredBy">{prospectiveDoctor.referredByUser}</td>
      <td className="referredDate">
        {moment(prospectiveDoctor.referredDate).format("MMMM DD, 'YY")}
      </td>
      <td className="status">
        <Select
          classNamePrefix="react-select"
          className="react-select-container"
          options={selectOptions}
          defaultValue={selectOptions.find(
            (o) => o.value === prospectiveDoctor.status
          )}
          onChange={(e: any) => {
            onStatusChange(
              e.value as ProspectiveDoctorStatus,
              Number(prospectiveDoctor.getId())
            );
          }}
        />
      </td>
    </tr>
  );
};
