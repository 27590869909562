import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../../../types';

type PatientForProspectiveDoctorTableItemProps = {
  patient: {
    doctorFirstName: string;
    doctorLastName: string;
    firstName: string;
    lastName: string;
    id: string;
  };
  doctorOptions: SelectOption<string>[];
  onChangeDoctor: (doctorId: string, patientId: string) => void;
};

const PatientForProspectiveDoctorTableItem: React.FC<
  PatientForProspectiveDoctorTableItemProps
> = ({ patient, doctorOptions, onChangeDoctor }) => {
  return (
    <tr className="patient-for-prospective-doctor-table-item">
      <td className="name">
        {patient.firstName} {patient.lastName}
      </td>
      <td className="doctor-name">
        {patient.doctorFirstName} {patient.doctorLastName}
      </td>
      <td className="switch-doctor">
        <Select
          classNamePrefix="react-select"
          className="react-select-container"
          options={doctorOptions}
          onChange={(e: any) => {
            onChangeDoctor(e.value, patient.id);
          }}
        />
      </td>
    </tr>
  );
};

export default PatientForProspectiveDoctorTableItem;
