/* eslint-disable no-param-reassign */
import axios from 'axios';
import { BILLING_URL } from '../util/constants';
import { BillingStatus } from '../models/billing.model';
import {
  BillingAddress,
  BillingPlanDetails,
  PaymentMethodDetail,
} from '../types';

/**
 * starts a Stripe Checkout Session to handle payment intents
 */
export const createCheckoutSession = async (): Promise<string> => {
  const result = await axios.post<{ sessionId: string }>(
    `${BILLING_URL}/checkout-session`
  );
  return result.data.sessionId;
};

export const createCustomerPortalSession = async (): Promise<string> => {
  const result = await axios.post<{ redirectUrl: string }>(
    `${BILLING_URL}/customer-portal`
  );
  return result.data.redirectUrl;
};

/**
 * Checks if a given code is legit, and returns a human readable form of what the code does
 * @param code
 */
export const validatePromoCode = async (
  code: string
): Promise<string | null> => {
  try {
    const response = await axios.post<{ promoInWords: string }>(
      `${BILLING_URL}/validate-promo-code`,
      {
        code,
      }
    );
    return response.data.promoInWords;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return null;
    }
    throw error;
  }
};

export const applyPromoCode = async (code: string) => {
  await axios.post(`${BILLING_URL}/apply-promo-code`, {
    code,
  });
};

export const getBillingSummary = async (): Promise<{
  billingAddress?: BillingAddress;
  billingStatus: BillingStatus;
  customerPortalLink: string;
  isCancelled: boolean;
  hasDefaultPaymentMethod: boolean;
  paymentMethods: PaymentMethodDetail[];
  planDetails: BillingPlanDetails;
  status: string;
}> => {
  const result = await axios.get<ReturnType<typeof getBillingSummary>>(
    `${BILLING_URL}/summary`
  );
  return result.data;
};

/**
 * @param checkoutSessionId
 */
export const updateDefaultPaymentMethodForCustomer = async (
  checkoutSessionId: string
): Promise<{
  paymentMethod: string;
}> => {
  const result = await axios.post<{ paymentMethod: string }>(
    `${BILLING_URL}/default-payment-method`,
    {
      checkoutSessionId,
    }
  );
  return result.data;
};

export const restartSubscription = async (): Promise<void> => {
  await axios.post(`${BILLING_URL}/restart-subscription`);
};

export const renewSubscription = async (): Promise<void> => {
  await axios.post(`${BILLING_URL}/renew-subscription`);
};

export const updateBillingAddress = async (
  address: BillingAddress
): Promise<void> => {
  await axios.post(`${BILLING_URL}/billing-address`, {
    ...address,
  });
};
