import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../shared';
import { CheckboxList } from '../../shared/forms/checkboxList/checkboxList';

const BASIC_REASONS = [
  {
    text: 'The App is too expensive',
  },
  {
    text: "I don't need hormone treatment anymore",
  },
  {
    text: "I'd prefer to use a paper calendar",
  },
  {
    text: 'Switching to another product',
  },
  {
    text: 'The app is confusing or difficult to use',
  },
  {
    text: 'Technical issues',
  },
  {
    text: 'Missing features',
  },
];

const StyledFormGroup = styled.div`
  textarea {
    width: 100%;
  }
`;
const StyledSubmitButton = styled(Button)`
  width: 50%;
  margin: 0 auto;
`;

type WhyAreYouLeavingProps = {
  onSubmit: (reasonsWhy: string[], explained: string) => void;
  isDeleting?: boolean;
};

export const WhyAreYouLeaving: React.FC<WhyAreYouLeavingProps> = ({
  onSubmit,
  isDeleting,
}) => {
  const [reasonsWhy, setReasonsWhy] = useState([] as string[]);
  const [explained, setExplained] = useState('');

  const checkboxes = BASIC_REASONS.map(({ text }) => ({
    label: text,
    inputName: text,
    onChange: (_e: any, reason: string) => {
      const set = new Set(reasonsWhy);
      if (set.has(reason)) {
        set.delete(reason);
      } else {
        set.add(reason);
      }
      setReasonsWhy([...set]);
    },
    isChecked: reasonsWhy.includes(text),
  }));

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(reasonsWhy, explained);
      }}
    >
      <strong>We&apos;re sorry to see you go!</strong>
      <p>
        Before you leave, please let us know the reason you are leaving. Every
        bit of feedback helps!
      </p>
      <CheckboxList checkboxes={checkboxes} />
      {!!reasonsWhy.find(
        (reason) =>
          reason === 'Technical issues' ||
          reason === 'Missing features' ||
          reason === 'Switching to another product' || reason === 'The app is confusing or difficult to use'
      ) && <strong> Would you mind telling us a little more ?</strong>}
      <StyledFormGroup className="form-group">
        <label>
          <textarea
            rows={5}
            className="is-wide"
            name="explained"
            placeholder="Any additional details you want to share"
            onChange={(e) => setExplained(e.target.value)}
          />
        </label>
      </StyledFormGroup>
      <StyledSubmitButton type="submit" className="danger">
        {isDeleting ? 'Delete' : 'Cancel'} Account
      </StyledSubmitButton>
    </form>
  );
};
