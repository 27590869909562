// Routes

import { SelectOption } from '../types';
import { NotificationType } from '../models/notification.model';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const AUTH_URL = `${BASE_URL}`;
export const DOCTORS_URL = `${BASE_URL}/doctors`;
export const PATIENTS_URL = `${BASE_URL}/patients`;
export const ADMIN_URL = `${BASE_URL}/admin`;
export const CLINIC_URL = `${BASE_URL}/clinics`;
export const BILLING_URL = `${BASE_URL}/billing`;

export const TIME_BEFORE_REFRESH_EXPIRE = 1000 * 60; // 1 minute

export const MAX_NUMBER_OF_LUNAR_CYCLE_DAYS = 28;

export const PAGE_NOT_FOUND = '/not-found';
export const PAGE_UNAUTHORIZED = '/unauthorized';

/**
 * Pages that dont need to be be authentication to visit
 * We also dont want to redirect to these pages on reload after authentication
 */
export const PAGES_NOT_REQUIRING_AUTHENTICATION = [
  '/register',
  '/login',
  '/forgot-password',
  '/reset-password',
  '/logout',
];

// Select Options
export const GENDER_OPTIONS: SelectOption<string>[] = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS: SelectOption<string>[] = [
  {
    value: 'email invite',
    label: 'Email Invite',
  },
  {
    value: 'marketing flyers',
    label: 'Marketing Flyers',
  },
  {
    value: 'patient',
    label: 'Another Patient',
  },
  {
    value: 'pharmacy',
    label: 'Compounding Pharmacy',
  },
  {
    value: 'AANP conference',
    label: 'AANP Conference',
  },
  {
    value: 'WANP Conference',
    label: 'WANP Conference',
  },
  {
    value: 'another provider',
    label: 'Another Provider',
  },
  {
    value: 'provider',
    label: 'Your Provider',
  },
  {
    value: 'youtube',
    label: 'Youtube',
  },

  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'instagram',
    label: 'Instagram',
  },
  {
    value: 'google',
    label: 'Google',
  },
];

export const ZONE_OPTIONS: SelectOption<string>[] = [
  {
    value: 'EDT',
    label: 'Eastern Time',
  },
  {
    value: 'CDT',
    label: 'Central Time',
  },
  {
    value: 'MDT',
    label: 'Mountain Time',
  },
  {
    value: 'PDT',
    label: 'Pacific Time',
  },
  {
    value: 'AKDT',
    label: 'Alaska Time',
  },
  {
    value: 'AST',
    label: 'Atlantic Standard Time',
  },
  {
    value: 'HST',
    label: 'Hawaii Time',
  },
];

export const TREATMENT_OPTIONS: SelectOption<string>[] = [
  { value: 'estrogen', label: 'bHRT - bioidentical hormones' },
  // { value: 'testerone', label: 'Testosterone Hormone' },
];

export const CALENDAR_TYPE_OPTIONS: SelectOption<string>[] = [
  { value: 'personal', label: 'Personal' },
  { value: 'lunar', label: 'Lunar' },
];

export const PERIOD_MARGIN_OPTIONS: SelectOption<number>[] = [
  { label: '+/- 0 Days', value: 0 },
  { label: '+/- 1 Day', value: 1 },
  { label: '+/- 2 Days', value: 2 },
  { label: '+/- 3 Days', value: 3 },
  { label: '+/- 4 Days', value: 4 },
  { label: '+/- 5 Days', value: 5 },
];

export const FORM_VALIDATION = {
  VALID_EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
  VALID_PHONE_NUMBER:
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
};

export const DOCTOR_NOTIFICATION_SETTINGS = [
  {
    label: 'New Patient',
    name: NotificationType.NEW_PATIENT,
  },
  {
    label: 'Symptoms Change',
    name: NotificationType.SYMPTOMS_CHANGE,
  },
  {
    label: 'Unexpected Period (outside margin)',
    name: NotificationType.UNEXPECTED_PERIOD,
  },
  {
    label: 'Patient Note Added',
    name: NotificationType.PATIENT_NOTE_ADDED,
  },
] as const;

export const SYMPTOMS_LIST = [
  'Period Start',
  'Bleed',
  'Acne',
  'Angry',
  'Cramps',
  'Dry Vagina',
  'Hot Flashes',
  'Irritable',
  'Nausea',
  'Spotting',
  'Anxiety',
  'Constipation',
  'Fatigue',
  'Heart Palpitations',
  'Itching',
  'Night Sweats',
  'Teary',
  'Brain Fog',
  'Depression',
  'Feel Great',
  'Incontinence',
  'Joint Pain',
  'Sad',
  'Tingling',
  'Bloating',
  'Dizziness',
  'Headache',
  'Insomnia',
  'Libido',
  'Sore Breasts',
  'Tired',
  'Terrible Sleep',
  'Bad Sleep',
  'Okay Sleep',
  'Good Sleep',
  'Fantastic Sleep',
] as const;

export const SYMPTOMS_OPTIONS = SYMPTOMS_LIST.map((symptom) => ({
  value: symptom,
  label: symptom,
}));

// TODO: maybe this should be fetched instead
export const FEATURE_FLAGS = [];

export const COOKIE_LAST_CALENDAR_VIEW_MODE =
  '__artemis-lastCalendarViewMode' as const;

export const RATING_PULSE_VALUES = [
  [1, 'fa-solid fa-face-tired', 'Terrible!'],
  [2, 'fa-solid fa-face-frown', 'Meh'],
  [3, 'fa-solid fa-face-meh', 'Okay'],
  [4, 'fa-solid fa-face-smile', 'Good'],
  [5, 'fa-solid fa-face-laugh-beam', 'Great!'],
] as const;
