import { Observable, ReplaySubject } from 'rxjs';
import { AxiosError } from 'axios';
import { uuidv4 } from '../util/helpers';
import { ToastProps } from '../components/shared/toast/toast';

// eslint-disable-next-line no-shadow
export enum ToastType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

const toastSubject = new ReplaySubject<ToastProps>();

export function onNewToast(): Observable<ToastProps> {
  return toastSubject.asObservable();
}

// core alert method
function createToast({
  message,
  type,
  id,
  title,
  onClick,
  onClose,
  autoRemove,
}: Partial<ToastProps>): void {
  const toast: ToastProps = {
    message: message || '',
    type: type || ToastType.info,
    id: id || uuidv4(),
    title,
    onClick,
    onClose: onClose || ((_id) => {}),
    autoRemove,
  };
  toastSubject.next(toast);
}

export function onSuccessToast(
  message: string,
  options?: Partial<ToastProps>
): void {
  createToast({
    ...options,
    message,
    type: ToastType.success,
  });
}

export function onInfoToast(
  message: string,
  options?: Partial<ToastProps>
): void {
  createToast({
    ...options,
    message,
    type: ToastType.info,
  });
}

export function onWarningToast(
  message: string,
  options?: Partial<ToastProps>
): void {
  createToast({
    ...options,
    message,
    type: ToastType.warning,
  });
}

export function onErrorToast(
  error: string,
  options?: Partial<ToastProps>
): void {
  createToast({
    ...options,
    message: error,
    type: ToastType.error,
  });
}

export function onApiErrorToast(
  error: AxiosError,
  customErrorMessage?: string,
  options?: Partial<ToastProps>
): void {
  console.error(error.response);
  let message = '';

  if (customErrorMessage) {
    message = customErrorMessage;
  } else if (error.response) {
    const { status, data } = error.response;
    if (status === 401 || status === 403 || status === 500) {
      // Let the axios interceptor handle this
      return;
    }
    message = data?.message || data;
  } else if (error.message === 'Network Error') {
    message = "Sorry, it looks like we're experiencing technical difficulties.";
  } else {
    message = error.message;
  }

  createToast({
    ...options,
    message,
    type: ToastType.error,
  });
}
