import Select from 'react-select';
import React, { useState } from 'react';
import moment from 'moment';
import {
  useForm,
  Controller,
  FormContext,
  useFormContext,
} from 'react-hook-form';
import classNames from 'classnames';
import { CalendarType, ChangeType } from '../../../types';
import './treatmentLog.scss';
import { withSession, useSessionContext } from '../../shared/global';
import { SelectOption, TreatmentLogFormData } from '../../../types/form.types';
import {
  convertSelectOptionsToValue,
  excludePick,
  pick,
} from '../../../util/helpers';
import {
  Treatment,
  TreatmentLogDosageDelta,
} from '../../../types/apiResponse.types';
import { onErrorToast } from '../../../services/toasts.service';
import { TreatmentLog } from '../../../models';
import { SYMPTOMS_OPTIONS } from '../../../util';
import { Button, ConfirmModal } from '../../shared';
import { HowDoYouFeel } from './howDoYouFeel';

type TreatmentLogProps = {
  treatmentLog: TreatmentLog;
  onSave: (
    log: TreatmentLog,
    dosageDeltas: TreatmentLogDosageDelta[]
  ) => Promise<void>;
  onChangeCalendarType: (log: TreatmentLog) => Promise<void>;
  onResetCycleDayNumber: (log: TreatmentLog) => Promise<void>;
};

function RepeatChangeOptions({
  treatmentLog,
  dosageDeltas,
}: {
  treatmentLog: TreatmentLog;
  dosageDeltas: TreatmentLogDosageDelta[];
}) {
  const [sessionContext] = useSessionContext();
  const methods = useFormContext();
  const { control, errors } = methods;

  const changeTypeOptions: SelectOption<string>[] = [
    {
      label: "Don't Repeat",
      value: ChangeType.single,
    },
    {
      label: `Repeat Cycle Day
        ${treatmentLog.cycleDayNumber === 0 ? '1*' : treatmentLog.cycleDayNumber
        }s`,
      value: ChangeType.repeat,
    },
    // ignore Cycle Day 1*, 1, and last day
    ...([0, 1, 28].indexOf(treatmentLog.cycleDayNumber) < 0
      ? [
        {
          label: `Repeat Cycle Days 1 to ${treatmentLog.cycleDayNumber}`,
          value: ChangeType.fromBeginningToDay,
        },
      ]
      : []),
    ...(treatmentLog.cycleDayNumber !== 0 && treatmentLog.cycleDayNumber !== 28
      ? [
        {
          label: `Repeat Cycle Days ${treatmentLog.cycleDayNumber} to 28`,
          value: ChangeType.restOfCycle,
        },
      ]
      : []),
    {
      label: 'Repeat Every Cycle Day',
      value: ChangeType.all,
    },
  ];

  const doesLogAlreadyExist =
    !!treatmentLog.getId() && !treatmentLog.isTemporaryLog;
  if (
    (sessionContext.isPatientView && !sessionContext.isSelfManagedView) ||
    doesLogAlreadyExist ||
    dosageDeltas.every((delta) => delta.change === 0)
  ) {
    return null;
  }
  return (
    <div>
      <div className="form-group repeat-options">
        <span className="faux-label">Repeat Change </span>
        <Controller
          as={
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              options={changeTypeOptions}
            />
          }
          control={control}
          name="changeType"
        />
        <div className="error">
          {errors.changeType && errors.changeType.message}
        </div>
      </div>
    </div>
  );
}

/**
 * Used only that we send the necessary information back related to the treatment log itself when saving
 * @param param0
 * @returns
 */
function HiddenFormValues({ treatmentLog }: { treatmentLog: TreatmentLog }) {
  const methods = useFormContext();
  const { register } = methods;
  return (
    <div className="form-values is-hidden">
      <input type="text" disabled name="id" ref={register({})} />
      <input
        type="text"
        disabled
        name="date"
        defaultValue={treatmentLog.date.toString()}
        ref={register({})}
      />
      <input type="number" disabled name="cycleDayNumber" ref={register({})} />
      <input type="text" disabled name="calendarType" ref={register({})} />
      <input
        type="checkbox"
        checked={treatmentLog.isTemporaryLog}
        disabled
        name="isTemporaryLog"
        ref={register({})}
      />
      <input
        type="checkbox"
        checked={treatmentLog.isWithinPeriodMargin}
        disabled
        name="isWithinPeriodMargin"
        ref={register({})}
      />
    </div>
  );
}

function AdvancedDoctorSettings({
  treatmentLog,
  onChangeCalendarType,
  onResetCycleDayNumber,
}: Pick<
  TreatmentLogProps,
  'treatmentLog' | 'onChangeCalendarType' | 'onResetCycleDayNumber'
>) {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const [sessionContext] = useSessionContext();
  if (!sessionContext.isDoctorView && !sessionContext.isSelfManagedView) {
    return null;
  }
  const canResetCycleDayOne = !sessionContext.isSelfManagedView ||
    (sessionContext.isSelfManagedView &&
      sessionContext.patient?.calendarType === CalendarType.personal);
  return (
    <section className="advanced-settings">
      <h3 className="text-centered capitalized">advanced settings</h3>
      {!treatmentLog.isTemporaryLog && (
        <p className="description warning-text">
          Note: A log already exists for this day. Any of the below changes will
          correctly update all future days for that change, but will not affect
          this specific date.
        </p>
      )}
      <div className="settings text-centered">
        <div className="calendar-type setting">
          <Button
            isDisabled={!treatmentLog.canChangeCalendarType()}
            onClick={() => onChangeCalendarType(treatmentLog)}
          >
            Change Calendar Settings
          </Button>

          <span className="description">
            Change between lunar and personal calendars or reset future doses
            using a template.
          </span>
        </div>
        {canResetCycleDayOne && (
          <div className="reset-cycle-day setting">
            <Button
              isDisabled={!treatmentLog.canResetCycleDay()}
              onClick={() => setConfirmModalOpen(true)}
            >
              Reset to Cycle Day 1
            </Button>
            <span className="description">
              Recalculate <strong>future</strong> cycle days using the
              current day as Cycle day 1. This applies only to Personal
              Calendar Types (not Lunar).
            </span>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        message="Are you sure you want to reset this day to cycle day 1?"
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={() => {
          const log = pick<TreatmentLog>(treatmentLog, [
            'id',
            'isTemporaryLog',
            'date',
            'symptoms',
            'calendarType',
            'treatment',
          ]);
          log.cycleDayNumber = 1;
          log.isResetCycleDayOne = true;
          onResetCycleDayNumber(log as TreatmentLog);
        }}
      >
        <p className="warning-text text-centered">
          Warning: this will delete all future single dosage changes.
        </p>
      </ConfirmModal>
    </section>
  );
}

function Symptoms({
  treatmentLog,
  disabledForFutureDate,
}: {
  treatmentLog: TreatmentLog;
  disabledForFutureDate: boolean;
}) {
  const methods = useFormContext();
  const { control } = methods;
  const [sessionContext] = useSessionContext();

  const initialSelectedSymptoms = SYMPTOMS_OPTIONS.filter(
    (option) => treatmentLog.symptoms.indexOf(option.value) >= 0
  );

  return (
    <div className="form-group">
      <label>
        Observed Symptoms
        <Controller
          as={
            <Select
              classNamePrefix="react-select"
              className="react-select-container"
              closeMenuOnSelect={false}
              options={SYMPTOMS_OPTIONS}
              isMulti
            />
          }
          control={control}
          defaultValue={initialSelectedSymptoms}
          placeholder="Symptoms you felt today"
          name="symptoms"
          isDisabled={
            disabledForFutureDate ||
            sessionContext.isDoctorView ||
            (sessionContext.isSelfManagedView &&
              new Date() < new Date(treatmentLog.date))
          }
        />
      </label>
    </div>
  );
}

function Notes({
  disabledForFutureDate,
  treatmentLog,
}: {
  disabledForFutureDate: boolean;
  treatmentLog: TreatmentLog;
}) {
  const methods = useFormContext();
  const { register } = methods;
  const [sessionContext] = useSessionContext();
  return (
    <>
      {((sessionContext.isPatientView && treatmentLog.doctorNotes) ||
        sessionContext.isDoctorView) && (
          <div className="form-group">
            <label>
              Provider&apos;s Notes
              <textarea
                name="doctorNotes"
                disabled={sessionContext.isPatientView}
                className="doctor-note is-wide"
                ref={register({})}
                placeholder="Notes to share with the patient"
              />
            </label>
          </div>
        )}
      {((sessionContext.isDoctorView && treatmentLog.patientNotes) ||
        sessionContext.isPatientView) && (
          <div className="form-group">
            <label>
              {sessionContext.isSelfManagedView
                ? 'Your Notes'
                : "Patient's Notes"}
              <textarea
                name="patientNotes"
                disabled={sessionContext.isDoctorView || disabledForFutureDate}
                className="patient-note is-wide"
                ref={register({})}
                placeholder={
                  sessionContext.isSelfManagedView
                    ? 'Notes to self'
                    : 'Notes to share with the provider'
                }
              />
            </label>
          </div>
        )}
    </>
  );
}

function TreatmentList({ treatmentLog }: { treatmentLog: TreatmentLog }) {
  const methods = useFormContext();
  const [sessionContext] = useSessionContext();

  const { register, setValue, watch } = methods;

  const treatmentsToDisplay = treatmentLog.sortTreatments();

  // Need this because the hooks form displays arrays as strings for keys
  const treatments = watch('treatment');

  const doesLogAlreadyExist =
    !!treatmentLog.getId() && !treatmentLog.isTemporaryLog;

  return (
    <>
      <h4>Dosage Breakdown</h4>

      <div className="treatment-list">
        {treatmentsToDisplay.map(
          (
            { substance, dosage, frequency, frequencyUnit, dosageUnit },
            index
          ) => {
            const matchedTreatment = treatments.find(
              (t: Treatment) =>
                t.substance === substance && t.frequencyUnit === frequencyUnit
            );
            const currentTreatmentDosage = matchedTreatment?.dosage || 0;
            return (
              <div
                className={classNames('treatment', {
                  'is-hidden': frequency === 0,
                })}
                key={`${substance}-${frequencyUnit}`}
              >
                <div className="form-values is-hidden">
                  {/* If you use the hidden field type, it wont update properly for some reason, only one dosage will be allowed to be changed */}
                  <input
                    type="text"
                    disabled
                    name={`treatment[${index}].substance`}
                    ref={register({ required: true })}
                    defaultValue={substance}
                  />
                  <input
                    type="number"
                    disabled
                    name={`treatment[${index}].dosage`}
                    ref={register({ required: true })}
                    defaultValue={dosage}
                  />
                  <input
                    type="text"
                    disabled
                    name={`treatment[${index}].dosageUnit`}
                    ref={register({ required: true })}
                    defaultValue={dosageUnit}
                  />
                  <input
                    type="number"
                    disabled
                    name={`treatment[${index}].frequency`}
                    ref={register({ required: true })}
                    defaultValue={frequency}
                  />
                  <input
                    type="text"
                    disabled
                    name={`treatment[${index}].frequencyUnit`}
                    ref={register({ required: true })}
                    defaultValue={frequencyUnit}
                  />
                </div>
                <div className="display-values">
                  <strong
                    className="substance capitalized"
                    data-substance={substance}
                  >
                    {substance} ({frequencyUnit})
                  </strong>
                  <span className="dosage-adjustment">
                    {sessionContext.canAccessAdditionalViews &&
                      !doesLogAlreadyExist && (
                        <>
                          <Button
                            isDisabled={currentTreatmentDosage <= 0}
                            className="is-flat"
                            tooltipText="-1 to dosage"
                            onClick={() =>
                              setValue(
                                `treatment[${index}].dosage`,
                                +currentTreatmentDosage - 1
                              )
                            }
                          >
                            -
                          </Button>
                          <span className="dosage-delta">
                            ({currentTreatmentDosage - dosage})
                          </span>
                          <Button
                            isDisabled={currentTreatmentDosage >= 60}
                            className="is-flat"
                            tooltipText="+1 to dosage"
                            onClick={() => {
                              setValue(
                                `treatment[${index}].dosage`,
                                +currentTreatmentDosage + 1
                              );
                            }}
                          >
                            +
                          </Button>
                        </>
                      )}
                    <span className="dosage">
                      {currentTreatmentDosage} {dosageUnit}
                    </span>
                  </span>
                  <span className="frequency">
                    {frequency}x /{frequencyUnit}
                  </span>
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}

const TreatmentLogView: React.FC<TreatmentLogProps> = ({
  treatmentLog,
  onSave,
  onChangeCalendarType,
  onResetCycleDayNumber,
}) => {
  const [sessionContext] = useSessionContext();
  const [isConfirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const treatmentsToDisplay = treatmentLog.sortTreatments();

  const methods = useForm<TreatmentLogFormData>({
    defaultValues: {
      ...excludePick(treatmentLog, ['symptoms']),
      treatment: treatmentsToDisplay,
    },
  });
  const { handleSubmit, watch, getValues, setError } = methods;

  // Need this because the hooks form displays arrays as strings for keys
  const treatments = watch('treatment');

  const disabledForFutureDate =
    sessionContext.isPatientView &&
    !sessionContext.isSelfManagedView &&
    moment.utc(treatmentLog.date).startOf('day').toDate() >
    moment.utc().startOf('day').toDate();

  const dosageDeltas = treatments.map((treatment) => {
    const matchingExistingTreatment = treatmentLog.treatment.find(
      (t: Treatment) =>
        t.substance === treatment.substance &&
        t.frequencyUnit === treatment.frequencyUnit
    );

    return {
      substance: matchingExistingTreatment!.substance,
      change: treatment.dosage - matchingExistingTreatment!.dosage,
      frequencyUnit: matchingExistingTreatment?.frequencyUnit,
    } as TreatmentLogDosageDelta;
  });

  let saveButtonText = 'Save Changes';
  if (disabledForFutureDate) {
    saveButtonText = 'You cannot save treatments that are in the future';
  } else if (treatmentLog.isTemporaryLog) {
    if (treatmentLog.getId()) {
      saveButtonText = 'Save Changes';
    } else {
      saveButtonText = 'Create Log';
    }
  }

  /**
   * We pass the dirty fields, form values, and the delta for each substance if there is one
   * The delta is only applicable for the doctor view, if they change dosages
   * @param values formValues
   */
  function onSubmit() {
    let values = getValues({ nest: true }) as any;

    if (
      (sessionContext.isDoctorView || sessionContext.isSelfManagedView) &&
      !values.changeType &&
      dosageDeltas.find((delta) => delta.change !== 0)
    ) {
      setError('changeType', 'required', 'Required');
      onErrorToast('Select if this change should repeat');
      return;
    }

    setIsLoading(true);
    /**
     * https://github.com/JedWatson/react-select/issues/3632
     * Fun stuff, but react-select defaults to null for empty arrays, who would've thought
     */
    values = convertSelectOptionsToValue(values, ['symptoms', 'changeType']);
    values.symptoms = values.symptoms ?? [];
    values.pulse = values.pulse ? Number(values.pulse) : undefined;
    onSave(values as any, dosageDeltas);
  }

  return (
    <section className="treatment-log">
      <FormContext {...methods}>
        <form
          onSubmit={handleSubmit(() => {
            if (sessionContext.isPatientView) {
              setConfirmSaveModalOpen(true);
            } else {
              onSubmit();
            }
          })}
        >
          <h3 className="text-centered capitalized">
            <span>
              {moment.utc(treatmentLog.date).format('dddd, MMMM Do YYYY')}
            </span>
            <span> | </span>
            <span>Cycle Day {treatmentLog.cycleDayNumber || '1*'}</span>
            <span> | </span>
            <span>{treatmentLog.calendarType}</span>
          </h3>
          <HiddenFormValues treatmentLog={treatmentLog} />

          <hr />
          <TreatmentList treatmentLog={treatmentLog} />

          <RepeatChangeOptions
            treatmentLog={treatmentLog}
            dosageDeltas={dosageDeltas}
          />

          <Notes
            disabledForFutureDate={disabledForFutureDate}
            treatmentLog={treatmentLog}
          />
          <Symptoms
            treatmentLog={treatmentLog}
            disabledForFutureDate={disabledForFutureDate}
          />
          <HowDoYouFeel
            treatmentLog={treatmentLog}
            disabledForFutureDate={disabledForFutureDate}
          />

          <Button
            isDisabled={disabledForFutureDate}
            className="is-wide treatment-save"
            isLoading={isLoading}
            type="submit"
          >
            {saveButtonText}
          </Button>
        </form>
      </FormContext>
      <AdvancedDoctorSettings
        treatmentLog={treatmentLog}
        onResetCycleDayNumber={onResetCycleDayNumber}
        onChangeCalendarType={onChangeCalendarType}
      />
      {sessionContext.isPatientView && (
        <ConfirmModal
          onCancel={() => {
            setConfirmSaveModalOpen(false);
          }}
          onConfirm={() => {
            setConfirmSaveModalOpen(false);
            onSubmit();
          }}
          isOpen={isConfirmSaveModalOpen}
          message="Are you sure you want to save this?"
        />
      )}
    </section>
  );
};

export default withSession(TreatmentLogView);
