import axios from 'axios';
import useSWR from 'swr';
import { PATIENTS_URL } from '../util';
import {
  RegisterPatientData,
  ApiGetTreatmentHistoryResponse,
  ApiGetTreatmentSchedulesResponse,
  TreatmentLogDosageDelta,
  TreatmentSubstance,
  DosageUnit,
} from '../types';
import {
  User,
  TreatmentLog,
  TreatmentSchedule,
  StaticSchedule,
  Patient,
} from '../models';
import { fetcher } from '../lib/fetcher';

export const createPatient = async (
  patientData: RegisterPatientData
): Promise<Patient> => {
  const result = await axios.post<Patient>(`${PATIENTS_URL}`, patientData);
  return new Patient(result.data);
};

/**
 * @deprecated
 * @returns
 */
export const getPatient = async (patientId: string): Promise<Patient> => {
  const result = await axios.get<Patient>(`${PATIENTS_URL}/${patientId}`);
  return new Patient(result.data);
};

export const getTreatmentSchedules = async (
  patientId: string
): Promise<ApiGetTreatmentSchedulesResponse> => {
  const result = await axios.get<any>(`${PATIENTS_URL}/${patientId}/schedule`);
  return result.data.map((d: TreatmentSchedule) => new TreatmentSchedule(d));
};

export const getTreatmentHistory = async (
  patientId: string,
  month: string
): Promise<ApiGetTreatmentHistoryResponse> => {
  const result = await axios.get<any>(
    `${PATIENTS_URL}/${patientId}/treatment?month=${month}`
  );
  return {
    treatmentLogs: result.data.treatmentLogs.map(
      (l: TreatmentLog) => new TreatmentLog(l)
    ),
  };
};

export const getTreatmentLog = async (
  patientId: string,
  treatmentLogId: string
): Promise<TreatmentLog> => {
  const result = await axios.get<TreatmentLog>(
    `${PATIENTS_URL}/${patientId}/treatment/${treatmentLogId}`
  );
  return new TreatmentLog(result.data);
};

// TODO: save and edit are similar, combine?
export const saveTreatmentLog = async (
  patientId: string,
  treatmentLog: TreatmentLog,
  dosageDeltas?: TreatmentLogDosageDelta[]
): Promise<any> => {
  const result = await axios.post(`${PATIENTS_URL}/${patientId}/treatment`, {
    ...treatmentLog,
    dosageDeltas,
  });
  return result.data;
};

// TODO: Types
export const editTreatmentLog = async (
  patientId: string,
  treatmentLogId: string,
  treatmentLog: TreatmentLog,
  dosageDeltas?: TreatmentLogDosageDelta[]
): Promise<any> => {
  const result = await axios.put(
    `${PATIENTS_URL}/${patientId}/treatment/${treatmentLogId}`,
    {
      ...treatmentLog,
      dosageDeltas,
    }
  );
  return result.data;
};

export const changeDoctor = async (
  patientId: string,
  doctorId: string
): Promise<Patient> => {
  const requestBody = {
    doctorId,
  };

  const result = await axios.put(`${PATIENTS_URL}/${patientId}`, requestBody);
  return result.data;
};

export const editPatient = async (
  patientId: string,
  // TODO: probably should just make a real type here
  patient: Partial<Patient> &
    Partial<User> & {
      dosagePreferences: {
        [key in TreatmentSubstance]: {
          frequency: number;
          dosageUnit: DosageUnit;
          frequencyUnit: string;
        };
      };
    }
): Promise<Patient> => {
  const {
    doctorId,
    averagePeriodCycleLength,
    averagePeriodDuration,
    periodMargin,
    dosagePreferences,
    dateOfBirth,
    selfManagement,
  } = patient;
  const requestBody = {
    doctorId,
    averagePeriodCycleLength,
    averagePeriodDuration,
    periodMargin,
    dosagePreferences,
    dateOfBirth,
    selfManagement,
  };

  const result = await axios.put(`${PATIENTS_URL}/${patientId}`, requestBody);
  return result.data;
};

/**
 * Returns a full history of a patient's treatment logs
 * @param patientId
 */
export const getAllTreatmentLogs = async (
  patientId: string
): Promise<{ fields: string[]; logs: TreatmentLog[] }> => {
  const result = await axios.get<{ fields: string[]; logs: TreatmentLog[] }>(
    `${PATIENTS_URL}/${patientId}/treatment/history`
  );
  const { data } = result;
  return {
    fields: data.fields,
    logs: data.logs.map((t) => new TreatmentLog(t)),
  };
};

export const updateHipaaConsent = async (
  patientId: string
): Promise<{ date: Date }> => {
  const result = await axios.post<{ date: Date }>(
    `${PATIENTS_URL}/${patientId}/hipaa-consent`
  );
  return result.data;
};

export const getStaticSchedules = async (): Promise<StaticSchedule[]> => {
  const result = await axios.get<StaticSchedule[]>(
    `${PATIENTS_URL}/static-schedules`
  );

  return result.data.map((s) => new StaticSchedule(s));
};

export const usePatientSWR = (patientId: string) => {
  const { data, error, isLoading } = useSWR<Patient>(
    `${PATIENTS_URL}/${patientId}`,
    fetcher
  );
  if (data) {
    return { patient: new Patient(data), error, isLoading };
  }
  return { error, isLoading };
};

export const usePatientTreatmentLogsSWR = (patientId: string) => {
  if (!patientId) {
    return {
      isLoading: false,
    };
  }
  const { data, error, isLoading, mutate } = useSWR<{
    fields: string[];
    logs: TreatmentLog[];
  }>(`${PATIENTS_URL}/${patientId}/treatment/history`, fetcher);

  return {
    mutate,
    error,
    isLoading,
    data: data?.logs.map((t) => new TreatmentLog(t)),
    fields: data?.fields,
  };
};
