/* eslint-disable react/no-danger */
import React from 'react';
import './notificationItem.scss';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Notification } from '../../../models';
import { UserType } from '../../../types';
import { useSessionContext } from '../../shared/global/sessionContext';

type NotificationProps = {
  notification: Notification;
  onClickToggleReadStatus: (notification: Notification) => void;
};

const NotificationItem: React.FC<NotificationProps> = ({
  notification,
  onClickToggleReadStatus,
}) => {
  const [session] = useSessionContext();
  let name = '';
  if (notification.creator.getId() === notification.receiver.getId()) {
    name = 'You';
  } else if (notification.creator.userType === UserType.doctor) {
    name = `Provider ${notification.creator.getFullName()}`;
  } else {
    name = notification.creator.getFullName();
  }
  return (
    <div
      className={classNames(
        'notification is-flex-column',
        notification.notificationType,
        {
          unread: notification.isUnread,
        }
      )}
    >
      <div className="content">
        <span className="message">
          <b>{name} </b>
          <span dangerouslySetInnerHTML={{ __html: notification.message }} />
          {notification.treatmentLog && (
            <>
              {' '}
              on{' '}
              <b>
                {moment
                  .utc(notification.treatmentLog.date)
                  .format('dddd, MMMM Do')}
                .
              </b>{' '}
              Click
              <Link
                to={
                  `${
                    session.isDoctorView
                      ? `/patient/${notification.treatmentLog.patient.getId()}`
                      : ''
                  }/calendar?log=${notification.treatmentLog.getId()}`!
                }
              >
                {' '}
                here{' '}
              </Link>{' '}
              to see more
            </>
          )}
          .
        </span>
        {session.user.getId() === notification.receiver.getId() && (
          <span className="actions">
            <button
              onClick={() => onClickToggleReadStatus(notification)}
              type="button"
              className="is-icon has-tooltip"
              data-tooltip={
                notification.isUnread ? 'mark as read' : 'mark as unread'
              }
            >
              <i
                className={classNames('fas', {
                  'fa-eye': notification.isUnread,
                  'fa-eye-slash': !notification.isUnread,
                })}
              />
            </button>
          </span>
        )}
      </div>
      <div className="additional-info">
        <span className="short-time">
          {moment(notification.createdDate).from(new Date())}
        </span>
        <span className="time">
          {moment(notification.createdDate).format('MMM Do, hh:mm A')}
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
