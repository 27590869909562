import { BaseModel, User, TreatmentLog } from '.';

// eslint-disable-next-line no-shadow
export enum NotificationType {
  // Doctor Notifications
  PATIENT_NOTE_ADDED = 'patientNoteAdded',
  SYMPTOMS_CHANGE = 'symptomsChange',
  NEW_PATIENT = 'newPatient',
  UNEXPECTED_PERIOD = 'unexpectedPeriod',

  // Patient Notifications
  DOCTOR_NOTE_ADDED = 'doctorNoteAdded',
  DOSAGE_ADJUSTMENT = 'dosageAdjustment',
  CALENDAR_SWITCH = 'calendarSwitch',
  CYCLE_DAY_RESET = 'cycleDayReset',
  ACCEPTED_PATIENT = 'acceptedPatient',
  REJECTED_PATIENT = 'rejectedPatient',
  SCHEDULE_RESET = 'scheduleReset',
}

export class Notification extends BaseModel {
  notificationType!: NotificationType;

  isUnread!: boolean;

  receiver!: User;

  creator!: User;

  treatmentLog?: TreatmentLog;

  affectedDate?: Date;

  message!: string;

  constructor(json?: Notification) {
    super();
    if (json) {
      const notification = Notification.fromJSON<Notification>(json, {
        receiver: new User(json.receiver),
        creator: new User(json.creator),
      });
      if (json.treatmentLog) {
        notification.treatmentLog = new TreatmentLog(json.treatmentLog);
      }
      return notification;
    }
  }
}
