import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { capitalize } from '../../../util/helpers';

const getParsedPageTitle = (pathname: string): string => {
  return capitalize(pathname.replace('/', '').replace('-', ' ')) || 'Home';
};

// TODO: replace this with helmet on a per page basis
export const DocumentTitle: React.FC = () => {
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState(
    getParsedPageTitle(location.pathname)
  );

  useEffect(() => {
    // TODO: figure out how to do this better for nested routes like /patient/id
    // see header
    setPageTitle(getParsedPageTitle(location.pathname));
  }, [location.pathname]);

  return ReactDOM.createPortal(
    ` | ${pageTitle}`,
    document.getElementsByTagName('title')[0]
  );
};
