import React, { useState, useEffect } from 'react';
import './notifications.page.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import NotificationItem from '../../features/notification/notificationItem';
import { Notification, Patient, User } from '../../../models';
import {
  getNotifications,
  toggleNotificationReadStatus,
  markAllNotificationAsRead,
  getPatientByUserId,
} from '../../../services';
import { useSessionContext } from '../../shared/global/sessionContext';
import {
  onApiErrorToast,
  onSuccessToast,
} from '../../../services/toasts.service';
import { CollectionCount } from '../../../types';
import { setPageTitle } from '../../shared/header/header';
import { Button, Loader, Tabs } from '../../shared';
import { PatientQuickNav } from '../../features/patient/patientQuickNav';

function NotificationsPage() {
  const { userId } = useParams<{ userId: string }>();

  const [patient, setPatient] = useState(null as unknown as Patient);
  const [, setUserName] = useState('Loading');
  const [viewMode, setViewMode] = useState('unread');
  const [session] = useSessionContext();
  const [notificationCollection, setNotificationCollection] = useState({
    results: [],
    total: 0,
  } as CollectionCount<Notification>);
  const [notificationsToDisplay, setNotificationsToDisplay] = useState({
    results: [],
    total: 0,
  } as CollectionCount<Notification>);

  const [isLoading, setIsLoading] = useState(true);

  const userIdToUse = userId || session.user.getId();
  const navigate = useNavigate();
  const allValue = {
    value: 'all',
    label: 'All Users',
  };
  const [filterByUsers, setFilterByUsers] = useState([allValue]);

  const onChangeByUser = (user: any) => {
    if (user.value === 'all') {
      setNotificationsToDisplay(notificationCollection);
    } else {
      const filteredNotifications = notificationCollection.results.filter(
        (n: Notification) => n.creator.getId() === user.value
      );
      setNotificationsToDisplay({
        results: filteredNotifications,
        total: filteredNotifications.length,
      });
    }
  };

  const fetchNotifications = () => {
    setIsLoading(true);
    getNotifications(userIdToUse, viewMode)
      .then((newNotifications) => {
        setNotificationCollection(newNotifications);
        setNotificationsToDisplay(newNotifications);
        setIsLoading(false);
        setPageTitle(`${newNotifications.userName}'s Notifications`);
        setUserName(`${newNotifications.userName}'s`);

        if (session.isDoctorView) {
          const creators = newNotifications.results
            // Convert notifications to just the creators
            .map((n: Notification) => n.creator)
            // Make a unique set
            // TODO: should i add ignoring yourself
            .reduce((users: User[], user: User) => {
              return users.some((u: User) => u.getId() === user.getId())
                ? users
                : [...users, user];
            }, [])
            // Convert it into an select options value
            .map((user) => {
              return {
                value: user.getId(),
                label: user.getFullName(),
              };
            });
          setFilterByUsers([allValue, ...creators]);
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
        } else {
          onApiErrorToast(error);
        }
      });
  };

  const onMarkAllNotificationsRead = () => {
    markAllNotificationAsRead(userIdToUse)
      .then(() => {
        fetchNotifications();
        onSuccessToast('Marked all notifications as read');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  const onClickToggleReadStatus = async (notification: Notification) => {
    // TODO: optimization, dont fetch, just change state
    await toggleNotificationReadStatus(
      userIdToUse,
      notification.getId(),
      !notification.isUnread
    );
    fetchNotifications();
  };

  useEffect(() => {
    fetchNotifications();
  }, [viewMode]);

  useEffect(() => {
    if (!session.isPatientView && userIdToUse !== session.user.getId()) {
      getPatientByUserId(userIdToUse)
        .then((patientData) => {
          setPatient(patientData);
        })
        .catch((error) => onApiErrorToast(error));
    }
  }, [session.isPatientView, session.user, userIdToUse]);

  return (
    <section className="notifications-page">
      {!session.isPatientView && patient && (
        <div className="page-wrapper">
          <PatientQuickNav patient={patient} />
        </div>
      )}
      <div className="page-wrapper is-flex-column">
        <div className="options">
          <Tabs
            tabs={[
              {
                label: `Unread ${
                  viewMode === 'unread'
                    ? `(${notificationsToDisplay.total})`
                    : ''
                }`,
                id: 'unread',
              },
              {
                label: `All ${
                  viewMode === 'all' ? `(${notificationsToDisplay.total})` : ''
                }`,
                id: 'all',
              },
            ]}
            onTabChange={(tab) => {
              setViewMode(tab.id);
            }}
            activeTabId={viewMode}
          />
          {userIdToUse === session.user.getId() && (
            <Button
              className="mark-all"
              onClick={onMarkAllNotificationsRead}
              isDisabled={viewMode === 'all'}
            >
              <i className="fas fa-check" /> Mark All as Read
            </Button>
          )}
          {session.isDoctorView && userIdToUse === session.user.getId() && (
            <label className="filter-by">
              <strong>Filter By User</strong>
              <Select
                classNamePrefix="react-select"
                className="react-select-container "
                options={filterByUsers}
                onChange={onChangeByUser}
                defaultValue={filterByUsers[0]}
              />
            </label>
          )}
        </div>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : (
          <div className="notifications">
            {notificationsToDisplay.results.map((notification) => (
              <NotificationItem
                key={notification.getId()}
                onClickToggleReadStatus={onClickToggleReadStatus}
                notification={notification}
              />
            ))}
            {!notificationsToDisplay.results.length && (
              <h4 className="empty">No Notifications</h4>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default NotificationsPage;
