import React from 'react';
import './landing-page.scss';

import { Link } from 'react-router-dom';
import LoginForm from '../../features/authentication/loginForm';
import { useSessionContext } from '../../shared/global/sessionContext';
import { LogoBlock } from '../../shared';
import BookmarkInstructions from '../../features/misc/BookmarkInstructions';

const LandingPage: React.FC = () => {
  const [session] = useSessionContext();
  return (
    <section className="landing-page">
      <div className="page-wrapper centered full-page">
        <div className="panel">
          <LogoBlock />
          <p className="text-caption has-box-shadow full-page">
            Artemis Calendar is a hormone management app, designed to help
            doctors and patients manage shared calendars.
          </p>
          <div className="wrapper has-box-shadow">
            <LoginForm>
              <Link to="/register" className="button text-centered">
                {session.user?.hasCompletedRegistration === false
                  ? 'Finish Registration'
                  : 'Register'}
              </Link>
              <Link to="/forgot-password" className="button is-link button--tertiary">
                Forgot Password?
              </Link>
              <BookmarkInstructions />
            </LoginForm>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
