export function setCookie(cname: string, cvalue: any, exdays = 1): void {
  try {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function getCookie(cname: string): string {
  try {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
}
