/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { countries, states } from '../../../assets';
import { Clinic } from '../../../models';
import { onSuccessToast, onApiErrorToast } from '../../../services';
import { editClinic } from '../../../services/clinic.service';
import { EditClinicFormData, SelectOption } from '../../../types';
import { convertSelectOptionsToValue, pick } from '../../../util/helpers';
import { TextField, Dropdown, Button } from '../../shared';

const countryOptions: SelectOption<string>[] = countries.map(
  (c: { country: string; code: string }) => ({
    value: c.country,
    label: c.country,
  })
);
const stateOptions: SelectOption<string>[] = states.map((state: string) => {
  return {
    value: state,
    label: state,
  };
});

type EditClinicFormProps = {
  clinic: Clinic;
};

const EditClinicForm: React.FC<EditClinicFormProps> = ({ clinic }) => {
  const methods = useForm<EditClinicFormData>();
  const {
    handleSubmit,
    formState: { dirtyFields },
  } = methods;
  const onSubmit = (values: EditClinicFormData) => {
    let dynamicOptionsArray = ['country', 'state'];
    if (
      (methods.watch('country') as unknown as SelectOption<string>)?.value !==
      'United States'
    ) {
      dynamicOptionsArray = dynamicOptionsArray.filter(
        (value) => value !== 'state'
      );
    }

    const editedValues = convertSelectOptionsToValue(
      values,
      dynamicOptionsArray
    );

    editClinic(clinic.getId(), pick(editedValues, [...dirtyFields]))
      .then((_response) => {
        onSuccessToast('Changes Saved!');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <TextField
            required
            name="name"
            initialValue={clinic!.name}
            validationOptions={{
              required: 'Required',
            }}
          >
            Name
          </TextField>
        </div>
        <div className="form-group">
          <TextField
            required
            name="address"
            initialValue={clinic.address}
            validationOptions={{
              required: 'Required',
            }}
          >
            Address
          </TextField>
        </div>
        <div className="form-group half-width">
          {(methods.watch('country') as unknown as SelectOption<string>)
            ?.value === 'United States' && (
            <Dropdown
              name="state"
              options={stateOptions}
              initialValue={clinic.state}
              required
            >
              State
            </Dropdown>
          )}
          {(methods.watch('country') as unknown as SelectOption<string>)
            ?.value !== 'United States' && (
            <TextField
              required
              name="state"
              initialValue={clinic.state}
              validationOptions={{
                required: 'Required',
              }}
            >
              State
            </TextField>
          )}
        </div>
        <div className="form-group half-width">
          <Dropdown
            name="country"
            options={countryOptions}
            initialValue={clinic.country}
            required
          >
            Country
          </Dropdown>
        </div>
        <div className="form-group half-width">
          <TextField name="website" initialValue={clinic.website}>
            Website
          </TextField>
        </div>
        <div className="form-group half-width">
          <TextField name="telephone" initialValue={clinic.telephone}>
            Telephone
          </TextField>
        </div>
        <Button type="submit">Update</Button>
      </form>
    </FormContext>
  );
};

export default EditClinicForm;
