import React from 'react';
import classNames from 'classnames';
import './button.scss';
import styled from 'styled-components';
import { Loader } from '..';

const StyledButton = styled.button``;

export type ButtonProps = {
  children?: any;
  onClick?: (e: any) => void;
  role?: string;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  tooltipText?: string;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  onClick,
  isLoading = false,
  className,
  tooltipText,
  isDisabled,
  role,
}) => (
  // eslint-disable-next-line react/button-has-type
  <StyledButton
    role={role}
    onClick={onClick}
    type={type}
    className={classNames(className, {
      loading: isLoading,
      'has-tooltip': !!tooltipText,
    })}
    disabled={isLoading || isDisabled}
    data-tooltip={tooltipText}
  >
    {isLoading ? <Loader /> : children}
  </StyledButton>
);
