import React, { useCallback, useMemo } from 'react';
import { CalendarViewMode } from '../../features/calendar/calendar';
import { getCookie, setCookie } from '../../../lib/cookies';
import { COOKIE_LAST_CALENDAR_VIEW_MODE } from '../../../util/constants';

export interface GlobalAppState {
  isFrozenAccount: boolean;
  isInDunning: boolean;
  isCancelled: boolean;
  lastCalendarPageViewMode?: CalendarViewMode;
  showAnnouncements?: boolean;
}

export type AppContextProps = {
  appState: GlobalAppState;
  updateAppState: (val: Partial<GlobalAppState>) => void;
  hideAnnouncements: () => void;
  resetAppState: () => void;
  isInGoodStanding: boolean;
};

const defaultValues: GlobalAppState = {
  isFrozenAccount: false,
  isInDunning: false,
  isCancelled: false,
  showAnnouncements: true,
  lastCalendarPageViewMode: CalendarViewMode.CALENDAR,
};

export const AppContext = React.createContext<AppContextProps>({
  appState: defaultValues,
  updateAppState: (val: Partial<GlobalAppState>) => {},
  hideAnnouncements: () => {},
  resetAppState: () => {},
  isInGoodStanding: true,
});

export const useAppContext = () => React.useContext(AppContext);

/**
 * Shared Global Context throughout the app for App things that can be triggered in multiple components
 * BUT OUTSIDE of sessions
 * @param props
 */
export const AppContextProvider: React.FC<{
  children?: Element | any;
  initialAppState?: GlobalAppState;
}> = ({ children, initialAppState }) => {
  const cookie = getCookie(COOKIE_LAST_CALENDAR_VIEW_MODE) as CalendarViewMode;
  const lastCalendarPageViewMode =
    cookie || defaultValues.lastCalendarPageViewMode;

  const values = {
    ...defaultValues,
    lastCalendarPageViewMode,
  };

  const [appState, setAppState] = React.useState(initialAppState || values);

  const resetAppState = useCallback(() => {
    setCookie(COOKIE_LAST_CALENDAR_VIEW_MODE, CalendarViewMode.CALENDAR);
    setAppState(defaultValues);
  }, []);

  const hideAnnouncements = useCallback(() => {
    setAppState({
      ...appState,
      showAnnouncements: false,
    });
  }, []);

  const updateAppState = (newValues: Partial<GlobalAppState>) => {
    setAppState({ ...appState, ...newValues });
  };

  const isInGoodStanding = useMemo(() => {
    return (
      !appState.isCancelled &&
      !appState.isFrozenAccount &&
      !appState.isInDunning
    );
  }, [appState.isCancelled, appState.isFrozenAccount, appState.isInDunning]);

  const defaultSessionContext = useMemo(() => {
    return {
      appState,
      updateAppState,
      resetAppState,
      hideAnnouncements,
      isInGoodStanding,
    };
  }, [appState]);

  // TODO: make the hide announcement state + reset states

  return (
    <AppContext.Provider value={defaultSessionContext}>
      {children}
    </AppContext.Provider>
  );
};
