import React from 'react';

import './callout.css';
import classNames from 'classnames';

export const Callout = ({
  children,
  danger,
}: {
  children: React.ReactNode;
  danger?: boolean;
}) => (
  <section
    className={classNames('callout', {
      danger,
    })}
  >
    {children}
  </section>
);
