import React from 'react';
import {
  ErrorMessage,
  useFormContext,
  ValidationOptions,
} from 'react-hook-form';
import './textField.scss';
import classNames from 'classnames';

export type TextFieldProps = {
  name: string;
  disabled?: boolean;
  type?: 'text' | 'number' | 'email';
  initialValue?: number | string;
  required?: boolean;
  placeholder?: string;
  validationOptions?: ValidationOptions;
  min?: number;
  max?: number;
};

export const TextField: React.FC<TextFieldProps> = ({
  placeholder,
  type = 'text',
  disabled = false,
  initialValue,
  name,
  required = false,
  children,
  validationOptions,
  max,
  min,
}) => {
  const { errors, register } = useFormContext();
  const textField = (
    <label
      className={classNames('text-field', {
        required,
      })}
      htmlFor={name}
    >
      {required && <span className="asterisk">*</span>}
      {children}
      <input
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={initialValue}
        required={required}
        autoComplete=""
        ref={register({ ...validationOptions })}
        aria-autocomplete="list"
        autoCapitalize="none"
        autoCorrect="off"
        spellCheck="false"
        min={min}
        max={max}
        minLength={type === 'text' ? min : 0}
        maxLength={type === 'text' ? max : 1000000}
      />
      {errors[name] && (
        <span className="error">
          <ErrorMessage errors={errors} name={name}>
            {({ message }) => message}
          </ErrorMessage>
        </span>
      )}
    </label>
  );

  return textField;
};
