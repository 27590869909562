import React, { useState } from 'react';
import moment from 'moment';
import {
  onSuccessToast,
  onApiErrorToast,
} from '../../../services/toasts.service';
import { cancelSubscription } from '../../../services';
import { User } from '../../../models';
import { Button, ConfirmModal } from '../../shared';
import { WhyAreYouLeaving } from './whyAreYouLeaving';
import { Modal } from '../../shared/modal/modal';

type CancelAccountProps = {
  user: User;
  billingInfo: Record<string, any>;
  onCancelAccount: () => void;
};

export const CancelAccount: React.FC<CancelAccountProps> = ({
  user,
  billingInfo,
  onCancelAccount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLastModalOpen, setIsLastModalOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const cancelUserSubscription = (
    reasonsWhy?: string[],
    explained?: string
  ) => {
    setIsCancelling(true);
    cancelSubscription(user.getId(), reasonsWhy, explained)
      .then((_periodEnd: Date) => {
        onSuccessToast(
          `Your subscription will be cancelled at the end of this billing cycle.`
        );
        onCancelAccount();
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsCancelling(false));
  };

  const periodEndFormatted = billingInfo.planDetails?.currentPeriodEnd
    ? moment(billingInfo!.planDetails.currentPeriodEnd * 1000).format(
        'MMMM DD, YYYY'
      )
    : undefined;

  return (
    <>
      <Button
        isLoading={isCancelling}
        className="danger cancel-subscription"
        onClick={() => setIsModalOpen(true)}
      >
        Cancel Subscription
      </Button>
      <ConfirmModal
        onConfirm={() => setIsModalOpen(false)}
        onCancel={() => {
          setIsModalOpen(false);
          setIsLastModalOpen(true);
        }}
        isOpen={isModalOpen}
        message={`Are you sure you want to cancel your subscription? Cancelling this subscription will prevent future access to this website. However you will still have access until ${periodEndFormatted}.`}
        reverseOrder
        cancelText="Yes I want to cancel"
        confirmText="Go back"
      />
      <Modal
        isOpen={isLastModalOpen}
        onClose={() => setIsLastModalOpen(false)}
        title="Cancel Subscription"
      >
        <WhyAreYouLeaving
          onSubmit={(reasonsWhy, explained) => {
            setIsLastModalOpen(false);
            cancelUserSubscription(reasonsWhy, explained);
          }}
        />
      </Modal>
    </>
  );
};
