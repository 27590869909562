import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '..';
import './tabs.scss';

export interface Tab {
  label: string;
  id: string;
  classNames?: string;
  icon?: string;
  href?: string;
}

export type TabsProps = {
  tabs: Tab[];
  onTabChange: (tab: Tab) => void;
  activeTabId: string;
};

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  onTabChange,
  activeTabId,
}) => {
  // TODO: 2020/12/28 - seems a bit odd, probably should be either a prop or something related to the tabs prop
  return (
    <div className="tabs-container">
      {tabs.map((tab) => {
        const Component = tab.href ? (Link as any) : Button;

        const props: Record<string, any> = {
          key: tab.id,
          onClick: () => {
            onTabChange(tab);
          },
        };
        if (tab.href) {
          props.to = tab.href;
        }
        return (
          <Component
            {...props}
            className={classNames('tab', tab.classNames, {
              active: tab.id === activeTabId,
              button: tab.href,
            })}
          >
            {tab.icon && <i className={tab.icon} />}
            {tab.label}
          </Component>
        );
      })}
    </div>
  );
};
