import React from 'react';

export type ExternalLinkProps = {
  href: string;
};
export const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  children,
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className="external-link"
    >
      {children}
    </a>
  );
};
