import { DosageUnit, TreatmentSubstance } from '../types';
import { BaseModel } from './base.model';
import { FrequencyUnit } from '../types/enums';

export class StaticSchedule extends BaseModel {
  name!: string;

  dosageSchedule!: number[];

  substance!: TreatmentSubstance;

  dosageUnit!: DosageUnit;

  frequencyUnit!: FrequencyUnit;

  frequency!: number;

  constructor(json?: StaticSchedule) {
    super();
    if (json) {
      const schedule = StaticSchedule.fromJSON<StaticSchedule>(json, {});
      return schedule;
    }
  }
}
