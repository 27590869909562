import { BaseModel, Clinic, User } from '.';
import { TreatmentType } from '../types';

export class Doctor extends BaseModel {
  school!: string;

  nip!: string;

  graduationYear!: number;

  treatmentsOffered!: TreatmentType[];

  user!: User;

  clinicId!: string;

  clinic?: Clinic;

  website?: string;

  totalPatients?: number;

  isApproved!: boolean;

  constructor(json?: Doctor) {
    super();
    if (json) {
      const doctor = Doctor.fromJSON<Doctor>(json, {
        user: new User(json.user),
      });
      if (json.clinic) {
        doctor.clinic = new Clinic(json.clinic);
      }
      if (json.user) {
        doctor.user = new User(json.user);
      }
      return doctor;
    }
  }
}
