import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { validatePromoCode } from '../../../services';
import { Button } from '../../shared';
import './validatePromoCode.scss';

type ValidatePromoCodeProps = {
  onApplyPromoCode: (promoCode: string) => void;
  className?: string;
};

/**
 * Used to verify a promo code, and displays the value of the code in human readable
 * Has a prop to do something after actually verifying it
 */
const ValidatePromoCode: React.FC<ValidatePromoCodeProps> = ({
  className,
  onApplyPromoCode,
}) => {
  const { errors, register, watch, setError, clearError } = useFormContext();
  const [validPromoCode, setValidPromoCode] = useState<string>('');
  const [promoCodeInWords, setPromoCodeInWords] = useState<string | null>('');
  const promoCodeWatched = watch('promoCode');

  if (promoCodeWatched !== validPromoCode && validPromoCode) {
    setValidPromoCode('');
    setPromoCodeInWords('');
    clearError('promoCode');
  }

  const onClickValidatePromoCode = async () => {
    if (promoCodeWatched) {
      const promoCodeDescribed = await validatePromoCode(promoCodeWatched);
      if (promoCodeDescribed === null) {
        setValidPromoCode('');
        setError('promoCode', 'invalid', 'The Promo Code is not valid');
      } else {
        setValidPromoCode(promoCodeWatched);
        clearError('promoCode');
        setPromoCodeInWords(promoCodeDescribed);
        onApplyPromoCode(promoCodeWatched);
      }
    } else {
      setError('promoCode', 'invalid', 'No promo code provided');
    }
  };
  return (
    <div className={`validate-promo-code form-group ${className}`}>
      <label>
        <span className="label-value">Promotional Codes</span>
        <p className="description">
          Apply a promo code here if you have one. Promo Codes do not stack.
        </p>
        <input
          type="text"
          name="promoCode"
          className="is-textbox"
          placeholder="Promo Code"
          ref={register()}
        />
        {promoCodeInWords && (
          <span className="promo-described">Success! {promoCodeInWords}</span>
        )}
        <span className="error">
          {errors.promoCode && errors.promoCode.message}
        </span>
      </label>
      <Button
        isDisabled={validPromoCode === promoCodeWatched}
        onClick={onClickValidatePromoCode}
      >
        Apply Coupon
      </Button>
    </div>
  );
};
ValidatePromoCode.defaultProps = {
  className: '',
};

export default ValidatePromoCode;
