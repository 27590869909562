import React from 'react';
import './profile.page.css';
import { useParams } from 'react-router-dom';
import PatientProfile from '../../features/profile/patientProfile';

const PatientProfilePage: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();

  return (
    <section className="profile-page">
      <PatientProfile patientId={patientId || ''} />
    </section>
  );
};
export default PatientProfilePage;
