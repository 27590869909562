import {
  Chart,
  ChartConfiguration,
  ChartOptions,
  LinearScale,
  LineController,
  CategoryScale,
  PointElement,
  LineElement,
  PieController,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import React, { useEffect, useRef } from 'react';

const PRIMARY_COLOR = 'rgba(183, 173, 255, 1)';

// TODO: 2020/12/22 - loading views
export type GraphProps = {
  labels: string[];
  datasets: { label: string; data: any; backgroundColor?: string | string[] }[];
  graphTitle?: string;
  graphType?: 'line' | 'pie';
  xLabel?: string;
  yLabel?: string;
  options?: ChartOptions;
};

function getGraphDisplayConfig({
  yLabel,
  xLabel,
  options,
}: {
  yLabel?: string;
  xLabel?: string;
  options?: ChartOptions;
}): ChartOptions {
  const defaultOptions = {
    responsive: true,
    // Needs to be true otherwise these graphs just grow in height indefinitely
    maintainAspectRatio: true,
    ...options,
  };

  if (options) {
    return defaultOptions;
  }
  return {
    ...defaultOptions,
    scales: {
      yAxes: {
        title: {
          text: yLabel,
          display: true,
        },
        ticks: {},
      },
      xAxes: {
        title: {
          text: xLabel,
          display: true,
        },
      },
    },
  };
}

export function Graph({
  labels,
  datasets,
  graphTitle,
  graphType = 'line',
  yLabel,
  xLabel,
  options,
}: GraphProps) {
  const chartRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    let chart: Chart;
    Chart.register([
      LinearScale,
      LineController,
      CategoryScale,
      PointElement,
      LineElement,
      PieController,
      ArcElement,
      Tooltip,
      Legend,
    ]);
    if (chartRef?.current) {
      const context = chartRef!.current!.getContext('2d') as any;
      // TODO: 2020/12/22 - fix this weird bouncing glitch when changing time periods

      const displayOptions = getGraphDisplayConfig({ yLabel, xLabel, options });
      const config: ChartConfiguration = {
        type: graphType,
        data: {
          labels,
          datasets: datasets.map((d) => ({
            borderColor: PRIMARY_COLOR,
            backgroundColor: PRIMARY_COLOR,
            ...d,
          })),
        },
        options: displayOptions,
      };
      chart = new Chart(context, config);
    }
    // Chart references were being recreated each time on rerender, so the useeffect will deal with that otherwise
    // chart will throw an error saying the instance already exists
    return () => {
      chart.destroy();
    };
  }, [datasets, graphType, labels]);

  return (
    <section className="graph-container">
      <h3 className="text-centered">{graphTitle}</h3>
      <canvas ref={chartRef} />
    </section>
  );
}
