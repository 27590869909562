import React from 'react';
import { AppContext, AppContextProps } from './appContext';

export type withAppContextProps = {
  globalAppContext: AppContextProps;
};

export function withAppContext(Component: any) {
  return function AppContextComponent(props: any) {
    return (
      <AppContext.Consumer>
        {(appState) => <Component {...props} globalAppContext={appState} />}
      </AppContext.Consumer>
    );
  };
}
