import React from 'react';
import styled from 'styled-components';
import { getDeviceBrowser } from '../../../util/helpers';
import { SVGIcon } from '../../shared/icons/svgIcon';

const StyledList = styled.ul`
  li {
    margin: 10px 0;
  }
  span {
    --global-font-size-multiplier: 1.5;
  }
`;

function BookmarkInstructions() {
  const { isSafari, isChrome, isFirefox } = getDeviceBrowser();
  let icon;
  let browser = '';
  let instruction: any = 'Add to Home Screen';
  if (isSafari) {
    icon = (
      <SVGIcon
        label=""
        height={16}
        width={16}
        iconName="arrow-up-from-square"
        style={{ position: 'relative', top: '3px', marginRight: '5px' }}
      />
    );
    browser = 'Safari';
    instruction = (
      <>
        <SVGIcon
          label=""
          height={16}
          width={16}
          iconName="plus-square-regular"
          style={{ position: 'relative', top: '3px', marginRight: '5px' }}
        />
        &quot;Add to Home Screen&ldquo;
      </>
    );
  } else if (isChrome) {
    icon = <i className="fa-solid fa-ellipsis-vertical" />;
    browser = 'Chrome';
    instruction = 'Add to Home Screen';
  } else if (isFirefox) {
    icon = <i className="fa-solid fa-ellipsis-vertical" />;
    browser = 'Firefox';
    instruction = 'Install';
  }
  return (
    <div className="mobile-only">
      <hr />
      <br />
      <h4 className="text-centered">
        How To Add To Your Phone&apos;s Home Screen
      </h4>
      <StyledList>
        <li>
          Click <span>{icon}</span> on your <strong>{browser}</strong> browser
        </li>
        <li>
          Select <strong>{instruction}</strong>
        </li>
        <li>
          Select <strong>Add</strong> to finish
        </li>
      </StyledList>
    </div>
  );
}

export default BookmarkInstructions;
