import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormContext, useForm } from 'react-hook-form';
import { forgotPassword } from '../../../services';
import { FORM_VALIDATION } from '../../../util';
import { LogoBlock, TextField, Button } from '../../shared';

const ForgotPasswordPage: React.FC = () => {
  const methods = useForm<{ email: string }>();
  const { handleSubmit } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [hasSentEmail, setHasSetEmail] = useState(false);

  const onSubmit = (values: { email: string }) => {
    setIsLoading(true);
    forgotPassword(values.email)
      .then(() => {
        setHasSetEmail(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="forgot-password-page">
      <div className="page-wrapper is-flex-column full-page">
        <LogoBlock />
        <div className="wrapper has-box-shadow">
          <FormContext {...methods}>
            {!hasSentEmail && (
              <form
                className="forgot-password-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h4>Forgot Password</h4>
                <hr />
                <p className="description">
                  Forgot your password? Enter your email below and we&apos;ll
                  send you instructions on how to reset your password.
                </p>
                <div className="form-group">
                  <TextField
                    name="email"
                    placeholder="Email"
                    required
                    type="email"
                    validationOptions={{
                      required: 'Required',
                      pattern: {
                        value: FORM_VALIDATION.VALID_EMAIL,
                        message: 'invalid email address',
                      },
                    }}
                  >
                    Email
                  </TextField>
                </div>
                <Button type="submit" isLoading={isLoading}>
                  Submit
                </Button>
              </form>
            )}
          </FormContext>
          {hasSentEmail && (
            <>
              <h3>Success!</h3>
              <p className="message">
                If an account with this email exists, you will receive
                instructions to reset your password.
              </p>
            </>
          )}
          <Link to="/" className="button is-link">
            Back
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordPage;
