import { User, Doctor, Patient } from '.';
import { UserType, JWTData } from '../types';

export class ElixirSession {
  isAuthenticated: boolean;

  redirectPathOnAuthentication?: string;

  sessionInfo: JWTData;

  constructor(session?: Partial<ElixirSession>) {
    this.isAuthenticated = session?.isAuthenticated || false;
    this.redirectPathOnAuthentication =
      session?.redirectPathOnAuthentication || '/';
    this.sessionInfo =
      session?.sessionInfo ||
      ({ additionalInfo: {}, user: new User() } as JWTData);
  }

  get user(): User {
    return this.sessionInfo.user;
  }

  get userType(): UserType | undefined {
    return this.user.userType;
  }

  get isAdmin(): boolean {
    return this.userType === UserType.admin;
  }

  get isPatientView(): boolean {
    return this.userType === UserType.patient && !!this.patient;
  }

  get isDoctorView(): boolean {
    return this.userType === UserType.doctor && !!this.doctor;
  }

  get isSelfManagedView(): boolean {
    return this.userType === UserType.patient && this.patient!.selfManagement;
  }

  get canAccessAdditionalViews() {
    return this.isDoctorView || this.isSelfManagedView;
  }

  get patient(): Patient | undefined {
    return this.sessionInfo.additionalInfo.patient;
  }

  set patient(patient: Patient | undefined) {
    this.sessionInfo.additionalInfo.patient = patient;
  }

  get doctor(): Doctor | undefined {
    return this.sessionInfo.additionalInfo.doctor;
  }

  set doctor(doctor: Doctor | undefined) {
    this.sessionInfo.additionalInfo.doctor = doctor;
  }
}
