import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../../features/authentication/loginForm';
import { useSessionContext } from '../../shared/global/sessionContext';
import { LogoBlock } from '../../shared';

const LoginPage: React.FC = () => {
  /**
   * We add a little catch here for changing the register text IN the case they didnt finish their registration
   */
  const [session] = useSessionContext();

  return (
    <section className="login-page">
      <div className="page-wrapper is-flex-column full-page">
        <LogoBlock />
        <div className="wrapper has-box-shadow">
          <LoginForm>
            <Link to="register" className="button text-centered">
              {session.user?.hasCompletedRegistration === false
                ? 'Finish Registration'
                : 'Register'}
            </Link>
            <Link
              to="forgot-password"
              className="button is-link button--tertiary"
            >
              Forgot Password?
            </Link>
          </LoginForm>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
