import React from 'react';

import './Pill.css';

export function Pill({
  children,
  number,
}: {
  children: React.ReactNode;
  number?: number;
}) {
  return (
    <div className="pill grid--spacing-5 align--center justify--center">
      <span className="pill--content">{children}</span>
      {number && (
        <span className="pill--number grid align--center justify--center">
          {number}
        </span>
      )}
    </div>
  );
}
