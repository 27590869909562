import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Clinic } from '../../../models';
import './clinic.page.css';
import { getClinic } from '../../../services/clinic.service';
import { onApiErrorToast } from '../../../services/toasts.service';
import { setPageTitle } from '../../shared/header/header';
import DoctorClinicTableItem from '../../features/clinic/doctorClinicTableItem';
import EditClinicForm from '../../features/clinic/editClinicForm';
import { TableHeader, Loader, Table } from '../../shared';

const ClinicPage: React.FC = () => {
  const { clinicId = '' } = useParams<{ clinicId: string }>();
  const [clinic, setClinicInfo] = useState<Clinic>(new Clinic());
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getClinic(clinicId)
      .then((clinicData) => {
        setClinicInfo(clinicData);
        setPageTitle(`${clinicData.name}'s Overview`);
      })
      .catch((error) => {
        onApiErrorToast(error);
        if (error.response?.status === 403) {
          navigate('/unauthorized');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clinicId]);

  const tableHeaders = [
    { label: 'Name' },
    { label: 'Treatments Offered' },
    { label: 'Total Patients' },
  ] as TableHeader[];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="clinic-page">
      <div className="page-wrapper is-flex-column">
        <EditClinicForm clinic={clinic} />
        <div className="divider" />

        <div className="doctors-list">
          <h3 className="title text-centered">Providers Under this Clinic </h3>
          <hr />
          <Table
            headers={tableHeaders}
            collection={clinic.doctors!.map((doctor) => ({
              id: doctor.getId(),
              doctor,
            }))}
            component={DoctorClinicTableItem}
            isFetchingData={isLoading}
            itemLabel="provider"
          />
        </div>
      </div>
    </section>
  );
};
export default ClinicPage;
