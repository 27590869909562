import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import {
  Callout,
  Graph,
  Pills,
  TitleBlock,
  setPageTitle,
  Pill,
  Banner,
  Button,
} from '../../shared';
import { useAppContext, useSessionContext } from '../../shared/global';
import { usePatientSWR, usePatientTreatmentLogsSWR } from '../../../services';

import 'react-loading-skeleton/dist/skeleton.css';
import { TreatmentLog } from '../../../models';
import { RATING_PULSE_VALUES } from '../../../util';
import { PatientQuickNav } from '../../features/patient/patientQuickNav';
import './patientAnalytics.css';
import { useAnalytics } from '../../../hooks';

type AnalyticBreakdown = {
  totalSymptoms: number;
  totalPulse: number;
  averageNumberSymptoms: number;
  averagePulse: number | null;
  mostCommonSymptoms: Record<string, number>;
};

type PatientAnalyticsInfo = {
  overallBreakdown: {
    symptomsComparison: any[];
    totalPatientNotes: number;
    mostRecentPulse: {
      date?: Date;
      pulse?: number;
    };
  } & AnalyticBreakdown;
  last7DaysBreakdown: {
    [key: string]: AnalyticBreakdown;
  } & { averagePulse?: number; mostCommonSymptoms?: Record<string, number> };
  last30DaysBreakdown: {
    [key: string]: AnalyticBreakdown;
  } & { averagePulse?: number; mostCommonSymptoms?: Record<string, number> };
  monthlyBreakdown: {
    [key: string]: AnalyticBreakdown & {
      count: number;
    };
  };
  cycleDayBreakdown: {
    [key: number | string]: {
      count: number;
    } & AnalyticBreakdown;
  };
  totalCount: number;
};

function calculateAveragesForBreakdown(
  breakdown: Record<string | number, any>
) {
  const newBreakdown = { ...breakdown };
  Object.keys(newBreakdown).forEach((key) => {
    newBreakdown[key].averagePulse =
      newBreakdown[key].totalPulse / newBreakdown[key].count;

    newBreakdown[key].averageNumberSymptoms =
      newBreakdown[key].totalSymptoms / newBreakdown[key].count;
  });
  return newBreakdown;
}

function calculateBreakdownForTimePeriod(breakdown: Record<string, any>) {
  const newBreakdown = { ...breakdown };
  let totalEntriesWithPulse = 0;
  let totalPulseScore = 0;
  let averagePulse: number | undefined;
  const mostCommonSymptoms: Record<string, any> = {};
  Object.entries(breakdown).forEach(([, value]) => {
    if (value.totalPulse) {
      totalEntriesWithPulse += 1;
      totalPulseScore += value.totalPulse;
    }

    Object.entries(value.mostCommonSymptoms).forEach(([symptom, count]) => {
      if (symptom !== 'Period Start') {
        if (mostCommonSymptoms[symptom]) {
          mostCommonSymptoms[symptom] += count;
        } else {
          mostCommonSymptoms[symptom] = count;
        }
      }
    });
  });
  if (totalEntriesWithPulse) {
    averagePulse = totalPulseScore / totalEntriesWithPulse;
  }

  newBreakdown.averagePulse = averagePulse;
  newBreakdown.mostCommonSymptoms = mostCommonSymptoms;

  return newBreakdown;
}

function calculateBreakdown(
  key: string | number,
  {
    breakdown,
    pulse,
    symptoms,
  }: {
    breakdown: Record<string | number, any>;
    pulse?: number;
    symptoms: string[];
  }
) {
  const newBreakdown = { ...breakdown };
  if (newBreakdown[key]) {
    if (pulse) {
      newBreakdown[key].totalPulse = pulse + newBreakdown[key].totalPulse;
    }
    newBreakdown[key].totalSymptoms =
      symptoms.length + newBreakdown[key].totalSymptoms;
    newBreakdown[key].count = 1 + newBreakdown[key].count;
    symptoms.forEach((symptom: string) => {
      if (symptom !== 'Period Start') {
        if (newBreakdown[key].mostCommonSymptoms[symptom]) {
          newBreakdown[key].mostCommonSymptoms[symptom] += 1;
        } else {
          newBreakdown[key].mostCommonSymptoms[symptom] = 1;
        }
      }
    });
  } else {
    newBreakdown[key] = {
      totalPulse: pulse || 0,
      totalSymptoms: symptoms.length,
      count: 1,
      mostCommonSymptoms: {},
      averagePulse: null,
    };
    symptoms.forEach((symptom: string) => {
      if (symptom !== 'Period Start') {
        if (newBreakdown[key].mostCommonSymptoms[symptom]) {
          newBreakdown[key].mostCommonSymptoms[symptom] += 1;
        } else {
          newBreakdown[key].mostCommonSymptoms[symptom] = 1;
        }
      }
    });
  }
  return newBreakdown;
}

// TODO: move into server
/**
 *       
 * Total Symptoms Compared to last Month Average Pulse Compared to Last Month
      Sleep Values Total Hormones taken This day last month - SYMPTOMS_LIST What
      cycle day does their period start tend to be days from last period average
      period length expected period
 * @param data 
 * @returns 
 */
function calculateAnalytics(data: TreatmentLog[]) {
  const newAnalytics: PatientAnalyticsInfo = {
    overallBreakdown: {
      totalSymptoms: 0,
      totalPulse: 0,
      averageNumberSymptoms: 0,
      averagePulse: null,
      mostCommonSymptoms: {},
      symptomsComparison: [],
      totalPatientNotes: 0,
      mostRecentPulse: {},
    },
    last30DaysBreakdown: {},
    last7DaysBreakdown: {},
    monthlyBreakdown: {},
    cycleDayBreakdown: {},
    totalCount: 0,
  };

  let daysWithRecordedPulse = 0;
  let totalPulseScore = 0;
  let totalSymptoms = 0;
  let totalPatientNotes = 0;
  const mostCommonSymptoms: Record<string, number> = {};
  let last7DaysBreakdown: Record<string, any> = {};
  let last30DaysBreakdown: Record<string, any> = {};

  let cycleDayBreakdown: Record<number, any> = {};
  let monthlyBreakdown: Record<string, any> = {};

  const today = moment();
  const daysAgo7 = today.clone().subtract(7, 'days');
  const daysAgo30 = today.clone().subtract(30, 'days');

  const mostRecentPulse: { date?: Date; pulse?: number } = {};

  data.forEach((log) => {
    const { pulse, symptoms, cycleDayNumber, patientNotes, date } = log;
    const month = moment(date).format("MM 'YY");

    if (patientNotes) {
      totalPatientNotes += 1;
    }
    if (pulse) {
      if (!mostRecentPulse.date || moment(date).isAfter(mostRecentPulse.date)) {
        mostRecentPulse.date = date;
        mostRecentPulse.pulse = pulse;
      }

      daysWithRecordedPulse += 1;

      totalPulseScore += pulse;
    }
    if (symptoms.length) {
      totalSymptoms += symptoms.length;

      symptoms.forEach((symptom) => {
        if (symptom !== 'Period Start') {
          if (mostCommonSymptoms[symptom]) {
            mostCommonSymptoms[symptom] += 1;
          } else {
            mostCommonSymptoms[symptom] = 1;
          }
        }
      });
    }

    if (moment(date).isAfter(daysAgo7)) {
      last7DaysBreakdown = calculateBreakdown(moment(date).format('DD/MM'), {
        breakdown: last7DaysBreakdown,
        pulse,
        symptoms,
      });
    }
    if (moment(date).isAfter(daysAgo30)) {
      last30DaysBreakdown = calculateBreakdown(moment(date).format('DD/MM'), {
        breakdown: last30DaysBreakdown,
        pulse,
        symptoms,
      });
    }

    cycleDayBreakdown = calculateBreakdown(cycleDayNumber, {
      breakdown: cycleDayBreakdown,
      pulse,
      symptoms,
    });

    monthlyBreakdown = calculateBreakdown(month, {
      breakdown: monthlyBreakdown,
      pulse,
      symptoms,
    });
  });
  newAnalytics.overallBreakdown.mostRecentPulse = mostRecentPulse;
  newAnalytics.overallBreakdown.totalPatientNotes = totalPatientNotes;
  newAnalytics.overallBreakdown.mostCommonSymptoms = mostCommonSymptoms;

  const topSymptoms = Object.entries(mostCommonSymptoms).sort(
    (a, b) => b[1] - a[1]
  );
  const topFreqSymptoms = topSymptoms.slice(0, Math.min(6, topSymptoms.length));
  const bottomFreqSymptomsCount = topSymptoms
    .slice(Math.min(6, topSymptoms.length), topSymptoms.length)
    .reduce((acc, [, s]) => acc + s, 0);

  newAnalytics.overallBreakdown.symptomsComparison = [
    ...topFreqSymptoms,
    ['other', bottomFreqSymptomsCount],
  ];

  newAnalytics.overallBreakdown.totalPulse = totalPulseScore;
  if (daysWithRecordedPulse) {
    const averagePulseScore = totalPulseScore / daysWithRecordedPulse;
    newAnalytics.overallBreakdown.averagePulse = averagePulseScore;
  }

  newAnalytics.overallBreakdown.totalSymptoms = totalSymptoms;
  const averageNumberSymptoms = totalSymptoms / data.length;
  newAnalytics.overallBreakdown.averageNumberSymptoms = averageNumberSymptoms;

  cycleDayBreakdown = calculateAveragesForBreakdown(cycleDayBreakdown);
  newAnalytics.cycleDayBreakdown = cycleDayBreakdown;

  monthlyBreakdown = calculateAveragesForBreakdown(monthlyBreakdown);
  newAnalytics.monthlyBreakdown = monthlyBreakdown;
  newAnalytics.totalCount = data.length;

  last7DaysBreakdown = calculateBreakdownForTimePeriod(last7DaysBreakdown);
  last30DaysBreakdown = calculateBreakdownForTimePeriod(last30DaysBreakdown);

  newAnalytics.last7DaysBreakdown = last7DaysBreakdown;
  newAnalytics.last30DaysBreakdown = last30DaysBreakdown;

  return newAnalytics;
}

const PulseEmoji = ({ score }: { score?: number }) => {
  function getPulseEmoji() {
    return RATING_PULSE_VALUES.find(
      ([number]) => number === Math.floor(score!)
    )?.[1];
  }

  if (!score) {
    return <>N/A</>;
  }

  return <i className={`score--emoji ${getPulseEmoji()}`} />;
};

const PatientGraphs = ({
  analytics,
  patientId,
  isLoading,
}: {
  analytics: PatientAnalyticsInfo;
  patientId: string;
  isLoading: boolean;
}) => {
  const { error, mutate } = usePatientTreatmentLogsSWR(patientId as string);
  const { appState, isInGoodStanding } = useAppContext();
  const [session] = useSessionContext();
  const { patient } = usePatientSWR(patientId as string);

  function generateChartOptions({
    xTitle,
    yTitle,
    yMax,
    xMax,
    yMin,
  }: {
    xTitle?: string;
    yTitle?: string;
    yMax?: number;
    xMax?: number;
    yMin?: number;
  }) {
    return {
      scales: {
        yAxes: {
          min: yMin || 0,
          max: yMax,
          title: {
            text: yTitle,
            display: true,
          },
          ticks: {},
        },
        xAxes: {
          min: 0,
          max: xMax,
          title: {
            text: xTitle,
            display: true,
          },
        },
      },
    };
  }

  if (error) {
    return <Callout>Sorry, we couldn&apos;t load your data</Callout>;
  }

  const showAnalytics =
    isInGoodStanding && analytics.totalCount > 7 && !isLoading;

  return (
    <>
      {analytics.totalCount < 7 && !isLoading && (
        <Callout danger>
          <p className="text-centered">
            We don&apos;t have enough data to draw any insights yet. Please wait
            for at least 7 days to pass before checking back.
          </p>
        </Callout>
      )}
      {showAnalytics && (
        <Button onClick={(e) => mutate!()}>Refresh Data</Button>
      )}

      {session.isPatientView &&
        (appState.isCancelled || appState.isFrozenAccount) && (
          <Callout danger>
            <p className="text-centered">
              This feature is being limited because your account is{' '}
              {appState.isCancelled && 'cancelled'}{' '}
              {appState.isFrozenAccount && 'frozen'}.
            </p>
          </Callout>
        )}
      {!session.isPatientView &&
        (patient?.user.billing?.isCancelled ||
          patient?.user.billing?.isFrozen()) && (
          <Callout danger>
            <p className="text-centered">
              This feature is being limited because the patient has cancelled
              their account and/or is frozen.
            </p>
          </Callout>
        )}
      <h2 className="text-centered">See Changes in your Mood</h2>
      <Callout>
        {showAnalytics ? (
          <>
            <p>How to read your mood scores</p>
            <div className="grid grid--5 grid--spacing-5">
              {RATING_PULSE_VALUES.map(([value]) => (
                <span key={value}>
                  <PulseEmoji score={value} /> = {value}
                </span>
              ))}
            </div>
          </>
        ) : (
          <Skeleton height={10} />
        )}
      </Callout>
      <br />
      <div className="grid grid--3  grid--spacing-10">
        <Callout>
          <strong>Most Recent Mood</strong>
          <br />
          {showAnalytics ? (
            <>
              <PulseEmoji
                score={analytics!.overallBreakdown!.mostRecentPulse?.pulse || 0}
              />
              {analytics.overallBreakdown!.mostRecentPulse?.date && (
                <span className="description">
                  as of{' '}
                  {moment(
                    analytics!.overallBreakdown!.mostRecentPulse?.date
                  ).format('MM/DD, YYYY')}
                </span>
              )}
            </>
          ) : (
            <Skeleton height={10} />
          )}
        </Callout>
        <Callout>
          <strong>Last 7 Days Average Mood</strong>
          <br />
          {showAnalytics ? (
            <PulseEmoji score={analytics.last7DaysBreakdown.averagePulse} />
          ) : (
            <Skeleton height={10} />
          )}
        </Callout>
        <Callout>
          <strong>Last 30 Days Average Mood</strong>
          <br />
          {showAnalytics ? (
            <PulseEmoji score={analytics.last30DaysBreakdown.averagePulse} />
          ) : (
            <Skeleton height={10} />
          )}
        </Callout>
      </div>
      <br />
      <div className="grid grid--2 grid--1-mobile grid--spacing-10">
        {showAnalytics ? (
          <>
            <Graph
              labels={Object.entries(analytics.last7DaysBreakdown)
                .filter(
                  ([key, value]) =>
                    key !== 'averagePulse' &&
                    key !== 'mostCommonSymptoms' &&
                    (value as any).totalPulse > 0
                )

                .map(([key]) => `${key}`)}
              datasets={[
                {
                  label: 'Mood over last 7 days',
                  data: Object.entries(analytics.last7DaysBreakdown)
                    .filter(
                      ([key, value]) =>
                        key !== 'averagePulse' &&
                        key !== 'mostCommonSymptoms' &&
                        (value as any).totalPulse > 0
                    )
                    .map(([key, value]) => {
                      return (value as any).totalPulse;
                    }),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Mood',
                xTitle: 'Date',
              })}
              graphTitle="Mood over the last 7 days"
              graphType="line"
            />
            <Graph
              labels={Object.entries(analytics.last30DaysBreakdown)
                .filter(
                  ([key, value]) =>
                    key !== 'averagePulse' &&
                    key !== 'mostCommonSymptoms' &&
                    (value as any).totalPulse > 0
                )
                .map(([key]) => `${key}`)}
              datasets={[
                {
                  label: 'Mood over the last 30 days',
                  data: Object.entries(analytics.last30DaysBreakdown)
                    .filter(
                      ([key, value]) =>
                        key !== 'averagePulse' &&
                        key !== 'mostCommonSymptoms' &&
                        (value as any).totalPulse > 0
                    )
                    .map(([key, value]) => {
                      return (value as any).totalPulse;
                    }),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Mood',
                xTitle: 'Date',
              })}
              graphTitle="Mood over the last 30 days"
              graphType="line"
            />
            <Graph
              labels={Object.entries(analytics.cycleDayBreakdown).map(
                ([key]) => `${key}`
              )}
              datasets={[
                {
                  label: 'Avg Mood over a cycle',
                  data: Object.entries(analytics.cycleDayBreakdown)
                    .filter(([, value]) => !!value?.averagePulse)
                    .map(([key, value]) => {
                      return Number(value.averagePulse!.toFixed(1));
                    }),
                  backgroundColor: Object.entries(
                    analytics.cycleDayBreakdown
                  ).map(([key, value]) => {
                    if (!value.averagePulse) {
                      return 'black';
                    }
                    if (value.averagePulse < 2.5) {
                      return 'red';
                    }
                    if (value.averagePulse < 3) {
                      return 'yellow';
                    }
                    return 'green';
                  }),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Average Mood',
                yMax: 5,
                xMax: 28,
                xTitle: 'Cycle Day',
              })}
              graphTitle="Average Mood per Cycle Day (All Time)"
              graphType="line"
            />
            <Graph
              labels={Object.entries(analytics.monthlyBreakdown).map(
                ([key]) => `${key}`
              )}
              datasets={[
                {
                  label: 'Average Mood by Month',
                  data: Object.entries(analytics.monthlyBreakdown)
                    .filter(([, value]) => !!value?.averagePulse)
                    .map(([key, value]) => {
                      return Number(value.averagePulse!.toFixed(1));
                    }),
                  backgroundColor: Object.entries(
                    analytics.cycleDayBreakdown
                  ).map(([key, value]) => {
                    if (!value.averagePulse) {
                      return 'black';
                    }
                    if (value.averagePulse < 2.5) {
                      return 'red';
                    }
                    if (value.averagePulse < 3) {
                      return 'yellow';
                    }
                    return 'green';
                  }),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Average Mood',
                yMax: 5,
                xMax: 28,
                xTitle: 'Month',
              })}
              graphTitle="Average Mood by Month (All Time)"
              graphType="line"
            />
          </>
        ) : (
          <>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </>
        )}
      </div>
      <br />
      <hr className="line-divider" />
      <h2 className="text-centered">Symptoms Summary</h2>
      <div className="grid grid--2 grid--1-mobile grid--spacing-10">
        <Callout>
          <p>
            <strong>Most Common Symptoms (Last 7 Days)</strong>
          </p>
          {showAnalytics ? (
            <Pills>
              {Object.entries(
                analytics.last7DaysBreakdown.mostCommonSymptoms || {}
              ).map(([key, value]) => (
                <Pill key={key} number={value}>
                  {key}
                </Pill>
              ))}
            </Pills>
          ) : (
            <Skeleton height={20} />
          )}
        </Callout>
        <Callout>
          <p>
            <strong>Most Common Symptoms (Last 30 Days)</strong>
          </p>
          {showAnalytics ? (
            <Pills>
              {Object.entries(
                analytics.last30DaysBreakdown.mostCommonSymptoms || {}
              ).map(([key, value]) => (
                <Pill key={key} number={value}>
                  {key}
                </Pill>
              ))}
            </Pills>
          ) : (
            <Skeleton height={20} />
          )}
        </Callout>
        <Callout>
          <p>
            <strong>Most Common Symptoms (All Time)</strong>
          </p>
          {showAnalytics ? (
            <Pills>
              {analytics.overallBreakdown.symptomsComparison.map(
                ([key, value]) => (
                  <Pill key={key} number={value}>
                    {key}
                  </Pill>
                )
              )}
            </Pills>
          ) : (
            <Skeleton height={20} />
          )}
        </Callout>
        <Callout>
          <p>
            <strong>Most Common Symptom per Cycle Day (All Time)</strong>
          </p>
          {showAnalytics ? (
            <Pills>
              {Object.entries(analytics.cycleDayBreakdown)
                .map(([key, value]) => {
                  return Object.entries(value.mostCommonSymptoms)
                    .sort((a, b) => b[1] - a[1])
                    .slice(
                      0,
                      Math.min(
                        1,
                        Object.entries(
                          analytics.overallBreakdown.mostCommonSymptoms
                        ).length
                      )
                    )
                    .map((a) => (
                      <Pill key={key} number={a[1]}>
                        <strong>Day {key}:</strong>
                        {a[0]}
                      </Pill>
                    ));
                })
                .map((d) => d)}
            </Pills>
          ) : (
            <Skeleton height={20} />
          )}
        </Callout>
        {showAnalytics ? (
          <>
            <Graph
              labels={Object.entries(analytics.last7DaysBreakdown)
                .filter(
                  ([key]) =>
                    key !== 'averagePulse' && key !== 'mostCommonSymptoms'
                )
                .map(([key]) => `${key}`)}
              datasets={[
                {
                  label: 'Number of Symptoms over the last 7 days',
                  data: Object.entries(analytics.last7DaysBreakdown)
                    .filter(
                      ([key]) =>
                        key !== 'averagePulse' && key !== 'mostCommonSymptoms'
                    )
                    .map(([key, value]) => {
                      return (value as any).totalSymptoms;
                    }),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Total # Symptoms',
                xTitle: 'Date',
              })}
              graphTitle="# Symptoms over the last 7 days"
              graphType="line"
            />
            <Graph
              labels={Object.entries(analytics.last30DaysBreakdown)
                .filter(
                  ([key]) =>
                    key !== 'averagePulse' && key !== 'mostCommonSymptoms'
                )
                .map(([key]) => `${key}`)}
              datasets={[
                {
                  label: 'Number of Symptoms over the last 30 days',
                  data: Object.entries(analytics.last30DaysBreakdown)
                    .filter(
                      ([key]) =>
                        key !== 'averagePulse' && key !== 'mostCommonSymptoms'
                    )
                    .map(([key, value]) => {
                      return (value as any).totalSymptoms;
                    }),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Total # Symptoms',
                xTitle: 'Date',
              })}
              graphTitle="# Symptoms over the last 30 days"
              graphType="line"
            />
            <Graph
              labels={Object.entries(analytics.monthlyBreakdown).map(
                ([key]) => `${key}`
              )}
              datasets={[
                {
                  label: 'Total Symptoms by Month',
                  data: Object.entries(analytics.monthlyBreakdown).map(
                    ([key, value]) => {
                      return value.totalSymptoms;
                    }
                  ),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Total # Symptoms',
                xTitle: 'Month',
              })}
              graphTitle="Total # Symptoms by Month"
              graphType="line"
            />
            <Graph
              labels={Object.entries(analytics.cycleDayBreakdown).map(
                ([key]) => `${key}`
              )}
              datasets={[
                {
                  label: 'Average Symptoms over a cycle',
                  data: Object.entries(analytics.cycleDayBreakdown).map(
                    ([key, value]) => {
                      return value.averageNumberSymptoms;
                    }
                  ),
                },
              ]}
              options={generateChartOptions({
                yTitle: 'Avg # Symptoms',
                xTitle: 'Cycle Day',
                xMax: 28,
              })}
              graphTitle="Average Symptoms per Cycle Day"
              graphType="line"
            />
          </>
        ) : (
          <>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </>
        )}
      </div>
    </>
  );
};

const PatientAnalyticsPage: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const [analytics, setAnalytics] = useState({} as any as PatientAnalyticsInfo);
  const [session] = useSessionContext();
  const [showBetaBanner, setShowBetaBanner] = useState(true);
  const { page } = useAnalytics();

  const { patient } = usePatientSWR(patientId as string);
  const { data, isLoading, mutate } = usePatientTreatmentLogsSWR(
    patientId as string
  );

  useEffect(() => {
    page('Patient Analytics');
  }, []);

  useEffect(() => {
    setPageTitle(
      session.isPatientView ? 'Your Analytics' : 'Patient Analytics'
    );
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newAnalytics = calculateAnalytics(data);
    setAnalytics(newAnalytics);
  }, [isLoading]);

  return (
    <section className="patient-analytics--page">
      {!session.isPatientView && patient && (
        <div className="page-wrapper">
          <PatientQuickNav patient={patient!} />
        </div>
      )}
      <div className="page-wrapper is-flex-column">
        {showBetaBanner && session.isPatientView && (
          <Banner
            icon="fas fa-exclamation-circle"
            isSuccess
            isFixed={false}
            onClose={() => setShowBetaBanner(false)}
          >
            This is still in beta! Please let us know what you think by sending
            us an email{' '}
            <a
              className="external-link"
              rel="noreferrer"
              href="mailto:contact@artemiscalendar.com?subject=Artemis Calendar Analytics Feedback"
              target="_blank"
            >
              here
            </a>
            .
          </Banner>
        )}
        <TitleBlock title="Track How You Feel Over Time" />
        <section>
          <PatientGraphs
            analytics={analytics}
            patientId={patientId as string}
            isLoading={isLoading}
          />
        </section>
      </div>
    </section>
  );
};

export default PatientAnalyticsPage;
