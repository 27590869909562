import React, { useState, useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import classNames from 'classnames';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';

import styled from 'styled-components';
import {
  SelectOption,
  RegisterPatientData,
  CalendarSetupForm,
} from '../../../types/form.types';
import './register-form.scss';
import { getDoctors } from '../../../services/doctor.service';
import { Doctor } from '../../../models';
import { GENDER_OPTIONS, TREATMENT_OPTIONS, ZONE_OPTIONS } from '../../../util';
import CalendarSetup from '../calendar/calendarSetup';
import { convertSelectOptionsToValue, useQuery } from '../../../util/helpers';
import { countries, states } from '../../../assets';
import { onApiErrorToast } from '../../../services/toasts.service';
import { ApiGetDoctorsResponse } from '../../../types/apiResponse.types';
import ProspectiveDoctorForm from './prospectiveDoctorForm';
import {
  Datepicker,
  Dropdown,
  TextField,
  Button,
  Radio,
  ExternalLink,
} from '../../shared';

type PatientRegisterProps = {
  onSubmit: (formData: RegisterPatientData) => void;
  isLoading: boolean;
};

const PageNumber = styled.strong``;

// TODO: maybe move selects into a file

const countryOptions: SelectOption<string>[] = countries.map(
  (c: { country: string; code: string }) => ({
    value: c.country,
    label: c.country,
  })
);
const stateOptions: SelectOption<string>[] = states.map((state: string) => {
  return {
    value: state,
    label: state,
  };
});

// These are the values which are SelectOption values, which we should transform into strings before sending
const selectValuesArray = [
  'treatmentType',
  'state',
  'country',
  'doctorId',
  'prospectiveDoctorCountry',
  'prospectiveDoctorState',
  'gender',
  'timezone',
];

const prospectiveDoctorLabel = ">>> Can't Find Your Provider?";

// TODO: the error states for selects not working
const PatientRegister: React.FC<PatientRegisterProps> = ({
  onSubmit,
  isLoading,
}: PatientRegisterProps) => {
  const preFilledProvider = useQuery().get('token') || undefined;
  const methods = useForm<RegisterPatientData>({
    defaultValues: {
      treatmentPreference: 'selfManaged',
    },
  });
  const { handleSubmit, getValues, setValue, watch } = methods;
  const [doctorOptions, setDoctorOptions] = useState<SelectOption<string>[]>(
    []
  );
  const [onCalendarSetup, setCalendarSetup] = useState<boolean>(false);

  const guessedTimeZoneName = moment.tz.guess();
  const guessedTimezone = moment.tz.zone(guessedTimeZoneName);

  useEffect(() => {
    async function getDoctorsOptions(): Promise<void> {
      // TODO: double check
      getDoctors()
        .then((doctorsResponse: ApiGetDoctorsResponse) => {
          if (!doctorsResponse.doctors.length) {
            Sentry.captureMessage(
              'No doctors were retrieved for the list of patient potential providers'
            );
          }
          const doctors = doctorsResponse.doctors.map((doctor: Doctor) => {
            return {
              label: `Dr. ${doctor.user.getFullName()}`,
              value: doctor.getId(),
            };
          });
          const prospectiveDoctorIndex = doctors.findIndex(
            (d) => d.label === `Dr. Prospective Doctor`
          );
          const prospectiveDoctor = doctors[prospectiveDoctorIndex];
          if (prospectiveDoctor) {
            prospectiveDoctor.label = prospectiveDoctorLabel;
          }
          // Make sure its at the bottom
          doctors.splice(prospectiveDoctorIndex, 1);
          doctors.push(prospectiveDoctor);
          setDoctorOptions(doctors);
          // For email Invites, prepopulate the right provider
          if (preFilledProvider) {
            const matchingDoctor = doctors.find(
              (doctor) => doctor.value === preFilledProvider
            );
            if (matchingDoctor) {
              setValue('doctorId', matchingDoctor as any);
            } else {
              Sentry.captureMessage(
                `No matching doctor found from patient invite email with doctor ID ${preFilledProvider}`
              );
            }
          }
        })
        .catch((error) => {
          onApiErrorToast(error);
        });
    }

    getDoctorsOptions();
  }, []);
  const onCalendarSetupSubmit = (values: CalendarSetupForm) => {
    // TODO: We convert to any because typescript is giving stupid errors, should probably figure out how to fix this
    let combinedFormValues = Object.assign(values, getValues()) as any;
    let dynamicSelectValuesArray = selectValuesArray;
    // State wont be a select if the country isn't US
    if (
      (watch('country') as unknown as SelectOption<string>)?.value !==
      'United States'
    ) {
      dynamicSelectValuesArray = dynamicSelectValuesArray.filter(
        (value) => value !== 'state'
      );
    }
    if (
      (watch('prospectiveDoctorCountry') as unknown as SelectOption<string>)
        ?.value !== 'United States'
    ) {
      dynamicSelectValuesArray = dynamicSelectValuesArray.filter(
        (value) => value !== 'prospectiveDoctorState'
      );
    }
    combinedFormValues = convertSelectOptionsToValue(
      combinedFormValues,
      dynamicSelectValuesArray
    );
    // Period Margin is hidden for onboarding so manually put a value
    combinedFormValues.periodMargin = 0;

    // Set a useless doctorId to bypass required checks
    if (combinedFormValues.treatmentPreference === 'selfManaged') {
      combinedFormValues.doctorId = '-1';
      combinedFormValues.selfManagement = true;
    } else {
      combinedFormValues.selfManagement = false;
    }

    delete combinedFormValues.treatmentPreference;
    onSubmit(combinedFormValues);
  };

  const isSelfManaged =
    methods.watch('treatmentPreference') === 'selfManaged' || false;

  return (
    <>
      {onCalendarSetup && (
        <CalendarSetup
          onSubmit={onCalendarSetupSubmit}
          isSaving={isLoading}
          onRegistration
        >
          <PageNumber>Part 2 / 2</PageNumber>
        </CalendarSetup>
      )}

      <FormContext {...methods}>
        <form
          className={classNames('profile-info', {
            'is-hidden': onCalendarSetup,
          })}
          onSubmit={handleSubmit(() => setCalendarSetup(true))}
        >
          <h3>Complete your Personal Profile</h3>
          <hr />
          <div className="form-group">
            <Dropdown
              name="timezone"
              required
              options={ZONE_OPTIONS}
              validationOptions={{
                required: {
                  value: true,
                  message: 'required',
                },
              }}
              initialValue={
                ZONE_OPTIONS.find((option) =>
                  guessedTimezone?.abbrs.includes(option.value)
                )?.value
              }
            >
              your timezone
              <p className="description">
                {' '}
                (required for some parts of the calendar to work correctly)
              </p>
            </Dropdown>
          </div>

          <br />
          <h3>Select the role of your Provider in your treatment</h3>
          <hr />
          <div className="form-group">
            <Dropdown
              name="treatmentType"
              required
              options={TREATMENT_OPTIONS}
              validationOptions={{
                required: {
                  value: true,
                  message: 'required',
                },
              }}
              initialValue={TREATMENT_OPTIONS[0].value}
            >
              Treatment Type
            </Dropdown>
          </div>
          <h4>Select how your treatment will be managed</h4>

          <fieldset>
            <Radio
              name="treatmentPreference"
              value="selfManaged"
              reverse
              isChecked={isSelfManaged}
            >
              <span>
                <strong>Manage My Own Dosing</strong> (I am comfortable
                adjusting dosing. No Provider access to my calendar)
              </span>
            </Radio>
            <br />
            <Radio
              name="treatmentPreference"
              value="providerManaged"
              reverse
              isChecked={!isSelfManaged}
            >
              <span>
                <strong>Provider Managed Dosing</strong> (Recommended for those
                new to bHRT, or invited by Provider)
              </span>
            </Radio>
          </fieldset>
          <ExternalLink href="https://www.artemiscalendar.com/manage-treatment">
            Not sure?
          </ExternalLink>
          <br />
          {!isSelfManaged && (
            <>
              <div className="form-group">
                <Dropdown
                  name="doctorId"
                  required={!isSelfManaged}
                  placeholder="Start Typing to Search by Name"
                  options={doctorOptions}
                  validationOptions={{
                    required: {
                      value: true,
                      message: 'required',
                    },
                  }}
                  initialValue={preFilledProvider}
                  menuPlacement="top"
                  disabled={isSelfManaged}
                >
                  who is your provider?
                </Dropdown>
              </div>
              {!isSelfManaged && (
                <>
                  <p>
                    The information is used to verify your identity by your
                    provider.
                  </p>
                  <div className="form-group half-width">
                    <Dropdown
                      name="gender"
                      required={!isSelfManaged}
                      options={GENDER_OPTIONS}
                      validationOptions={
                        isSelfManaged
                          ? {}
                          : {
                              required: {
                                value: true,
                                message: 'required',
                              },
                            }
                      }
                    >
                      Gender
                    </Dropdown>
                  </div>
                  <div className="form-group half-width">
                    <Datepicker
                      minDate={new Date('01/01/1930')}
                      maxDate={moment().subtract(10, 'years').toDate()}
                      name="dateOfBirth"
                      required={!isSelfManaged}
                      placeholder="Click to select"
                      label="Date of Birth"
                      validationOptions={
                        isSelfManaged
                          ? {}
                          : {
                              required: {
                                value: true,
                                message: 'required',
                              },
                            }
                      }
                    />
                  </div>

                  <div className="form-group half-width">
                    <Dropdown
                      name="country"
                      options={countryOptions}
                      required
                      validationOptions={{
                        required: 'Required',
                      }}
                      initialValue={
                        countryOptions.find((c) => c.value === 'United States')
                          ?.value
                      }
                    >
                      Country
                    </Dropdown>
                  </div>

                  <div className="form-group half-width">
                    {(watch('country') as unknown as SelectOption<string>)
                      ?.value === 'United States' && (
                      <Dropdown
                        name="state"
                        options={stateOptions}
                        required
                        validationOptions={{
                          required: {
                            value: true,
                            message: 'required',
                          },
                        }}
                      >
                        State
                      </Dropdown>
                    )}
                    {(watch('country') as unknown as SelectOption<string>)
                      ?.value !== 'United States' && (
                      <TextField
                        required
                        name="state"
                        placeholder="state/province"
                        validationOptions={{
                          required: 'Required',
                        }}
                      >
                        State/Province
                      </TextField>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {(watch('doctorId') as any)?.label === prospectiveDoctorLabel &&
            !isSelfManaged && (
              <>
                <span>OR</span>
                <FormContext {...methods}>
                  <br />
                  <ProspectiveDoctorForm />
                </FormContext>
              </>
            )}
          <hr />
          <div className="button-container">
            <PageNumber>Part 1 / 2</PageNumber>

            <Button type="submit" isLoading={isLoading}>
              Next
            </Button>
          </div>
        </form>
      </FormContext>
    </>
  );
};

export default PatientRegister;
