import React from 'react';
import './logoBlock.scss';

export const LogoBlock: React.FC = () => (
  <div className="logo-block">
    <a href="/">
      <img src="/images/logo.png" className="logo" alt="Logo" />
      <span className="text-logo">ARTEMIS CALENDAR</span>
    </a>
  </div>
);
