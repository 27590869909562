/* eslint-disable jsx-a11y/control-has-associated-label */
import moment from 'moment';
import React, { useState } from 'react';
import { InvitedPatient, InviteStatus } from '../../../models';
import { Button } from '../../shared';
import {
  onApiErrorToast,
  onSuccessToast,
  invitePatientByEmail,
} from '../../../services';

type InvitedPatientTableItemProps = {
  invitedPatient: InvitedPatient;
};

export const InvitedPatientTableItem: React.FC<
  InvitedPatientTableItemProps
> = ({ invitedPatient }) => {
  const [isLoading, setIsLoading] = useState(false);
  const canResendInvite =
    moment().diff(moment(invitedPatient.lastInviteSentDate), 'hours') > 24;
  const actions = (
    <span
      className={canResendInvite ? '' : 'has-tooltip'}
      data-tooltip={
        canResendInvite
          ? undefined
          : 'You have already sent an invite in the last 24 hours. Please try again later.'
      }
    >
      <Button
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);

          invitePatientByEmail(
            invitedPatient.referringDoctorId,
            invitedPatient.email
          )
            .then(() => {
              onSuccessToast('Email Invite Sent!');
              // eslint-disable-next-line no-param-reassign
              invitedPatient.lastInviteSentDate = new Date();
            })
            .catch((error) => onApiErrorToast(error))
            .finally(() => setIsLoading(false));
        }}
        isDisabled={!canResendInvite}
      >
        <i className="far fa-envelope" />
        <span className="icon-text">Resend Invite</span>
      </Button>
    </span>
  );

  return (
    <>
      <tr className="invited-patient-table-item" key={invitedPatient.getId()}>
        <td className="email">{invitedPatient.email}</td>
        <td className="invite-date">
          {moment(invitedPatient.lastInviteSentDate).fromNow()}
        </td>
        <td className="invite-status">{invitedPatient.inviteStatus}</td>
        <td className="actions desktop-only">
          {invitedPatient.inviteStatus === InviteStatus.PENDING && actions}
        </td>
      </tr>
      <tr className="invited-patient-table-item mobile-only">
        <td colSpan={4}>
          <span className="actions">{actions}</span>
        </td>
      </tr>
    </>
  );
};
