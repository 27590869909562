import React, { useEffect } from 'react';
import './notFound.page.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { maintenanceLogo } from '../../../assets';
import { setPageTitle } from '../../shared/header/header';
import { useSessionContext } from '../../shared/global/sessionContext';
import { LogoBlock, Button } from '../../shared';

const StyledSection = styled.section`
  display: flex;
`;

const NotFoundPage: React.FC = () => {
  const [sessionInfo] = useSessionContext();
  useEffect(() => {
    setPageTitle('Not Found');
  });

  return (
    <StyledSection className="not-found">
      <div className="page-wrapper has-box-shadow">
        <div className="text text-left">
          <LogoBlock />
          <h3>
            {sessionInfo.isAuthenticated
              ? "It looks like the page you're looking for can't be found."
              : 'You have been signed out of your session for regular security purposes.'}
          </h3>
          <p className="description">
            {sessionInfo.isAuthenticated ? '' : 'Sign back in now.'}
          </p>
          <p className="description">Contact us if this was a mistake.</p>
          <Link to="/">
            <Button>
              {sessionInfo.isAuthenticated ? 'Back to Home' : 'Log In'}
            </Button>
          </Link>
        </div>
        <img src={maintenanceLogo} alt="Maintenance" />
      </div>
    </StyledSection>
  );
};
export default NotFoundPage;
