import React, { useEffect, useState } from 'react';
import './settings.page.css';
import { useParams } from 'react-router-dom';
import AccountSettings from '../../features/settings/accountSettings';
import { useSessionContext } from '../../shared/global/sessionContext';
import BillingSettings from '../../features/settings/billingSettings';
import SupportSettings from '../../features/settings/supportSettings';
import AdminSettings from '../../features/settings/adminSettings';
import { getUser, onApiErrorToast } from '../../../services';
import { setPageTitle } from '../../shared/header/header';
import { User } from '../../../models';
import { Tabs } from '../../shared';

type SettingsPageProps = {
  isBilling?: boolean;
};

function SettingsPage({ isBilling }: SettingsPageProps) {
  const [activeTabView, setActiveTabView] = useState('account');
  const [session] = useSessionContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(new User());
  const [sessionContext] = useSessionContext();

  const userIdToUse =
    useParams<{ userId: string }>().userId || sessionContext.user.getId();
  const isSameAsSessionAccount = userIdToUse === sessionContext.user.getId();

  // a Cheat, but sometimes we need to forcibly tell react to rerender some components (checkboxes) after a user setting changes
  const onUpdateUser = (newUser: User) => {
    setUser(newUser);
  };

  useEffect(() => {
    if (isBilling && session.isPatientView) {
      setActiveTabView('billing');
    }
  }, []);

  useEffect(() => {
    getUser(userIdToUse)
      .then((userProfile) => {
        setUser(userProfile);
        if (!isSameAsSessionAccount) {
          setPageTitle(`${userProfile.getFullName()}'s Settings`);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  }, [userIdToUse]);

  const tabs = [
    {
      label: 'Account',
      id: 'account',
      href: '/settings',
    },
    {
      label: 'Help & Support',
      id: 'support',
    },
  ];
  if (session.isPatientView && !user.isDummyUser) {
    tabs.push({
      label: 'Billing',
      id: 'billing',
      href: '/billing',
    });
  }
  if (session.isAdmin) {
    tabs.push({
      label: 'Admin Features',
      id: 'admin',
    });
  }

  if (!isLoaded) {
    return null;
  }
  return (
    <section className="settings-page">
      <div className="page-wrapper">
        <Tabs
          tabs={tabs}
          onTabChange={(tab) => {
            setActiveTabView(tab.id);
          }}
          activeTabId={activeTabView}
        />
        {activeTabView === 'account' && (
          <AccountSettings
            user={user}
            isSameAsSessionAccount={isSameAsSessionAccount}
            onUpdateUser={onUpdateUser}
          />
        )}
        {activeTabView === 'billing' && <BillingSettings user={user} />}
        {activeTabView === 'support' && <SupportSettings user={user} />}
        {activeTabView === 'admin' && (
          <AdminSettings user={user} onUpdateUser={onUpdateUser} />
        )}
      </div>
    </section>
  );
}

export default SettingsPage;
