import React from 'react';
import { Checkbox, CheckboxProps } from '../checkbox/checkbox';
import './checkboxList.scss';

export type CheckboxListProps = {
  checkboxes: Pick<
    CheckboxProps,
    'label' | 'inputName' | 'onChange' | 'isChecked' | 'reversed'
  >[];
};

export const CheckboxList: React.FC<CheckboxListProps> = ({
  checkboxes = [],
}) => (
  <div className="checkbox-list">
    {!checkboxes.length && 'Nothing to Show'}
    {checkboxes.map(({ reversed, label, inputName, onChange, isChecked }) => (
      <Checkbox
        key={inputName}
        reversed={reversed}
        label={label}
        inputName={inputName}
        onChange={onChange}
        isChecked={isChecked}
      />
    ))}
  </div>
);
