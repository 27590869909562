import React from 'react';
import './App.scss';

import axios from 'axios';
import classNames from 'classnames';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Pusher from 'pusher-js';

import {
  parseJwtToken,
  getInMemorySessionToken,
  setInMemorySessionToken,
} from './shared/global/sessionContext';
import {
  refreshToken,
  API_ACCESS_TOKEN_RESPONSE,
} from '../services/authentication.service';
import { BASE_URL, PAGES_NOT_REQUIRING_AUTHENTICATION } from '../util';
import { ElixirSession } from '../models';
import { withSessionProp, withSession } from './shared/global/withSession';
import { onErrorToast, onInfoToast } from '../services/toasts.service';
import { AppRoutes } from '../routes';
import { withAppContext, withAppContextProps } from './shared/global';
import InactiveAccountBanner from './features/billing/inactiveAccountBanner';
import { User } from '../models/user.model';
import {
  DocumentTitle,
  ToastList,
  Loader,
  ErrorBoundary,
  Header,
  Announcements,
} from './shared';
import { GoogleAnalytics } from './vendor/googleAnalytics';
import {
  withNavigationProp,
  withNavigation,
} from './shared/global/withNavigation';
import { Icons } from './shared/icons/icons';
import { SegmentAnalytics } from './vendor/segmentAnalytics';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

let isFullStoryInitialized = false;

export function initFullStory(user: User): void {
  try {
    if (isFullStoryInitialized || user.isAdmin() || !user.allowFullStory)
      return;

    FullStory.init({
      orgId: process.env.REACT_APP_FULLSTORY_ORG_ID!,
      devMode: process.env.REACT_APP_ENV !== 'prod',
    });
    isFullStoryInitialized = true;
  } catch (error) {
    // Fullstory will fail on tests because there's no browser
  }
}

export function shutdownFullStory(): void {
  try {
    if (!isFullStoryInitialized) return;
    FullStory.shutdown();
    isFullStoryInitialized = false;
  } catch (error) {
    // Fullstory will fail on tests because there's no browser
  }
}
class App extends React.Component<
  withSessionProp & withNavigationProp & withAppContextProps,
  { doneInitialLoad: boolean }
> {
  constructor(
    props: withSessionProp & withNavigationProp & withAppContextProps
  ) {
    super(props);
    this.state = {
      doneInitialLoad: false,
    };
  }

  componentDidMount(): void {
    this.generateAccessTokenOnLoad();
    this.setupAxiosInterceptors();
    this.setupPusherConnection();

    if (!BASE_URL) {
      Sentry.captureMessage('Deploy Unsuccessful. BASE_URL is not set.');
    }
  }

  /**
   * Setups request intercepts for authentication
   * as well as response handling to globally handle errors
   */
  setupAxiosInterceptors(): void {
    axios.interceptors.request.use((request) => {
      const tokenData = getInMemorySessionToken();
      if (tokenData.token) {
        // We manually add the authorization header to deal with cookie blocking/general fallback
        if (tokenData.expiresAt! > new Date()) {
          request.headers!.Authorization = `Bearer ${tokenData.token}`;
        } else {
          delete request.headers?.Authorization;
        }
      } else {
        delete request.headers?.Authorization;
      }
      return request;
    });
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response) {
          const { status } = error.response;
          if (status === 401) {
            const {
              session: [, updateSessionContext],
              globalAppContext: { resetAppState },
            } = this.props;
            setInMemorySessionToken(null, 0);

            // TODO: maybe not the best way
            // Specifically if they hit /login
            if (
              error.response.data.message === 'Email or password is incorrect'
            ) {
              return Promise.reject(error);
            }

            const session = new ElixirSession({
              isAuthenticated: false,
            });
            resetAppState();
            updateSessionContext(session);
            onErrorToast('Your session has expired. Please sign back in.');
          } else if (status === 403) {
            Sentry.captureException('Invalid Access Permissions');
            onErrorToast('You have insufficient permissions');
          } else if (status === 500) {
            // TODO: user context here
            Sentry.captureException(error);
            onErrorToast(
              "Sorry, something went wrong on our end. We're looking into it!"
            );
          } else {
            // TODO: figure out how to deal with other error types globally
            // eslint-disable-next-line no-console
            console.error(error);
          }
        } else if (error.request) {
          Sentry.captureMessage(
            `Unable to connect to servers. ${error.message}`
          );
          // Network Error
          onErrorToast("Sorry, we can't connect to our servers right now.");
        } else {
          Sentry.captureMessage('somehow here');
          // Something happened in setting up the request that triggered an Error
          // eslint-disable-next-line no-console
          Sentry.captureException(error);
          console.error('Error', error.message);
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * Uses the refresh token to get the server to regenerate a new access token if the refresh token is valid
   */
  async generateAccessTokenOnLoad(): Promise<void> {
    const {
      navigate,
      location,
      session: [sessionInfo, updateSessionContext],
    } = this.props;
    refreshToken()
      .then((updatedToken) => {
        this.onRefreshTokenSuccess(updatedToken);
      })
      .catch((_error) => {
        if (sessionInfo.isAuthenticated) {
          navigate('/unauthenticated');
        }
        // Need this so we can redirect properly on login
        const session = new ElixirSession({
          isAuthenticated: false,
          redirectPathOnAuthentication: location.pathname,
        });
        updateSessionContext(session);
        this.setState({ doneInitialLoad: true });
      });
  }

  // eslint-disable-next-line class-methods-use-this
  setupPusherConnection() {
    // Use a pusher/subscriber as a way to have the client get automatic updates when we do successful CI builds
    // that would require them to update their page to get the updates
    // TODO: 2021-05-10 this is using Pusher right now, but may need to move away as number of connections increases due to sandbox plan
    try {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
      });
      const channel = pusher.subscribe(
        process.env.REACT_APP_PUSHER_BUILDS_UPDATE_CHANNEL!
      );
      channel.bind(
        process.env.REACT_APP_PUSHER_BUILDS_UPDATE_EVENT_NAME!,
        (_data: { version: string }) => {
          onInfoToast(
            "Please save any changes you're making, and then refresh the page to get the latest version, or click here to reload now.",
            {
              title: 'A new version of Artemis Calendar is out!',
              autoRemove: false,
              // eslint-disable-next-line no-restricted-globals
              onClick: () => location.reload(),
            }
          );
        }
      );
    } catch (error) {
      // We dont want to crash the app if pusher fails, since its a NICE to have
    }
  }

  onRefreshTokenSuccess(updatedToken: API_ACCESS_TOKEN_RESPONSE) {
    const {
      session: [, updateSessionContext],
      location,
      navigate,
      globalAppContext: { updateAppState },
    } = this.props;
    // Forces the redirect if they try to manually go to the login/register page and reload
    const redirectPath =
      location.pathname &&
      [...PAGES_NOT_REQUIRING_AUTHENTICATION, '/'].indexOf(
        location.pathname
      ) === -1
        ? location.pathname
        : '/home';

    const session = new ElixirSession({
      isAuthenticated: true,
      sessionInfo: parseJwtToken(updatedToken.token),
      redirectPathOnAuthentication: redirectPath,
    });

    // In case a user reloaded the page before they finished their registration properly, we force them back to finish their registration
    if (!session.user.hasCompletedRegistration) {
      session.isAuthenticated = false;
      navigate('/register');
    }
    if (session.user.billing) {
      updateAppState({
        isFrozenAccount: session.user.billing.isFrozen(),
        isInDunning: session.user.billing.isInDunning(),
        isCancelled: session.user.billing.isCancelled,
      });
    }
    // initFullStory(session.user);

    this.setState({ doneInitialLoad: true });
    updateSessionContext(session);
  }

  render(): React.ReactElement {
    const {
      session: [sessionInfo],
      globalAppContext: { appState },
    } = this.props;
    const { doneInitialLoad } = this.state;
    const showHeader = sessionInfo.isAuthenticated;

    return (
      <main
        className={classNames('app-wrapper', {
          authenticated: sessionInfo.isAuthenticated,
          frozen: appState.isFrozenAccount,
          dunning: appState.isInDunning,
          cancelled: appState.isCancelled,
        })}
      >
        <Icons />
        {process.env.REACT_APP_ENV === 'prod' && <GoogleAnalytics />}
        <SegmentAnalytics />
        <ErrorBoundary>
          {/* A little cheat to avoid the double render that would happen while we waited for verify to return in case authenticated */}
          {doneInitialLoad ? (
            <>
              <DocumentTitle />
              {showHeader && <Header />}
              <Elements stripe={stripePromise}>
                <AppRoutes />
              </Elements>
              <ToastList />
              <Announcements />
            </>
          ) : (
            <div className="app-loader">
              <Loader />
            </div>
          )}
          <InactiveAccountBanner />
        </ErrorBoundary>
      </main>
    );
  }
}
export default withNavigation(withSession(withAppContext(App)));
