/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment-timezone';
import './calendar.scss';
import jsPDF, { jsPDFOptions } from 'jspdf';
import html2canvas from 'html2canvas';
import { TreatmentLog, Patient } from '../../../models';
import { withSession, withSessionProp } from '../../shared/global';
import CalendarHeader from './calendarHeader';
import CalendarFooter from './calendarFooter';
import CalendarDayBlock from './calendarDayBlock';
import CalendarDayListItem from './calendarDayListItem';
import { onInfoToast } from '../../../services';
import { onSuccessToast } from '../../../services/toasts.service';
import { Button, Loader } from '../../shared';
import {
  withAppContext,
  withAppContextProps,
} from '../../shared/global/withAppContext';
import { setCookie, getCookie } from '../../../lib/cookies';
import { COOKIE_LAST_CALENDAR_VIEW_MODE } from '../../../util';

type CalendarProps = {
  currentDisplayMonth: moment.Moment;
  treatmentLogs: TreatmentLog[];
  patient: Patient;
  nextMonth: () => void;
  prevMonth: () => void;
  onClick: (log: TreatmentLog) => void;
};

// eslint-disable-next-line no-shadow
export enum CalendarViewMode {
  LIST = 'list',
  CALENDAR = 'calendar',
}

class Calendar extends React.Component<
  CalendarProps & withSessionProp & withAppContextProps,
  { viewMode: CalendarViewMode; exportingToPDF: boolean }
> {
  private calendarRef: React.RefObject<HTMLDivElement>;

  constructor(props: CalendarProps & withSessionProp & withAppContextProps) {
    super(props);
    const { globalAppContext } = props;
    this.state = {
      viewMode:
        globalAppContext.appState.lastCalendarPageViewMode ||
        (getCookie(COOKIE_LAST_CALENDAR_VIEW_MODE) as CalendarViewMode) ||
        CalendarViewMode.CALENDAR,
      exportingToPDF: false,
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount(): void {
    this.scrollToToday();
  }

  componentDidUpdate() {
    this.scrollToToday();
  }

  scrollToToday() {
    const { viewMode } = this.state;
    if (viewMode === CalendarViewMode.LIST) {
      const element = document.querySelector('.calendar-list-item.today');
      if (element?.previousElementSibling) {
        element.previousElementSibling!.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }

  /**
   * Converts each of the treatments logs for a month into a calendar block (Calendar View Mode)
   */
  renderLogsAsCalendarDayBlocks(): React.ReactElement[] {
    const { currentDisplayMonth, treatmentLogs, onClick } = this.props;

    return treatmentLogs.map((log, index) => {
      const i = index + 1;
      return (
        <CalendarDayBlock
          treatmentLog={log}
          currentDisplayMonth={currentDisplayMonth}
          index={i}
          dayNumber={i}
          onClick={onClick}
          key={`cal-${i}`}
        />
      );
    }) as React.ReactElement[];
  }

  getContentToDisplay(): React.ReactElement {
    const { viewMode } = this.state;
    const { currentDisplayMonth } = this.props;
    let days: React.ReactElement[] = [];
    const emptyDaysUntilStartOfMonth = [];
    const emptyDaysAfterEndOfMonth = [];
    // Returns the day of the week the first day is
    const firstDayOfMonth: number = +currentDisplayMonth
      .clone()
      .startOf('month')
      .format('d');
    // Returns the day of the week the last day is
    const lastDayOfMonth = +currentDisplayMonth
      .clone()
      .endOf('month')
      .format('d');

    if (viewMode === CalendarViewMode.CALENDAR) {
      days = days.concat(this.renderLogsAsCalendarDayBlocks());

      for (let i = 0; i < firstDayOfMonth; i += 1) {
        emptyDaysUntilStartOfMonth.push(
          <div className="day calendar-block empty" key={i} />
        );
      }
      if (lastDayOfMonth !== 6) {
        for (let i = 0; i < 6 - lastDayOfMonth; i += 1) {
          emptyDaysAfterEndOfMonth.push(
            <div className="day calendar-block empty" key={i} />
          );
        }
      }

      return (
        <div className="days">
          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((day) => (
            <div key={day} className="static calendar-block header">
              {day}
            </div>
          ))}
          {emptyDaysUntilStartOfMonth}
          {days}
          {emptyDaysAfterEndOfMonth}
        </div>
      );
    }
    const { treatmentLogs, onClick } = this.props;

    const listItems = treatmentLogs.map((log, index) => {
      const i = index + 1;
      return (
        <CalendarDayListItem
          treatmentLog={log}
          currentDisplayMonth={currentDisplayMonth}
          dayNumber={i}
          onClick={onClick}
          key={`cal-${i}`}
        />
      );
    }) as React.ReactElement[];
    return <section className="calendar-list-items">{listItems}</section>;
  }

  showFrozenOrCancelledMessage() {
    const {
      session: [sessionInfo],
      patient: { user },
    } = this.props;

    let title = '';
    if (sessionInfo.isPatientView) {
      if (user.billing?.isCancelled) {
        title =
          'Your calendar is being limited due to your account being cancelled.';
      } else {
        title =
          'Your calendar is being limited due to your account being frozen.';
      }
    }
    return <p className="warning-text text-centered description">{title}</p>;
  }

  exportToPDF = () => {
    // TODO: convert most of this into a generic function
    onInfoToast('Exporting to PDF. Please wait a moment...');
    this.setState(
      {
        exportingToPDF: true,
      },
      async () => {
        const { currentDisplayMonth } = this.props;
        const canvas = await html2canvas(this.calendarRef.current!);
        const imageData = canvas.toDataURL('image/png');
        const margins = 100;
        const pdfOptions: jsPDFOptions = {
          orientation: 'landscape',
          unit: 'pt',
          format: [canvas.width + margins, canvas.height + margins],
        };

        if (canvas.height > canvas.width) {
          pdfOptions.orientation = 'portrait';
          // pdfOptions.format = [canvas.width * 2 + margins, canvas.height + margins];
        }
        // eslint-disable-next-line new-cap
        const doc = new jsPDF(pdfOptions);
        doc.addImage(
          imageData,
          'PNG',
          margins / 2,
          margins / 2,
          canvas.width,
          canvas.height
        );
        const name = `${currentDisplayMonth.format('MMMM')} Calendar.pdf`;
        doc.save(name);
        onSuccessToast(
          `Calendar for ${currentDisplayMonth.format('MMMM')} exported!`
        );
        this.setState({
          exportingToPDF: false,
        });
      }
    );
  };

  render() {
    const {
      currentDisplayMonth,
      prevMonth,
      nextMonth,
      treatmentLogs,
      patient: { calendarType, user, treatmentSchedules },
    } = this.props;
    const { viewMode, exportingToPDF } = this.state;

    const displayContent = this.getContentToDisplay();

    if (!treatmentLogs.length) {
      return <Loader className="calendar-loader" />;
    }

    return (
      <div className="calendar-container" ref={this.calendarRef}>
        {!exportingToPDF && (
          <>
            <CalendarHeader
              onClickNextMonth={nextMonth}
              onClickPrevMonth={prevMonth}
              currentDisplayMonth={currentDisplayMonth}
              onClickSetViewMode={(newViewMode: CalendarViewMode) => {
                this.setState({ viewMode: newViewMode });
                // eslint-disable-next-line react/destructuring-assignment
                const { updateAppState } = this.props.globalAppContext;
                setCookie(COOKIE_LAST_CALENDAR_VIEW_MODE, newViewMode, 1);
                updateAppState({
                  lastCalendarPageViewMode: newViewMode,
                });
              }}
              viewMode={viewMode}
            />
            {(user.billing?.isFrozen() || user.billing?.isCancelled) &&
              this.showFrozenOrCancelledMessage()}
          </>
        )}
        {exportingToPDF && (
          <>
            <h3>
              {currentDisplayMonth.format('MMMM YYYY')}, {calendarType} Calendar
            </h3>
            <p>Last Updated: {moment().format('MMMM DD, YYYY')} </p>
          </>
        )}
        {displayContent}
        {viewMode === CalendarViewMode.CALENDAR && (
          <CalendarFooter
            treatmentSchedules={treatmentSchedules}
            calendarType={calendarType}
            treatmentLogs={treatmentLogs}
          />
        )}
        <br />
        <Button onClick={this.exportToPDF} isDisabled={exportingToPDF}>
          <i className="fas fa-print" /> Save Calendar as PDF
        </Button>
      </div>
    );
  }
}

export default withSession(withAppContext(Calendar));
