import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import './checkbox.scss';

export type CheckboxProps = {
  inputName: string;
  isChecked: boolean;
  onChange: (e: any, inputName: string) => void;
  isRequired?: boolean;
  label?: string;
  reversed?: boolean;
  children?: any;
  inline?: boolean;
  className?: string;
};

// TODO: need to pass in a control
export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  inputName,
  onChange,
  isChecked = false,
  reversed = false,
  children,
  isRequired,
  inline = false,
  className = '',
}) => {
  const methods = useFormContext();
  return (
    <label className={classNames('checkbox', { reversed, inline }, className)}>
      {children || <span className="text">{label}</span>}
      <input
        type="checkbox"
        name={inputName}
        onChange={(e) => {
          onChange(e, inputName);
        }}
        checked={isChecked}
        ref={methods?.register}
        required={isRequired}
      />
      <span className="error">
        {methods?.errors &&
          methods?.errors![inputName] &&
          methods?.errors![inputName].message}
      </span>
    </label>
  );
};
