import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Dropdown, Loader } from '../../shared';
import { SelectOption } from '../../../types';
import { StaticSchedule } from '../../../models';

import './dosageSelection.scss';
import { CalendarSetupForm } from '../../../types/form.types';
import { FrequencyUnit } from '../../../types/enums';
import { Patient } from '../../../models/patient.model';
import { TreatmentSchedule } from '../../../models/treatmentSchedule.model';
import { capitalize } from '../../../util/helpers';

type DosageSelectionProps = {
  staticSchedules: StaticSchedule[];
  substance: string;
  required?: boolean;
  onRegistration?: boolean;
  activeTreatmentSchedule?: TreatmentSchedule;
};

function ChosenDosageDetails({ name }: { name: string }) {
  const { watch } = useFormContext<CalendarSetupForm>();
  const schedule = watch(name) as unknown as SelectOption<number>;
  const scheduleDetails = schedule?.args;
  if (!scheduleDetails) {
    return null;
  }

  return (
    <div className="dosage-details">
      {scheduleDetails.frequency > 0 && (
        <div className="dosage-schedule">
          {scheduleDetails.dosageSchedule.join(', ')}
        </div>
      )}
      <div className="dosage-number">
        <strong>Default Settings:</strong> {scheduleDetails.frequency}{' '}
        {scheduleDetails.dosageUnit}/{scheduleDetails.frequencyUnit}
      </div>
    </div>
  );
}

const Description = styled.p`
  margin: 0;
`;

const DosageSelection: React.FC<DosageSelectionProps> = ({
  substance,
  staticSchedules,
  required,
  onRegistration,
  activeTreatmentSchedule,
}) => {
  const { watch, setValue } = useFormContext<CalendarSetupForm>();
  const [options, setOptions] = useState(
    {} as Record<string, SelectOption<number>[]>
  );
  const name = `${substance}`;
  const multiDosingInputName = `enableMultiDosing-${name}`;
  const enabledMultiDosing: boolean = watch(multiDosingInputName) || false;

  if (!staticSchedules.length) {
    return null;
  }

  useEffect(() => {
    if (activeTreatmentSchedule?.frequencyUnit !== FrequencyUnit.day) {
      setValue(multiDosingInputName, true);
    }
  }, [activeTreatmentSchedule]);

  // Sets the correct dropdown options depending if theyve enabled multi dosages
  useEffect(() => {
    const mappedOptions: any[] = enabledMultiDosing
      ? staticSchedules
        .filter(
          (s) =>
            s.frequencyUnit === FrequencyUnit.am ||
            s.frequencyUnit === FrequencyUnit.pm
        )
        .map((s) => ({
          value: Number(s.getId()),
          label: s.name,
          args: s,
        }))
      : staticSchedules
        .filter((s) => s.frequencyUnit === FrequencyUnit.day)
        .map((s) => ({
          value: Number(s.getId()),
          label: s.name,
          args: s,
        }));
    const frequencyUnitOptions: Record<string, any> = {};
    mappedOptions.forEach((option) => {
      const { frequencyUnit } = option.args;
      if (frequencyUnitOptions[frequencyUnit]) {
        frequencyUnitOptions[frequencyUnit] = [
          ...frequencyUnitOptions[frequencyUnit],
          option,
        ];
      } else {
        frequencyUnitOptions[frequencyUnit] = [option];
      }
    });

    setOptions(frequencyUnitOptions);
  }, [staticSchedules, enabledMultiDosing]);

  // Make sure they select a new value when switching frequency units
  const specificRequired =
    (activeTreatmentSchedule?.frequencyUnit === FrequencyUnit.day &&
      enabledMultiDosing) ||
    (activeTreatmentSchedule?.frequencyUnit !== FrequencyUnit.day &&
      !enabledMultiDosing);
  return (
    <div className="dosage-selection">
      <strong>{capitalize(substance)} dosages </strong>
      <em>{!required ? '(Optional)' : ''}</em>

      <br />
      <br />

      <div className="form-group ">
        {Object.entries(options)
          .sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          })
          .map(([key, optionsForFrequency]) => (
            <React.Fragment key={`${name}-${key}`}>
              <Dropdown
                name={`${name}-${key}`}
                options={optionsForFrequency}
                required={required || specificRequired}
                placeholder={
                  onRegistration || specificRequired
                    ? 'Select a Template'
                    : 'Use Existing Dosages'
                }
                validationOptions={
                  required
                    ? {
                      required: 'Required',
                    }
                    : {}
                }
              >
                {onRegistration ? 'Daily Dosage' : key}
              </Dropdown>
              <ChosenDosageDetails name={`${name}-${key}`} />
            </React.Fragment>
          ))}
      </div>
      {!onRegistration && (
        <>
          <Checkbox
            isChecked={enabledMultiDosing}
            inputName={multiDosingInputName}
            label="Use different dosing between AM and PM"
            reversed
            inline
            onChange={(e) => setValue(multiDosingInputName, e.target.checked)}
          />
          {specificRequired && (
            <p className="description">
              Note: Changing when the dosages should be taken requires selecting
              a new template to start from.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export function DosageSelectionsList({
  onRegistration,
  patient,
  staticSchedulesMap,
}: { patient: Patient; onRegistration: boolean } & {
  staticSchedulesMap: Record<string, StaticSchedule[]>;
}) {
  // Sorted so that estrogen is first
  const sortedSchedules = Object.entries(staticSchedulesMap).sort(
    ([a], [b]) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    }
  );
  return (
    <>
      <h3>Setup your Dosage</h3>
      <hr />
      {onRegistration && (
        <Description>
          If you are unsure, use ones labeled Standard.{' '}
          {onRegistration && 'This can be changed later.'}
        </Description>
      )}
      {patient?.getId() && (
        <p className="description">
          Warning! Changing any of the dosage schedules will reset any future
          dosage changes previously saved.
        </p>
      )}
      {sortedSchedules.map(([key, val]) => {
        const activeTreatmentSchedule = patient?.treatmentSchedules?.find(
          (s) => s.substance === key
        );
        return (
          <DosageSelection
            substance={key}
            key={key}
            staticSchedules={val}
            required={onRegistration}
            onRegistration={onRegistration}
            activeTreatmentSchedule={activeTreatmentSchedule}
          />
        );
      })}
      {sortedSchedules.length === 0 && <Loader />}
      <hr />
    </>
  );
}
