/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  ApiGetDoctorsResponse,
  RegisterDoctorData,
  CollectionCount,
  PaginatedSearchRequest,
} from '../types';
import { DOCTORS_URL } from '../util/constants';
import { EditDoctorFormData, CalendarSetupForm } from '../types/form.types';
import { Doctor, Patient, PatientStatus, InvitedPatient } from '../models';
import { SortDirection } from '../types/enums';

export const getDoctors = async (): Promise<ApiGetDoctorsResponse> => {
  const result = await axios.get<ApiGetDoctorsResponse>(`${DOCTORS_URL}`);
  return { doctors: result.data.doctors.map((d) => new Doctor(d)) };
};

export const createDoctor = async (
  doctorData: RegisterDoctorData
): Promise<Doctor> => {
  const result = await axios.post<Doctor>(`${DOCTORS_URL}`, doctorData);
  return new Doctor(result.data);
};

export const editDoctor = async (
  doctorId: string,
  doctorData: EditDoctorFormData
): Promise<Doctor> => {
  const result = await axios.put<Doctor>(
    `${DOCTORS_URL}/${doctorId}`,
    doctorData
  );
  return new Doctor(result.data);
};

export const getDoctor = async (doctorId: string): Promise<Doctor> => {
  const result = await axios.get<Doctor>(`${DOCTORS_URL}/${doctorId}`);
  return new Doctor(result.data);
};

export const getPatientsForDoctor = async (
  doctorId: string,
  {
    sortBy,
    sortDirection,
    searchTerm,
    pageNumber,
    pageSize,
  }: PaginatedSearchRequest,
  activePatientsOnly: boolean
): Promise<CollectionCount<Patient> & { newPatients: Patient[] }> => {
  sortBy = sortBy || 'alphabetical';
  sortDirection = sortDirection || SortDirection.ASC;
  searchTerm = searchTerm || '';

  const result = await axios.get<
    CollectionCount<Patient> & { newPatients: Patient[] }
  >(
    `${DOCTORS_URL}/${doctorId}/patients?sortBy=${sortBy}&search=${searchTerm}&sortDirection=${sortDirection}&activeOnly=${activePatientsOnly}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
  const { total, results, newPatients } = result.data;

  return {
    results: results.map((r) => new Patient(r)),
    total,
    newPatients: newPatients.map((r) => new Patient(r)),
  };
};
export const getInvitedPatientsForDoctor = async (
  doctorId: string,
  { sortBy, sortDirection, pageNumber, pageSize }: PaginatedSearchRequest
): Promise<CollectionCount<InvitedPatient>> => {
  sortBy = sortBy || 'alphabetical';
  sortDirection = sortDirection || SortDirection.ASC;

  const result = await axios.get<CollectionCount<InvitedPatient>>(
    `${DOCTORS_URL}/${doctorId}/patients/invited?sortBy=${sortBy}&sortDirection=${sortDirection}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
  const { total, results } = result.data;

  return {
    results: results.map((r) => new InvitedPatient(r)),
    total,
  };
};

/**
 * Changing doctors management over a patient
 * @param doctorId
 * @param patientId
 * @param newStatus
 */
export const updatePatientStatus = async (
  doctorId: string,
  patientId: string,
  newStatus: PatientStatus
) => {
  const result = await axios.put<Doctor>(
    `${DOCTORS_URL}/${doctorId}/patients/${patientId}/status`,
    {
      status: newStatus,
    }
  );
  return result.data;
};

export const updateCalendarSettings = async (
  doctorId: string,
  patientId: string,
  calendarSettings: CalendarSetupForm
): Promise<Patient> => {
  const result = await axios.post<Patient>(
    `${DOCTORS_URL}/${doctorId}/patients/${patientId}/calendar`,
    calendarSettings
  );
  return new Patient(result.data);
};

export const invitePatientByEmail = async (
  doctorId: string,
  email: string
): Promise<void> => {
  await axios.post<Patient>(`${DOCTORS_URL}/${doctorId}/patients/invited`, {
    email,
  });
};
