import React from 'react';

import { Helmet } from 'react-helmet';

const ANALYTICS_ID = 'G-MEF1BTP5HP';

export function GoogleAnalytics() {
  const url = `https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`;
  return (
    <Helmet>
      <script async src={url} />
      <script>
        {` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-MEF1BTP5HP');`}
      </script>
    </Helmet>
  );
}
