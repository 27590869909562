import React, { useEffect, useRef } from 'react';
import './table.scss';
import classNames from 'classnames';
import { debounceTime } from 'rxjs/operators';
import { useForm } from 'react-hook-form';
import { Subscription, Subject, ReplaySubject } from 'rxjs';
import { uuidv4, getOppositeSortDirection } from '../../../util/helpers';
import { PaginationInfo, SortDirection } from '../../../types';
import { Button, Loader } from '..';

export type TableHeader = {
  label: string;
  sortKey?: string;
  tooltip?: string;
  className?: string;
};

// TODO: types
export type TableProps = {
  // Individual props to components can be passed through properties in the items in the collection
  collection: any[];
  component: React.FC | any;
  headers: TableHeader[];
  isFetchingData: boolean;

  itemLabel?: string;
  searchPlaceholder?: string; // Default Placeholder text for search input
  paginationInfo?: PaginationInfo;
  tableClassName?: string; // Custom Class name for the table
  onSort?: (
    sortKey: string,
    sortDirection: SortDirection,
    searchTerm?: string
  ) => void;
  onSearch?: (searchTerm: string) => void;
  onPageChange?: (newPageNumber: number, searchTerm?: string) => void;
};

const searchSubject = new Subject();
const sortSubject = new ReplaySubject<string>();

export const Table: React.FC<TableProps> = ({
  collection,
  headers,
  tableClassName,
  onSort,
  component,
  onSearch,
  isFetchingData,
  paginationInfo,
  searchPlaceholder,
  itemLabel,
  onPageChange,
  children,
}) => {
  const { register, getValues } = useForm<{
    searchTerm: string;
  }>();
  const isSortingEnabled = onSort && paginationInfo;

  const searchSubscription = useRef<Subscription>();
  const onClick = (sortKey?: string): void => {
    if (isSortingEnabled && sortKey) {
      sortSubject.next(sortKey);
      // TODO: could not figure out why this doesnt work with sorting with a debounce
      onSort!(
        sortKey,
        getOppositeSortDirection(paginationInfo!.sortDirection!),
        getValues().searchTerm
      );
    } else {
      console.warn('either sorting is disabled or sortKey is missing');
    }
  };

  useEffect(() => {
    if (onSearch) {
      searchSubscription.current = searchSubject
        .pipe(debounceTime(250))
        .subscribe(() => {
          onSearch(getValues().searchTerm);
        });
    }
    return (): void => {
      if (searchSubscription.current) {
        searchSubscription.current.unsubscribe();
      }
    };
  });

  const getFooter = () => {
    const { pageNumber, pageSize, total } = paginationInfo!;
    const startIndex = (pageNumber ? pageNumber - 1 : 0) * pageSize! + 1;
    const endIndex =
      pageNumber! * pageSize! > total ? total : pageNumber! * pageSize!;
    return (
      <tfoot>
        {collection.length ? (
          <tr>
            <td colSpan={100}>
              {/* TODO: remove the ! when i fix pagination elsewhere */}
              <span className="total">
                {startIndex} - {endIndex} of {total} {itemLabel || 'Results'}
              </span>
              <span className="actions">
                <Button
                  isDisabled={pageNumber! <= 1}
                  onClick={() =>
                    onPageChange!(pageNumber! - 1, getValues().searchTerm)
                  }
                >
                  Prev
                </Button>
                <Button
                  isDisabled={pageNumber! >= Math.ceil(total / pageSize!)}
                  onClick={() =>
                    onPageChange!(pageNumber! + 1, getValues().searchTerm)
                  }
                >
                  Next
                </Button>
              </span>
            </td>
          </tr>
        ) : null}
      </tfoot>
    );
  };

  return (
    <div className="table-wrapper">
      {onSearch && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            searchSubject.next('');
          }}
        >
          <div className="form-group search-wrapper">
            <label>
              <input
                type="text"
                name="searchTerm"
                className="is-textbox"
                placeholder={searchPlaceholder || 'search'}
                autoComplete=""
                ref={register()}
                onChange={() => searchSubject.next('')}
              />
              <Button
                className="search is-icon"
                onClick={(_e) => searchSubject.next('')}
              >
                <i className="fas fa-search" />
              </Button>
            </label>
          </div>
        </form>
      )}
      {children}
      <table className={tableClassName}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header.label}
                className={classNames(header.className, {
                  'has-tooltip': header.tooltip,
                  sortable: !!header.sortKey,
                  active: paginationInfo?.sortBy === header.sortKey,
                  [`${paginationInfo?.sortDirection?.toLowerCase()}`]:
                    paginationInfo?.sortBy === header.sortKey,
                })}
                data-tooltip={header.tooltip}
                onClick={() => onClick(header.sortKey)}
              >
                {header.label}
                {header.sortKey && (
                  <span className="sort-icons">
                    <i className="fas fa-sort-up" />
                    <i className="fas fa-sort-down" />
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isFetchingData && (
            <tr className="loading">
              <td colSpan={100}>
                <Loader />
              </td>
            </tr>
          )}
          {!isFetchingData &&
            collection.map((item) => {
              const Component = component;
              return <Component key={item.id || uuidv4()} {...item} />;
            })}
          {!isFetchingData && !collection.length && (
            <tr className="empty">
              <td colSpan={100}>no {itemLabel || 'Results'} found</td>
            </tr>
          )}
        </tbody>
        {paginationInfo && onPageChange && getFooter()}
      </table>
    </div>
  );
};
