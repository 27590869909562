import React, { useState, useEffect } from 'react';
import './register.page.scss';
import { useForm, FormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import UserDetails from '../../features/registration/userRegister';
import {
  RegisterFormData,
  RegisterPatientData,
  RegisterDoctorData,
} from '../../../types/form.types';
import {
  useSessionContext,
  parseJwtToken,
} from '../../shared/global/sessionContext';
import { registerNewUser } from '../../../services/authentication.service';
import DoctorRegister from '../../features/registration/doctorRegister';
import PatientRegister from '../../features/registration/patientRegister';
import { UserType } from '../../../types';
import { createPatient, createDoctor } from '../../../services';
import { ElixirSession } from '../../../models';
import {
  onApiErrorToast,
  onSuccessToast,
  onErrorToast,
} from '../../../services/toasts.service';
import { LogoBlock } from '../../shared';
import { useQuery } from '../../../util';
import { initFullStory, shutdownFullStory } from '../../App';
import { User } from '../../../models/user.model';
import { useAnalytics } from '../../../hooks';

// eslint-disable-next-line no-shadow
enum RegisterStage {
  USER,
  PATIENT,
  DOCTOR,
  BILLING,
}

const RegisterPage: React.FC = () => {
  // TODO: https://github.com/facebook/react/issues/14259
  // TODO: look into ReactDOM.unstable_batchedUpdates(() => { ... }) or useReducer to reduce the number of calls
  const [isLoading, setIsLoading] = useState(false);
  // For Email Invites
  const preFilledUserType = useQuery().get('type') || 'null';
  const preFilledEmail = useQuery().get('email') || undefined;

  const methods = useForm<RegisterFormData>({
    defaultValues: {
      termsAndConditions: false,
      userType: preFilledUserType as UserType,
      howDidYouHearAboutUs: preFilledEmail
        ? ([{ value: 'email invite', label: 'Email Invite' }] as any)
        : undefined,
      email: preFilledEmail,
    },
  });

  const [sessionContext, updateSessionContext] = useSessionContext();
  const { identify } = useAnalytics();

  const [registrationStage, setStage] = useState(RegisterStage.USER);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionContext.user?.hasCompletedRegistration) {
      if (sessionContext.user?.userType === UserType.patient) {
        setStage(RegisterStage.PATIENT);
      } else if (sessionContext.user?.userType === UserType.doctor) {
        setStage(RegisterStage.DOCTOR);
      }
    }
  }, [sessionContext]);

  useEffect(() => {
    // Capture fullstory for only registrations right now
    const user = new User();
    user.allowFullStory = true;
    initFullStory(user);
  }, []);

  const onFinishUserData = (user: RegisterFormData) => {
    if (user.userType === UserType.doctor) {
      setStage(RegisterStage.DOCTOR);
    } else {
      setStage(RegisterStage.PATIENT);
    }
  };

  const onRegisterNewDoctor = (formData: RegisterDoctorData) => {
    setIsLoading(true);
    // This is to deal with if they create a new clinic
    if (formData.clinicId && formData.clinicId === 'tempId') {
      // eslint-disable-next-line no-param-reassign
      delete formData.clinicId;
    }
    createDoctor(formData)
      .then((doctorData) => {
        onSuccessToast('New Account Created!');

        const session = new ElixirSession({
          ...sessionContext,
          isAuthenticated: true,
        });
        session.doctor = doctorData;
        updateSessionContext(session);
        shutdownFullStory();
        navigate('/home');
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const onRegisterNewPatient = (formData: RegisterPatientData) => {
    setIsLoading(true);
    createPatient(formData)
      .then((patientData) => {
        onSuccessToast('New Account Created!');
        const session = new ElixirSession({
          ...sessionContext,
          isAuthenticated: true,
        });
        session.patient = patientData;
        updateSessionContext(session);
        shutdownFullStory();
        navigate('/hipaa-consent');
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const onRegisterNewUser = (formData: RegisterFormData) => {
    setIsLoading(true);

    registerNewUser(formData)
      .then((loginData) => {
        const session = new ElixirSession({
          ...sessionContext,
          sessionInfo: {
            ...parseJwtToken(loginData.token),
          },
        });
        updateSessionContext(session);
        onFinishUserData(formData);
        identify(session);
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          // TODO: maybe error response should be array of errors, where either error message or constant is returned
          methods.setError(
            'email',
            'notMatch',
            'This email is already being used'
          );
          onErrorToast('This email is already being used');
        } else {
          onApiErrorToast(error);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="register-page">
      <div className="page-wrapper is-flex-column">
        <LogoBlock />
        <div className="wrapper has-box-shadow">
          {registrationStage === RegisterStage.USER && (
            <FormContext {...methods}>
              <UserDetails onSubmit={onRegisterNewUser} isLoading={isLoading} />
            </FormContext>
          )}
          {registrationStage === RegisterStage.DOCTOR && (
            <DoctorRegister
              onSubmit={onRegisterNewDoctor}
              isLoading={isLoading}
            />
          )}
          {registrationStage === RegisterStage.PATIENT && (
            <PatientRegister
              onSubmit={onRegisterNewPatient}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default RegisterPage;
