import React from 'react';
import './titleBlock.css';

export type TitleBlockProps = {
  title: string;
};

export const TitleBlock: React.FC<TitleBlockProps> = ({ title }) => (
  <h3 className="title-block text-centered">{title}</h3>
);
