import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSessionContext } from '../../shared/global/sessionContext';
import { editUser, deleteAccount } from '../../../services/user.service';
import {
  onApiErrorToast,
  onSuccessToast,
} from '../../../services/toasts.service';
import { UserProfileGeneralFormData } from '../../../types/form.types';
import { User, NotificationType } from '../../../models';
import {
  capitalize,
  pick,
  convertSelectOptionsToValue,
} from '../../../util/helpers';
import { GENDER_OPTIONS, ZONE_OPTIONS, FORM_VALIDATION } from '../../../util';
import NotificationSettings from './notificationSettings';
import 'react-datepicker/dist/react-datepicker.css';
import {
  TextField,
  Dropdown,
  Datepicker,
  PasswordInput,
  Button,
  ConfirmModal,
} from '../../shared';

type AccountSettingsProps = {
  user: User;
  isSameAsSessionAccount: boolean;
  onUpdateUser: (user: User) => void;
};

const AccountSettings: React.FC<AccountSettingsProps> = ({
  user,
  onUpdateUser,
}) => {
  const methods = useForm<UserProfileGeneralFormData>();
  const {
    watch,
    handleSubmit,
    formState: { dirtyFields }, // accessing dirtyFields creates a listener for changes directly
  } = methods;

  const [sessionContext] = useSessionContext();

  const { sessionInfo } = sessionContext;

  const [isConfirmModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  const onSubmitAccountChanges = (values: Record<string, any>) => {
    // Make sure we only submit changed values
    // eslint-disable-next-line no-param-reassign
    values = pick(values, [...dirtyFields]);
    convertSelectOptionsToValue(values, ['timezone', 'gender']);

    setIsSaving(true);
    editUser(user.getId(), values)
      .then((_updatedUser) => {
        onSuccessToast('Changes saved!');
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onDeleteAccount = () => {
    deleteAccount(user.getId())
      .then(() => {
        onSuccessToast('Account Deleted Successfully');
        navigate('/logout');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  const handleSetNotificationSetting = (
    notification: NotificationType,
    value: boolean
  ) => {
    const userId = sessionInfo.user.getId();
    const notificationSettings = user.notificationSettings || {};
    notificationSettings[notification] = value;
    editUser(userId, {
      notificationSettings,
    })
      .then((response) => {
        onUpdateUser(response);
        onSuccessToast('Notification Settings changed.');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  return (
    <>
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmitAccountChanges)}>
          <div className="form-group">
            <TextField
              name="email"
              placeholder="e-mail"
              type="email"
              initialValue={user?.email}
              validationOptions={{
                required: 'Required',
                pattern: {
                  value: FORM_VALIDATION.VALID_EMAIL,
                  message: 'invalid email address',
                },
              }}
            >
              Email
            </TextField>
          </div>
          <div className="form-group half-width">
            <TextField
              name="firstName"
              placeholder=""
              initialValue={capitalize(user?.firstName)}
              validationOptions={{
                required: 'Required',
                min: 1,
                maxLength: 80,
              }}
            >
              First Name
            </TextField>
          </div>
          <div className="form-group half-width">
            <TextField
              name="lastName"
              placeholder=""
              initialValue={capitalize(user?.lastName)}
              validationOptions={{
                required: 'Required',
                min: 1,
                maxLength: 80,
              }}
            >
              Last Name
            </TextField>
          </div>
          <div className="form-group">
            <Dropdown
              name="gender"
              disabled
              options={GENDER_OPTIONS}
              initialValue={
                GENDER_OPTIONS.find((option) => option.value === user.gender)
                  ?.value
              }
            >
              Gender
            </Dropdown>
          </div>
          <div className="form-group">
            <Dropdown
              name="timezone"
              options={ZONE_OPTIONS}
              initialValue={
                ZONE_OPTIONS.find((option) => option.value === user.timezone)
                  ?.value
              }
            >
              Choose your timezone
            </Dropdown>
          </div>
          <div className="form-group">
            <Datepicker
              minDate={new Date('01/01/1930')}
              maxDate={moment().subtract(10, 'years').toDate()}
              name="dateOfBirth"
              placeholder="Select your Date of Birth"
              label="Date of Birth"
              initialValue={
                user.dateOfBirth ? moment(user.dateOfBirth).toDate() : undefined
              }
            />
          </div>

          <details>
            <summary>
              <strong>Password Management</strong>
              <hr />
            </summary>
            <div className="content">

              <fieldset>
                <div className="form-group">
                  <PasswordInput
                    customPasswordLabel="Current Password"
                    isRequired={!!watch('password')}
                    customInputName="currentPassword"
                  />
                </div>
                <div className="form-group">
                  <PasswordInput
                    customPasswordLabel="New Password"
                    isRequired={!!watch('currentPassword')}
                  />
                </div>
                <div className="form-group">
                  <PasswordInput
                    isConfirmPassword
                    isRequired={!!watch('password')}
                  />
                </div>
              </fieldset>
            </div>
          </details>
          <Button isDisabled={isSaving} type="submit">
            Save
          </Button>
        </form>
      </FormContext>
      <NotificationSettings
        user={user}
        onChange={(notification, value) => {
          handleSetNotificationSetting(notification, value);
        }}
      />

      {sessionContext.user.isAdmin() && (
        <section className="other-actions">
          <Button
            className="delete-account danger"
            onClick={() => setIsModalOpen(true)}
          >
            Delete This Account
          </Button>
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            message="Are you sure you want to delete this account?"
            onCancel={() => setIsModalOpen(false)}
            onConfirm={onDeleteAccount}
          >
            <p className="warning-text text-centered">
              Warning: This cannot be undone
            </p>
          </ConfirmModal>
        </section>
      )}
    </>
  );
};

export default AccountSettings;
