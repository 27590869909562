import axios from 'axios';
import { USER_BASE_URL } from '../util';
import { Notification, TreatmentLog, User, Patient } from '../models';
import { CollectionCount } from '../types';

export const getNotifications = async (
  userId: string,
  viewMode: string
): Promise<CollectionCount<Notification> & { userName: string }> => {
  const result = await axios.get<
    CollectionCount<Notification> & { userName: string }
  >(`${USER_BASE_URL}/${userId}/notifications?viewMode=${viewMode}`);
  const { results, total, userName } = result.data;

  return {
    results: results.map((r) => new Notification(r)),
    total,
    userName,
  };
};

export const toggleNotificationReadStatus = async (
  userId: string,
  notificationId: string,
  isUnread: boolean
): Promise<void> => {
  // TODO: how do we handle errors
  await axios.post<Notification[]>(
    `${USER_BASE_URL}/${userId}/notifications/${notificationId}`,
    {
      isUnread,
    }
  );
};

export const getUser = async (userId: string): Promise<User> => {
  const result = await axios.get<User>(`${USER_BASE_URL}/${userId}`);

  return new User(result.data);
};
export const editUser = async (
  userId: string,
  user: Partial<User>
): Promise<User> => {
  const result = await axios.put<User>(`${USER_BASE_URL}/${userId}`, user);
  return new User(result.data);
};

export const markAllNotificationAsRead = async (
  userId: string
): Promise<void> => {
  await axios.put<User>(`${USER_BASE_URL}/${userId}/notifications`);
};

export const deleteAccount = async (
  userId: string,
  reasonsWhy?: string[],
  explained?: string
): Promise<{ fields?: string[]; logs?: TreatmentLog[] }> => {
  const result = await axios.delete<{
    fields?: string[];
    logs?: TreatmentLog[];
  }>(`${USER_BASE_URL}/${userId}`, {
    data: {
      reasonsWhy,
      explained,
    },
  });
  const { data } = result;
  // fields + logs will only be for Patient deletions
  return {
    fields: data?.fields,
    logs: data?.logs?.map((t) => new TreatmentLog(t)),
  };
};

export const cancelSubscription = async (
  userId: string,
  reasonsWhy?: string[],
  explained?: string
): Promise<Date> => {
  const result = await axios.delete<Date>(
    `${USER_BASE_URL}/${userId}/subscription`,
    {
      data: {
        reasonsWhy,
        explained,
      },
    }
  );
  return result.data;
};

export const getPatientByUserId = async (userId: string): Promise<Patient> => {
  const result = await axios.get<Patient>(`${USER_BASE_URL}/${userId}/patient`);
  return new Patient(result.data);
};
