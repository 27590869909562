import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LoginFormData } from '../../../types/form.types';
import { login } from '../../../services/authentication.service';
import {
  useSessionContext,
  parseJwtToken,
} from '../../shared/global/sessionContext';
import { ElixirSession } from '../../../models';
import { onErrorToast, onWarningToast } from '../../../services/toasts.service';
import { FORM_VALIDATION } from '../../../util';
import { useAppContext } from '../../shared/global/appContext';
import { TextField, PasswordInput, Button } from '../../shared';
import { useAnalytics } from '../../../hooks';

const LoginForm: React.FC = ({ children }) => {
  const formContextMethods = useForm<LoginFormData>();

  const [isLoading, setIsLoading] = useState(false);
  const [sessionContext, updateSessionContext] = useSessionContext();
  const { updateAppState } = useAppContext();
  const navigate = useNavigate();
  const { identify } = useAnalytics();

  const onSubmit = formContextMethods.handleSubmit(
    async ({ email, password }) => {
      // TODO: loading animations/states
      setIsLoading(true);
      try {
        const loginData = await login({ email, password });
        const tokenData = parseJwtToken(loginData.token);
        const sessionInfo = new ElixirSession({
          ...sessionContext,
          isAuthenticated: true,
          sessionInfo: { ...tokenData },
        });
        if (!tokenData.user.hasCompletedRegistration) {
          sessionInfo.isAuthenticated = false;
          onWarningToast(
            'You must finish your registration before you can sign in.'
          );
          navigate('/register');
        }

        if (tokenData.user.billing) {
          updateAppState({
            isFrozenAccount: tokenData.user.billing.isFrozen(),
            isInDunning: tokenData.user.billing.isInDunning(),
            isCancelled: tokenData.user.billing.isCancelled,
          });
        }
        // initFullStory(tokenData.user);
        updateSessionContext(sessionInfo);

        identify(sessionInfo);
      } catch (error: any) {
        if (error.response?.status === 401) {
          formContextMethods.setError(
            'email',
            'notMatch',
            'Invalid email or password'
          );
          formContextMethods.setError(
            'password',
            'notMatch',
            'Invalid email or password'
          );
          onErrorToast('Your email or password is incorrect');
        } else if (error.request) {
          // network error, do nothing as app already caught it
        }
        setIsLoading(false);
      }
    }
  );

  return (
    <FormContext {...formContextMethods}>
      <form className="login-form" onSubmit={onSubmit}>
        <h4>SIGN IN</h4>
        <hr />
        <div className="form-group">
          <TextField
            name="email"
            type="email"
            placeholder=""
            required
            validationOptions={{
              required: 'Required',
              pattern: {
                value: FORM_VALIDATION.VALID_EMAIL,
                message: 'invalid email address',
              },
            }}
          >
            Email
          </TextField>
          <PasswordInput isRequired />
        </div>
        <Button type="submit" isLoading={isLoading} className="is-primary">
          Sign In
        </Button>
        {children}
      </form>
    </FormContext>
  );
};

export default LoginForm;
