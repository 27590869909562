import React, { useState, useEffect } from 'react';
import './header.scss';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Subject } from 'rxjs';
import { useSessionContext } from '../global/sessionContext';
import { uuidv4 } from '../../../util/helpers';
import { getNotifications } from '../../../services/user.service';
import { onApiErrorToast } from '../../../services/toasts.service';
import { Button } from '..';

const pageTitleSubject = new Subject<string>();

/**
 * Used to explictly set the header with a new title (ex: on /patient pages)
 * @param newTitle
 */
export function setPageTitle(newTitle: string) {
  pageTitleSubject.next(newTitle);
}

export function Header() {
  const location = useLocation();
  const [isFullPageExpanded, togglePageExpanded] = useState(false);
  const [session] = useSessionContext();
  const userIdToUse = session.user.getId();
  const [notificationCount, setNotificationCount] = useState(0);
  const [headerTitle, setHeaderTitle] = useState(
    location.pathname.replace('/', '')
  );

  // TODO: this is not updating on session changes
  const initials = session.user
    .getFullName()
    .split(' ')
    .slice(0, 2)
    .map((s: string) => s[0].toUpperCase());
  useEffect(() => {
    const pageTitleSubscription = pageTitleSubject.subscribe((newTitle) => {
      setHeaderTitle(newTitle);
    });

    return () => {
      pageTitleSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    togglePageExpanded(false);
    if (location.pathname !== '/logout') {
      getNotifications(userIdToUse, 'unread')
        .then((newNotifications) => {
          setNotificationCount(newNotifications.total);
        })
        .catch((error) => {
          onApiErrorToast(error);
        });
    }
    setHeaderTitle(location.pathname.replace('/', '') || 'Home');
  }, [location.pathname, userIdToUse]);

  const getFullNavLinks = (): React.ReactElement[] => {
    const links: React.ReactElement[] = [];
    if (session.isAdmin) {
      links.push(
        <NavLink key={uuidv4()} className="is-flex-column" to="/admin/users">
          <i className="fas fa-users" />
          users
        </NavLink>,
        <NavLink key={uuidv4()} className="is-flex-column" to="/admin/clinics">
          <i className="fas fa-clinic-medical" />
          clinics
        </NavLink>
      );
    } else {
      if (session.isPatientView) {
        links.push(
          <NavLink key={uuidv4()} className="is-flex-column" to="/calendar">
            <i className="fas fa-calendar-alt" />
            calendar
          </NavLink>
        );
        links.push(
          <NavLink key={uuidv4()} className="is-flex-column"
            to={`patient/${session.patient?.getId()}/analytics`}
          >
            <i className="fas fa-chart-bar" />
            analytics
          </NavLink>
        );
      } else if (session.isDoctorView) {
        links.push(
          <NavLink key={uuidv4()} className="is-flex-column" to="/dashboard">
            <i className="fas fa-columns" />
            dashboard
          </NavLink>
        );
      }

      links.push(
        <NavLink key={uuidv4()} className="is-flex-column" to="/notifications">
          {notificationCount > 0 && (
            <span className="notification-badge-count">
              {notificationCount}
            </span>
          )}
          <i className="fas fa-bell" />
          notifications
        </NavLink>,
        <NavLink key={uuidv4()} className="is-flex-column" to="/profile">
          <i className="fas fa-user" />
          profile
        </NavLink>
      );
    }
    if (session.isPatientView) {
      links.push(
        <NavLink
          key="billing"
          className="is-flex-column has-tooltip"
          data-tooltip="Billing"
          to="/billing"
        >
          <i className='fas fa-dollar-sign' />
          Billing
        </NavLink>
      )
    }

    links.push(
      <NavLink key={uuidv4()} className="is-flex-column" to="/settings">
        <i className="fas fa-cog" />
        settings
      </NavLink>,
      <a
        key="help"
        className="is-flex-column"
        href="https://artemiscalendar.com/help"
        target="_blank"
        rel="noreferrer noopener"
      >
        <i className="fas fa-question-circle" />
        help
      </a>
    );

    return links;
  };

  // For Desktop
  const getTopNavLinks = (): React.ReactElement[] => {
    const links: React.ReactElement[] = [];

    if (session.isAdmin) {
      links.push(
        <NavLink
          key="users"
          className="is-flex-column has-tooltip"
          data-tooltip="Users"
          to="/admin/users"
        >
          <i className="fas fa-users" />
        </NavLink>,
        <NavLink
          key="clinics"
          className="is-flex-column has-tooltip"
          to="/admin/clinics"
          data-tooltip="Clinics"
        >
          <i className="fas fa-clinic-medical" />
        </NavLink>
      );
    } else {
      if (session.isPatientView) {
        links.push(
          <NavLink
            key="calendar"
            className="is-flex-column has-tooltip"
            data-tooltip="Calendar"
            to="/calendar"
          >
            <i className="fas fa-calendar-alt" />
          </NavLink>
        );
        links.push(
          <NavLink
            key="analytics"
            className="is-flex-column has-tooltip"
            data-tooltip="Analytics"
            to={`patient/${session.patient?.getId()}/analytics`}
          >
            <i className="fas fa-bar-chart" />
          </NavLink>
        );
      } else if (session.isDoctorView) {
        links.push(
          <NavLink
            key="dashboard'"
            className="is-flex-column has-tooltip"
            data-tooltip="Dashboard"
            to="/dashboard"
          >
            <i className="fas fa-columns" />
          </NavLink>
        );
      }
      links.push(
        <NavLink
          key="notifications"
          className="is-flex-column has-tooltip"
          data-tooltip="Notifications"
          to="/notifications"
        >
          {notificationCount > 0 && (
            <span className="notification-badge-count">
              {notificationCount}
            </span>
          )}
          <i className="fas fa-bell" />
        </NavLink>
      );
    }
    if (session.isPatientView) {
      links.push(
        <NavLink
          key="billing"
          className="is-flex-column has-tooltip"
          data-tooltip="Billing"
          to="/billing"
        >
          <i className='fas fa-dollar-sign' />
        </NavLink>
      )
    }
    links.push(
      <NavLink
        key="settings"
        className="is-flex-column has-tooltip"
        data-tooltip="Settings"
        to="/settings"
      >
        <i className="fas fa-cog" />
      </NavLink>,
      <a
        key="help"
        className="is-flex-column has-tooltip"
        data-tooltip="Help"
        href="https://artemiscalendar.com/help"
        target="_blank"
        rel="noreferrer noopener"
      >
        <i className="fas fa-question-circle" />
      </a>
    );
    return links;
  };

  let showNavLinks = true;
  // If they havent agreed to hipaa compliance, dont let them use any part of the site
  if (session.isPatientView && !session.patient?.hasHipaaConsent()) {
    showNavLinks = false;
  }

  return (
    <header
      className={`${isFullPageExpanded ? 'is-expanded' : ''
        } is-fixed nav-header has-box-shadow`}
    >
      <section>
        <Link to="/" className="button is-icon logo">
          <img src="/images/logo.png" alt="Logo" />
          {session.isAdmin && <span className="admin-label">ADMIN</span>}
        </Link>
        <h3 title={headerTitle}>{headerTitle}</h3>
        {showNavLinks && (
          <div className="actions">
            <nav className="desktop-only">{getTopNavLinks()}</nav>
            <span className="user-profile desktop-only">
              <NavLink
                className="initials"
                to={session.isAdmin ? '/settings' : '/profile'}
              >
                {initials}
              </NavLink>
            </span>
            <Button
              className="menu is-secondary"
              onClick={() => togglePageExpanded(!isFullPageExpanded)}
            >
              MENU
              <span className="sr-only">Navigation Menu</span>
            </Button>
          </div>
        )}
      </section>

      {isFullPageExpanded && (
        <nav className="expandable">
          {getFullNavLinks()}
          <Link to="/logout" className="is-flex-column logout">
            <i className="fas fa-sign-out-alt" />
            Logout
          </Link>
        </nav>
      )}
    </header>
  );
}
