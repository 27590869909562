import React from 'react';
import './paymentMethodBlock.scss';

type PaymentMethodBlockProps = {
  paymentMethod: {
    brand: string;
    last4: number;
    expMonth: number;
    expYear: number;
    isDefault?: boolean;
  };
};

const PaymentMethodBlock: React.FC<PaymentMethodBlockProps> = ({
  paymentMethod: pm,
}) => {
  const cardExpiryDate = new Date(`${pm.expMonth}/01/${pm.expYear}`);
  const isExpired = new Date() > cardExpiryDate;
  return (
    <div className={`payment-method ${isExpired ? 'expired' : ''}`}>
      {/* TODO: can i use the stripe compoentns intead */}
      <span className="name">
        Card on File {pm.isDefault ? '(Default Card)' : ''}{' '}
        {isExpired ? '(Expired)' : ''}
      </span>
      <div className="card">
        <strong className="provider">{pm.brand.toUpperCase()}</strong>
        <div className="card-details">
          <span className="digits">
            Ending in: <strong>{pm.last4}</strong>
          </span>
          <span className="expiry">
            Exp: {pm.expMonth}/{pm.expYear}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodBlock;
