import React from 'react';
import './calendarSetup.scss';
import moment from 'moment-timezone';
import { CalendarViewMode } from './calendar';
import './calendarHeader.scss';
import { withSession } from '../../shared/global';
import { withSessionProp } from '../../shared/global/withSession';
import { Button, Tabs } from '../../shared';

type CalendarHeaderProps = {
  viewMode: CalendarViewMode;
  currentDisplayMonth: moment.Moment;
  onClickPrevMonth: () => void;
  onClickNextMonth: () => void;
  onClickSetViewMode: (newViewMode: CalendarViewMode) => void;
};

const CalendarHeader: React.FC<CalendarHeaderProps & withSessionProp> = ({
  onClickNextMonth,
  onClickPrevMonth,
  onClickSetViewMode,
  viewMode,
  currentDisplayMonth,
}) => {
  // We only have Full Moon dates starting from Feb 2020
  const isPrevMonthButtonDisabled =
    currentDisplayMonth.month() === 1 && currentDisplayMonth.year() === 2020;
  const currentDisplayMonthFormatted = currentDisplayMonth.format('MMMM YYYY');

  return (
    <>
      <Tabs
        tabs={[
          {
            label: 'Month View',
            id: 'calendar',
            icon: 'far fa-calendar-alt',
          },
          {
            label: 'Day by Day View',
            id: 'list',
            icon: 'far fa-list-alt',
          },
        ]}
        onTabChange={(tab) => {
          if (tab.id === 'calendar') {
            onClickSetViewMode(CalendarViewMode.CALENDAR);
          } else if (tab.id === 'list') {
            onClickSetViewMode(CalendarViewMode.LIST);
          }
        }}
        activeTabId={viewMode.toString()}
      />
      <div className="action-bar">
        <Button
          className="is-link is-flat top-tooltip is-dark"
          onClick={onClickPrevMonth}
          isDisabled={isPrevMonthButtonDisabled}
          tooltipText="Previous Month"
        >
          <i className="fas fa-arrow-alt-circle-left" />
        </Button>
        <span>{currentDisplayMonthFormatted}</span>
        <Button
          className="is-link is-flat top-tooltip is-dark"
          onClick={onClickNextMonth}
          isDisabled={isPrevMonthButtonDisabled}
          tooltipText="Next Month"
        >
          <i className="fas fa-arrow-alt-circle-right" />
        </Button>
      </div>
    </>
  );
};

export default withSession(CalendarHeader);
