/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Clinic } from '../../../models';
import './clinicTableItem.css';

type ClinicTableItemProps = {
  clinic: Clinic & { doctorCount: number };
};

const ClinicTableItem: React.FC<ClinicTableItemProps> = ({ clinic }) => {
  const navigate = useNavigate();

  const onClick = () => navigate(`/clinic/${clinic.getId()}`);

  return (
    <tr className="clinic-table-item" onClick={onClick}>
      <td className="name">{clinic.name}</td>
      <td className="doctor-count">{clinic.doctorCount}</td>
      <td className="created-date desktop-only">
        {moment(clinic.createdDate).fromNow()}
      </td>
    </tr>
  );
};

export default ClinicTableItem;
