import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import moment from 'moment';
import {
  CALENDAR_TYPE_OPTIONS,
  PERIOD_MARGIN_OPTIONS,
} from '../../../util/constants';
import './calendarSetup.scss';
import { Patient } from '../../../models';

import 'react-datepicker/dist/react-datepicker.css';
import { convertSelectOptionsToValue } from '../../../util/helpers';
import { Button, Datepicker, Dropdown, TextField } from '../../shared';
import {
  CalendarSetupForm,
  SelectOption,
  CalendarType,
  TreatmentSubstance,
  FrequencyUnit,
} from '../../../types';
import { getStaticSchedules, onApiErrorToast } from '../../../services';
import { DosageSelectionsList } from './dosageSelection';
import { StaticSchedule } from '../../../models/staticSchedule.model';
import { useSessionContext } from '../../shared/global/sessionContext';

type CalendarSetupProps = {
  onSubmit: (values: CalendarSetupForm) => void;
  isSaving?: boolean;
  patient?: Patient; // Only would happen if the doctor is editing
  onRegistration?: boolean;
  children?: React.ReactElement;
};

function PersonalCalendarSetup({
  patient,
  onRegistration,
}: Pick<CalendarSetupProps, 'patient' | 'onRegistration'>) {
  const [session] = useSessionContext();
  return (
    <>
      <div className="form-group">
        <TextField
          required
          name="averagePeriodDuration"
          type="number"
          placeholder="5 days"
          initialValue={patient?.averagePeriodDuration}
          validationOptions={{
            required: 'Required',
            min: {
              value: 2,
              message: 'Must be greater than 1 day',
            },
            max: {
              value: 10,
              message: 'Cannot be greater than 10 days',
            },
          }}
          min={2}
          max={10}
        >
          {patient ? 'Your' : ''} Period Length
          <p className="description">
            The average number of days your period lasts for
          </p>
        </TextField>
      </div>
      <div className="form-group">
        <TextField
          required
          name="averagePeriodCycleLength"
          type="number"
          placeholder="28 days"
          initialValue={patient?.averagePeriodCycleLength}
          validationOptions={{
            required: 'Required',
            min: {
              value: 20,
              message: 'Must be greater than 20 days',
            },
            max: {
              value: 50,
              message: 'Cannot be greater than 50 days',
            },
          }}
          min={20}
          max={50}
        >
          {patient ? 'Your' : ''} Cycle Length
          <p className="description">
            The average number of days between periods. 28 is typical. You can
            enter a number between 20 - 50.
          </p>
        </TextField>
      </div>
      <div className="form-group">
        <Datepicker
          dateFormat="d MMM, yyyy"
          minDate={new Date('01/01/2020')}
          maxDate={new Date()}
          showTimeSelect={false}
          todayButton="Today"
          name="lastPeriodDate"
          initialValue={
            patient?.lastPeriodDate
              ? moment(patient?.lastPeriodDate).toDate()
              : undefined
          }
          placeholderText="Select a date"
          key="periodDate"
          required
          validationOptions={{
            required: {
              value: true,
              message: 'required',
            },
          }}
          label="When did your last period start?"
        />
      </div>
      {!onRegistration && (
        <div className="form-group">
          <Dropdown
            name="periodMargin"
            options={PERIOD_MARGIN_OPTIONS}
            initialValue={
              PERIOD_MARGIN_OPTIONS.find(
                (option) => option.value === patient?.periodMargin
              )?.value || PERIOD_MARGIN_OPTIONS[0].value
            }
            disabled={!patient?.getId()}
            validationOptions={{
              required: {
                value: true,
                message: 'required',
              },
            }}
            required
          >
            Period Margin
            <p className="description">
              Notify when {session.isSelfManagedView ? 'my ' : ''}period start
              or bleed is early or late by more than a set number of days.
            </p>
          </Dropdown>
        </div>
      )}
    </>
  );
}

/**
 * This is only accessible for the patient on registration
 * Otherwise only the doctor can access this
 */
function CalendarSetup({
  onSubmit,
  patient,
  isSaving,
  onRegistration = false,
  children,
}: CalendarSetupProps) {
  const methods = useForm<CalendarSetupForm>({
    defaultValues: {},
  });
  const { handleSubmit, watch } = methods;
  const patientToUse = patient || new Patient();
  const [staticSchedulesMap, setStaticSchedulesMap] = useState(
    {} as Record<string, StaticSchedule[]>
  );

  function setDefaultScheduleValues(
    schedules: Record<string, StaticSchedule[]>
  ) {
    if (onRegistration) {
      const estrogenStandard = schedules[TreatmentSubstance.estrogen].find(
        (option: any) => option.name.includes('Standard')
      );
      if (estrogenStandard) {
        methods.setValue(
          `${TreatmentSubstance.estrogen}-${FrequencyUnit.day}`,
          estrogenStandard as any
        );
      }
      const progesteroneStandard = schedules[
        TreatmentSubstance.progesterone
      ].find((option: any) => option.name.includes('Standard'));
      if (progesteroneStandard) {
        methods.setValue(
          `${TreatmentSubstance.progesterone}-${FrequencyUnit.day}`,
          progesteroneStandard as any
        );
      }
    }
  }

  useEffect(() => {
    getStaticSchedules()
      .then((schedules) => {
        const substanceMap: Record<string, StaticSchedule[]> = {};
        schedules.forEach((s) => {
          if (substanceMap[s.substance]) {
            substanceMap[s.substance] = [...substanceMap[s.substance], s];
          } else {
            substanceMap[s.substance] = [s];
          }
        });
        setStaticSchedulesMap(substanceMap);
        setDefaultScheduleValues(substanceMap);
      })
      .catch((error) => onApiErrorToast(error));
  }, []);

  const chosenCalendarType = watch(
    'calendarType'
  ) as unknown as SelectOption<string>;
  // ChosenCalendarType  only would be undefined on first render
  const isPersonalCalendar =
    chosenCalendarType?.value === CalendarType.personal ||
    (!chosenCalendarType &&
      patientToUse?.calendarType === CalendarType.personal);

  // TODO: use memo
  const onSubmitCheck = (values: any) => {
    // These keys enable/disable the multi dosing, but we dont need to actually send them to the API
    const multiDosingKeys = Object.keys(values).filter(
      (v) => v.indexOf('enableMultiDosing') >= 0
    );

    // Because the schedules are now dictated by frequency Units, we need to try and find the keys
    // that could be associated to the substances, and the different frequencies
    const scheduleKeys = Object.keys(values).filter((key) =>
      [FrequencyUnit.am, FrequencyUnit.day, FrequencyUnit.pm].find(
        (frequency) => key.includes(frequency)
      )
    );
    const updatedValues = convertSelectOptionsToValue(values, [
      'calendarType',
      'periodMargin',
      ...scheduleKeys,
    ]);

    multiDosingKeys.forEach((k) => {
      // eslint-disable-next-line no-param-reassign
      delete updatedValues[k];
    });

    // To pass data to the API, we separate out each frequencyUnit and substance, to differentiate which
    // static schedule it should be updated to, or to be pulled from

    // TODO: THIS ISNT RIGHT, NEED A WAY TO DIFFERENTIATE BETWEEN DELIBERATE ACTION?
    updatedValues.staticSchedules = {};
    scheduleKeys.forEach((key) => {
      const [substance, frequencyUnit] = key.split('-');
      if (updatedValues[key]) {
        if (updatedValues.staticSchedules[substance]) {
          updatedValues.staticSchedules[substance][frequencyUnit] =
            updatedValues[key];
        } else {
          updatedValues.staticSchedules[substance] = {
            [frequencyUnit]: updatedValues[key],
          };
        }
      }
      // We'll use 0 to represent keeping the same value, or to use the existing one if we're transferring frequency units
      else if (updatedValues.staticSchedules[substance]) {
        updatedValues.staticSchedules[substance][frequencyUnit] = 0;
      } else {
        updatedValues.staticSchedules[substance] = {
          [frequencyUnit]: 0,
        };
      }
      // eslint-disable-next-line no-param-reassign
      delete updatedValues[key];
    });

    onSubmit(updatedValues as any);
  };

  return (
    <div className="calendar-setup">
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(onSubmitCheck)}>
          <h3>
            Set up{' '}
            {patientToUse?.user
              ? `${patientToUse.user.getFullName()}'s`
              : 'Your'}{' '}
            Calendar
          </h3>
          <hr />

          <div className="form-group">
            <Dropdown
              name="calendarType"
              options={CALENDAR_TYPE_OPTIONS}
              required
              initialValue={
                CALENDAR_TYPE_OPTIONS.find(
                  (option) => option.value === patientToUse?.calendarType
                )?.value
              }
              validationOptions={{
                required: 'Required',
              }}
            >
              Calendar Type
            </Dropdown>
            {onRegistration && (
              <>
                <p>
                  Choose <strong>Lunar</strong> if you are post menopausal, had
                  a hysterectomy or do not expect a period anytime in the next
                  28 days.
                </p>
                <p>
                  Choose <strong>Personal</strong> if you expect a period within
                  the next 28 - 40 days.
                </p>
              </>
            )}
          </div>

          {isPersonalCalendar && (
            <details open={onRegistration}>
              <summary>Personal Calendar Details</summary>
              <PersonalCalendarSetup
                patient={patientToUse}
                onRegistration={onRegistration}
              />
            </details>

          )}
          <details open>
            <summary>Dosages</summary>
            <DosageSelectionsList
              staticSchedulesMap={staticSchedulesMap}
              patient={patientToUse}
              onRegistration={onRegistration}
            />
          </details>


          {children}
          <Button isLoading={isSaving} type="submit">
            Save
          </Button>
        </form>
      </FormContext>
    </div>
  );
}

export default CalendarSetup;
