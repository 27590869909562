import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TreatmentLog } from '../../../models';
import { Radio } from '../../shared';
import { TreatmentLogFormData } from '../../../types/form.types';
import { useSessionContext } from '../../shared/global/sessionContext';
import { RATING_PULSE_VALUES } from '../../../util';

const FieldSet = styled.fieldset`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f1f1f8;
  width: 100%;
  padding: 20px 0;
  input[type='radio'] {
    display: none;
  }
  i {
    --global-font-size-multiplier: 2;
  }
  label {
    cursor: pointer;
    transition: 0.5s;
    opacity: 0.5;
    &.checked,
    &:hover {
      transform: scale(1.4);
      border-radius: 50px;
      opacity: 1;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
`;

const Label = styled.p`
  margin-top: 0;
`;

export function HowDoYouFeel({
  treatmentLog,
  disabledForFutureDate,
}: {
  treatmentLog: TreatmentLog;
  disabledForFutureDate: boolean;
}) {
  const methods = useFormContext<TreatmentLogFormData>();
  const [sessionContext] = useSessionContext();
  const watchedPulse = methods.watch('pulse');
  const pulse = watchedPulse ? Number(watchedPulse) : watchedPulse;
  const activePulse = RATING_PULSE_VALUES.find(([number]) => number === pulse);
  return (
    <div className="how-do-you-feel">
      <Label>
        <strong>
          Overall, today I {treatmentLog.isTemporaryLog ? 'feel' : 'felt'}...{' '}
          {activePulse && activePulse[2]}
        </strong>
      </Label>
      <FieldSet>
        {RATING_PULSE_VALUES.map(([value, emoji]) => (
          <Radio
            key={value}
            name="pulse"
            value={value}
            isChecked={Number(pulse) === value}
            disabled={
              sessionContext.isDoctorView ||
              disabledForFutureDate ||
              (sessionContext.isSelfManagedView &&
                new Date() < new Date(treatmentLog.date))
            }
          >
            <i className={`${emoji}`} />
          </Radio>
        ))}
      </FieldSet>
    </div>
  );
}
