import React, { useEffect } from 'react';
import './onboarding.page.css';
import { Link, useNavigate } from 'react-router-dom';
import { setPageTitle } from '../../shared/header/header';
import AddCreditCardForm from '../../features/billing/addCreditCardForm';
import { useSessionContext } from '../../shared/global/sessionContext';
import BillingAddressForm from '../../features/billing/billingAddressForm';
import { TitleBlock } from '../../shared';

const OnboardingPage: React.FC = () => {
  const navigate = useNavigate();
  const [sessionInfo] = useSessionContext();
  useEffect(() => {
    setPageTitle('Onboarding');
    document.querySelector('main')!.scrollTo({ top: 0, behavior: 'smooth' });
    if (sessionInfo.user.billing) {
      // On registration, this isnt populated
      navigate('/home');
    }
  }, []);
  // TODO: 2021/01/12 - maybe instead of hardcoding price, get subscription api

  return (
    <section className="onboarding-page">
      <div className="page-wrapper is-flex-column">
        <TitleBlock title="Get started with a FREE 30-day Trial on Us!" />
        <div className="fee-description">
          <p>
            During your trial, all app features for your account will be
            accessible by you
            {!sessionInfo.isSelfManagedView && ' and your provider'}.
          </p>
          <p>
            Monthly subscription fees of $5.99 USD apply after. Payment by
            credit card is required to maintain access to app features after
            your trial ends.
          </p>
          {sessionInfo.isPatientView && !sessionInfo.isSelfManagedView && (
            <p>
              Your trial period begins once your provider approves your request
              to manage your treatment.
            </p>
          )}
        </div>
        <hr className="line-divider" />
        <div className="billing-address">
          <h4>Billing Address</h4>
          <br />
          <BillingAddressForm showWarningIfMissing={false} />
        </div>
        <hr className="line-divider" />
        <p className="text-centered">
          Add a Credit Card now so you don&apos;t lose access to your app when
          your trial finishes.
        </p>
        <p className="text-centered">
          You will not be charged until your trial ends.
        </p>
        <div className="actions">
          <Link className="button text-centered skip-for-now" to="/home">
            Skip For Now
          </Link>
          <AddCreditCardForm />
        </div>
      </div>
    </section>
  );
};
export default OnboardingPage;
