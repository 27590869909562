import React, { useEffect, useState } from 'react';
import PatientForProspectiveDoctorTableItem from './patientForProspectiveDoctorTableItem';
import { AsyncReturnType } from '../../../util/helpers';
import { getPatientsOfProspectiveDoctors } from '../../../services/admin.service';
import { Doctor } from '../../../models';
import { getDoctors, onApiErrorToast } from '../../../services';
import { SelectOption, ApiGetDoctorsResponse } from '../../../types';
import { TableHeader, Table } from '../../shared';

type PatientsForProspectiveDoctorsTableProps = {
  patients: AsyncReturnType<typeof getPatientsOfProspectiveDoctors>;
  isLoading: boolean;
  onChangeDoctor: (doctorId: string, patientId: string) => void;
};

const PatientsForProspectiveDoctorsTable: React.FC<
  PatientsForProspectiveDoctorsTableProps
> = ({ patients, onChangeDoctor, isLoading }) => {
  const [doctorOptions, setDoctorOptions] = useState<SelectOption<string>[]>(
    []
  );
  useEffect(() => {
    async function getDoctorsOptions(): Promise<void> {
      // TODO: double check
      getDoctors()
        .then((doctorsResponse: ApiGetDoctorsResponse) => {
          const doctors = doctorsResponse.doctors.map((doctor: Doctor) => {
            return {
              label: `Dr. ${doctor.user.getFullName()}`,
              value: doctor.getId(),
            };
          });
          setDoctorOptions(doctors);
        })
        .catch((error) => onApiErrorToast(error));
    }

    getDoctorsOptions();
  }, []);
  const tableHeaders = [
    { label: 'Name', className: 'left-align' },
    { label: 'Doctor Name' },
    { label: 'Choose a Doctor' },
  ] as TableHeader[];

  return (
    <>
      <h3 className="title text-centered">Patients of Prospective Doctors</h3>
      <br />
      <Table
        headers={tableHeaders}
        collection={patients.map((patient) => ({
          id: patient.id,
          patient,
          doctorOptions,
          onChangeDoctor,
        }))}
        component={PatientForProspectiveDoctorTableItem}
        isFetchingData={isLoading}
        itemLabel="patients"
      />
    </>
  );
};

export default PatientsForProspectiveDoctorsTable;
