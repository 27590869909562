import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { updateBillingAddress } from '../../../services';
import { BillingAddress } from '../../../types/apiResponse.types';
import { countries, states } from '../../../assets';
import { convertSelectOptionsToValue } from '../../../util/helpers';
import {
  onApiErrorToast,
  onSuccessToast,
} from '../../../services/toasts.service';
import { SelectOption } from '../../../types';
import { Button, Dropdown, TextField } from '../../shared';

type BillingAddressFormProps = {
  address?: BillingAddress;
  showWarningIfMissing?: boolean; // Whether to show the warning prompt about filling in their address (only for billing page)
};

const countryOptions: SelectOption<string>[] = countries.map(
  (c: { country: string; code: string }) => ({
    value: c.code,
    label: c.country,
  })
);

const stateOptions: SelectOption<string>[] = states.map((state: string) => {
  return {
    value: state,
    label: state,
  };
});

const BillingAddressForm: React.FC<BillingAddressFormProps> = ({
  // eslint-disable-next-line camelcase
  address: { city, country, state, line1, line2, postal_code } = {},
  showWarningIfMissing = true,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const methods = useForm<BillingAddress>();
  const onSubmit = (e: BillingAddress) => {
    setIsSaving(true);
    const selectOptionsArray = ['country'];
    if ((watch('country') as unknown as SelectOption<string>)?.value === 'US') {
      selectOptionsArray.push('state');
    }
    const addressInfo = convertSelectOptionsToValue(e, selectOptionsArray);
    updateBillingAddress(addressInfo as BillingAddress)
      .then(() => onSuccessToast('Billing Address Updated!'))
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsSaving(false));
  };
  const { watch } = methods;

  return (
    <>
      {showWarningIfMissing &&
        (!watch('city') ||
          !watch('country') ||
          !watch('state') ||
          !watch('line1') ||
          !watch('postal_code')) && (
          <p className="action-required">
            <i className="fas fa-times" /> Billing Address is Incomplete!
          </p>
        )}
      <FormContext {...methods}>
        <form
          className="billing-address-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="form-group half-width--mobile-only">
            <Dropdown
              name="country"
              options={countryOptions}
              required
              validationOptions={{
                required: 'Required',
              }}
              initialValue={
                countryOptions.find((c) => c.value === country)?.value
              }
            >
              Country
            </Dropdown>
          </div>
          <div className="form-group half-width--mobile-only">
            {(watch('country') as unknown as SelectOption<string>)?.value ===
              'US' && (
              <Dropdown
                name="state"
                options={stateOptions}
                required
                initialValue={
                  stateOptions.find((o) => o.value === state)?.label
                }
              >
                State
              </Dropdown>
            )}
            {(watch('country') as unknown as SelectOption<string>)?.value !==
              'US' && (
              <TextField
                required
                name="state"
                initialValue={state}
                validationOptions={{
                  required: 'Required',
                }}
              >
                State/Province
              </TextField>
            )}
          </div>
          <div className="form-group half-width--mobile-only">
            <TextField
              required
              name="city"
              placeholder="City"
              initialValue={city}
              validationOptions={{
                required: 'Required',
              }}
            >
              City
            </TextField>
          </div>
          <div className="form-group half-width--mobile-only">
            <TextField
              required
              name="postal_code"
              placeholder="ZIP / Postal Code"
              // eslint-disable-next-line camelcase
              initialValue={postal_code}
              validationOptions={{
                required: 'Required',
              }}
            >
              ZIP / Postal Code
            </TextField>
          </div>
          <div className="form-group">
            <TextField
              required
              name="line1"
              initialValue={line1}
              placeholder="Address Line 1"
              validationOptions={{
                required: 'Required',
              }}
            >
              Address Line 1
            </TextField>
          </div>
          <div className="form-group">
            <TextField
              name="line2"
              initialValue={line2}
              placeholder="Suite / Appartment / Unit"
            >
              Address Line 2
            </TextField>
          </div>
          <Button type="submit" isLoading={isSaving}>
            Save Address
          </Button>
        </form>
      </FormContext>
    </>
  );
};

export default BillingAddressForm;
