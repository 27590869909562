import React from 'react';
import './profile.page.css';
import { useParams } from 'react-router-dom';
import DoctorProfile from '../../features/profile/doctorProfile';

const DoctorProfilePage: React.FC = () => {
  const { doctorId } = useParams<{ doctorId: string }>();

  return (
    <section className="profile-page">
      <DoctorProfile doctorId={doctorId} />
    </section>
  );
};
export default DoctorProfilePage;
