/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSessionContext } from './sessionContext';

/**
 * Route that redirects unless is authenticated
 * Once authenticated, will bring you to where you were trying to go
 * https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
 * */
export function ProtectedRoute() {
  const [sessionInfo] = useSessionContext();

  const { isAuthenticated, authenticationPath, redirectPathOnAuthentication } =
    {
      isAuthenticated: !!sessionInfo.isAuthenticated,
      authenticationPath: '/unauthenticated',
      redirectPathOnAuthentication:
        sessionInfo.redirectPathOnAuthentication || '/',
    };

  const currentLocation = useLocation();

  let redirectPath = redirectPathOnAuthentication || '/';
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  } else {
    redirectPath = currentLocation.pathname as string;
  }

  if (redirectPath !== currentLocation.pathname) {
    return <Navigate to={{ pathname: redirectPathOnAuthentication }} />;
  }
  return <Outlet />;
}
