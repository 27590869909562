/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import './patientTableItem.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BillingStatus, Patient } from '../../../models';

type PatientTableItemProps = {
  patient: Patient;
};

const AcceptedPatientTableItem: React.FC<PatientTableItemProps> = ({
  patient,
}) => {
  const { billingStatus, isCancelled } = patient.user.billing!;
  const { isDummyUser } = patient.user;
  const getTooltipText = (): string | undefined => {
    if (isDummyUser) {
      return 'This is a Demo Patient Account';
    }
    if (isCancelled) {
      return "This patient's account has been closed";
    }
    if (billingStatus === BillingStatus.FROZEN) {
      return "This patient's subscription has ended their account is no longer active";
    }
    return undefined;
  };

  const actions = (
    <>
      <Link
        to={`/patient/${patient.getId()}`}
        className="has-tooltip"
        data-tooltip="Profile"
      >
        <i className="fas fa-id-card" />
        <span className="mobile-only icon-text">Profile</span>
      </Link>
      <Link
        to={`/patient/${patient.getId()}/calendar`}
        className="has-tooltip"
        data-tooltip="Calendar"
      >
        <i className="fas fa-calendar-alt" />
        <span className="mobile-only icon-text">Calendar</span>
      </Link>
      <Link
        to={`/user/${patient.user.getId()}/notifications`}
        className="has-tooltip"
        data-tooltip="Notifications"
      >
        <i className="fas fa-bell" />
        <span className="mobile-only icon-text">Notifications</span>
      </Link>
      <Link
        to={`/patient/${patient.getId()}/analytics`}
        className="has-tooltip"
        data-tooltip="Analytics"
      >
        <i className="fas fa-chart-bar" />
        <span className="mobile-only icon-text">Analytics</span>
      </Link>
    </>
  );

  return (
    <>
      <tr
        className={classNames(
          `patient-table-item accepted-patient ${billingStatus} ${
            isCancelled ? 'cancelled' : ''
          }`
        )}
      >
        <td
          className={classNames('name', {
            'has-tooltip':
              billingStatus === BillingStatus.FROZEN ||
              isCancelled ||
              isDummyUser,
          })}
          data-tooltip={getTooltipText()}
        >
          {isDummyUser && <i className="fas fa-user-graduate" />}
          {(billingStatus === BillingStatus.FROZEN || isCancelled) && (
            <i className="warning fas fa-exclamation-circle" />
          )}

          {patient.user.getFullName()}
        </td>
        <td className="treatment-type">
          {patient.getTreatmentTypeFormatted()}
        </td>
        <td className="calendar-type capitalized">{patient.calendarType}</td>
        <td className="actions desktop-only">{actions}</td>
      </tr>
      {/* TODO: pretty crummy solution */}
      <tr className="patient-table-item mobile-only">
        <td colSpan={4}>
          <span className="actions">{actions}</span>
        </td>
      </tr>
    </>
  );
};

export default AcceptedPatientTableItem;
