import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSessionContext } from '../../shared/global/sessionContext';
import { editUser } from '../../../services/user.service';
import { initFullStory, shutdownFullStory } from '../../App';
import {
  onApiErrorToast,
  onSuccessToast,
} from '../../../services/toasts.service';
import { User } from '../../../models';
import { Checkbox } from '../../shared';

type SupportSettingsProps = {
  user: User;
};

const SupportSettings: React.FC<SupportSettingsProps> = ({ user }) => {
  const [sessionContext] = useSessionContext();
  const formContextMethods = useForm<{ allowFullStory: boolean }>({
    defaultValues: {
      allowFullStory: user.allowFullStory,
    },
  });
  const onToggleFullstory = () => {
    const newStatus = formContextMethods.getValues('allowFullStory');
    editUser(user.getId(), {
      allowFullStory: newStatus,
    })
      .then(() => {
        if (newStatus) {
          initFullStory(user);
        } else {
          shutdownFullStory();
        }
        onSuccessToast('Settings Updated!');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  return (
    <div className="more-settings-container">
      <h4>FullStory Support</h4>
      <p>
        FullStory is a debugging service that helps us track any issues you may
        run into on the application. By opting out of Full Story, our ability to
        help you resolve issues may be limited and or experience a longer delay.
        For more details on how ArtemisCalendar protects your privacy, please
        read our{' '}
        <a
          className="external-link"
          href="https://artemiscalendar.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacy policy.
        </a>
      </p>
      <FormContext {...formContextMethods}>
        <form onSubmit={formContextMethods.handleSubmit(onToggleFullstory)}>
          <Checkbox
            isChecked={formContextMethods.watch('allowFullStory')}
            inputName="allowFullStory"
            onChange={(e) => {
              formContextMethods.setValue('allowFullStory', e.target.checked);
              onToggleFullstory();
            }}
            reversed
            label="Allow FullStory Session Tracking"
          />
        </form>
      </FormContext>
      <hr />

      <p>Having technical problems with the app?</p>
      <p>
        Email us at{' '}
        <a
          href="mailto:contact@artemiscalendar.com"
          target="_blank"
          className="external-link"
          rel="noopener noreferrer"
        >
          contact@artemiscalendar.com
        </a>{' '}
        and we&apos;ll get back to you as soon as we can.
      </p>
      {sessionContext.isPatientView && (
        <>
          <h3 className="text-centered">Other</h3>
          <p>
            <Link to="/hipaa-consent" className="button text-centered">
              Review your HIPAA Consent Agreement
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default SupportSettings;
