import React, { useEffect } from 'react';
import './error-page.scss';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { setPageTitle } from '../../shared/header/header';
import { maintenanceLogo } from '../../../assets';
import { LogoBlock, Button } from '../../shared';

const StyledSection = styled.section`
  display: flex;
`;

// TODO: figure out how to use this
const ErrorPage: React.FC = () => {
  useEffect(() => {
    setPageTitle('Error');
  });

  return (
    <StyledSection className="internal-error">
      <div className="page-wrapper has-box-shadow">
        <div className="text text-left">
          <LogoBlock />
          <h3>Sorry, something went wrong.</h3>
          <p className="description">
            We&apos;re taking a look at what went wrong!
          </p>
          <p className="description">Click the button to go back.</p>
          <Link to="/">
            <Button>Back to Home</Button>
          </Link>
        </div>
        <img src={maintenanceLogo} alt="Error" />
      </div>
    </StyledSection>
  );
};

export default ErrorPage;
