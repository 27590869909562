import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../models';
import {
  toggleFeatureFlag,
  onSuccessToast,
  onApiErrorToast,
  generateResetPasswordLink,
  cancelSubscription,
  deleteAccount,
} from '../../../services';
import { downloadJSONAsCSV } from '../../../util';
import { Button, ConfirmModal } from '../../shared';
import { useSessionContext } from '../../shared/global/sessionContext';
import FeatureFlagList from './featureFlagList';
import './adminSettings.scss';

type AdminSettingsProps = {
  user: User;
  onUpdateUser: (user: User) => void;
};

const AdminSettings: React.FC<AdminSettingsProps> = ({
  user,
  onUpdateUser,
}) => {
  const [sessionContext] = useSessionContext();
  const navigate = useNavigate();
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    isLoading: false,
    link: '',
  });

  const cancelUserSubscription = () => {
    setIsCancelling(true);
    cancelSubscription(user.getId())
      .then((_periodEnd: Date) => {
        onSuccessToast(
          `Subscription will be cancelled at the end of this billing cycle.`
        );
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsCancelling(false));
  };

  const deleteUserAccount = () => {
    setIsDeleting(true);
    deleteAccount(user.getId())
      .then((logs) => {
        if (user.isPatient() && user.hasCompletedRegistration) {
          const filename = `${user.getFullName()}'s Treatment History ${moment().format(
            'YYYY-MM-DD'
          )}.csv`;

          downloadJSONAsCSV(
            logs!.logs!.map((l) => l.convertToCSVReadable()),
            logs!.fields!,
            filename
          );
        }
        onSuccessToast('User Account Deleted Successfully');
        // Move them back to home cuz this user no more
        navigate('/');
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsDeleting(false));
  };

  const toggleFeatureFlagCall = (featureFlag: string) => {
    toggleFeatureFlag({ userId: user.getId(), featureFlag })
      .then((flags) => {
        const updatedUser = user;
        updatedUser.featureFlags = flags;
        onUpdateUser(updatedUser);
        onSuccessToast('Feature Flag Changes Saved');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };
  const onClickGenerateResetPassword = () => {
    setResetPassword({
      link: '',
      isLoading: true,
    });
    generateResetPasswordLink(user.getId())
      .then(async (token) => {
        const resetURL = `${process.env.PUBLIC_URL}/reset-password?token=${token}`;
        await navigator.clipboard.writeText(resetURL);
        setResetPassword({
          link: resetURL,
          isLoading: false,
        });
        onSuccessToast('Reset Link copied to clipboard.');
      })
      .catch((error) => {
        onApiErrorToast(error);
        setResetPassword({
          link: '',
          isLoading: false,
        });
      });
  };
  if (!sessionContext.isAdmin) {
    return null;
  }
  return (
    <section className="admin-actions">
      <h3 className="text-centered">Admin Features</h3>
      <input
        type="text"
        className="is-textbox reset-password-link"
        placeholder="Reset Password Link"
        value={resetPassword.link}
        readOnly
        disabled
      />
      <Button
        className="reset-password-link"
        onClick={onClickGenerateResetPassword}
        isLoading={resetPassword.isLoading}
      >
        Generate Reset Password Link
      </Button>
      <p className="description">
        This generates a ONE TIME UNIQUE link for a user to reset their
        password. It DOES NOT send them an email. You must send them this link
        manually.
      </p>

      <FeatureFlagList
        user={user}
        onChange={(_e, flag) => {
          toggleFeatureFlagCall(flag);
        }}
      />
      {user.isPatient() && (
        <section className="delete-or-cancel">
          <div className="stacked">
            <Button
              isLoading={isDeleting}
              className="danger"
              onClick={() => setIsDeleteAccountModalOpen(true)}
            >
              DANGER: DELETE ACCOUNT
            </Button>
            <ConfirmModal
              onCancel={() => setIsDeleteAccountModalOpen(false)}
              onConfirm={() => {
                setIsDeleteAccountModalOpen(false);
                deleteUserAccount();
              }}
              isOpen={isDeleteAccountModalOpen}
              message="Are you sure you want to delete this account? This action CANNOT be reversed!"
            />
          </div>

          <div className="stacked">
            <Button
              isLoading={isCancelling}
              className="danger"
              isDisabled={
                !user.billing || user.billing?.isCancelled || user.isDummyUser
              }
              onClick={() => setIsCancelSubscriptionModalOpen(true)}
            >
              Cancel Subscription
            </Button>
            {!user.billing && (
              <p className="description">
                Cannot cancel subscription because user did not complete
                registration.
              </p>
            )}
            {user.billing?.isCancelled && (
              <p className="description">Subscription is already cancelled.</p>
            )}
            {user.isDummyUser && (
              <p className="description">
                Cannot cancel subscriptions for dummy accounts.
              </p>
            )}
            <ConfirmModal
              onCancel={() => setIsCancelSubscriptionModalOpen(false)}
              onConfirm={() => {
                setIsCancelSubscriptionModalOpen(false);
                cancelUserSubscription();
              }}
              isOpen={isCancelSubscriptionModalOpen}
              message="Are you sure you cancel this subscription? Cancelling this subscription will prevent future access but still give the user access to the application until the end of their current billing period."
            />
          </div>
        </section>
      )}
    </section>
  );
};

export default AdminSettings;
