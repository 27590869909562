import React, { useEffect } from 'react';
import './notAuthenticated.page.scss';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { unauthenticatedImage } from '../../../assets';
import { setPageTitle } from '../../shared/header/header';
import { LogoBlock, Button } from '../../shared';

const StyledSection = styled.section`
  display: flex;
`;

const NotAuthenticatedPage: React.FC = () => {
  useEffect(() => {
    setPageTitle('Unauthenticated');
  });
  // TODO: if they're authenticated, send them back to seomwhere else

  return (
    <StyledSection className="not-authenticated">
      <div className="page-wrapper has-box-shadow">
        <div className="text text-left">
          <LogoBlock />
          <h3>Please login to use the app.</h3>
          <p className="description">
            It looks like your session is expired, or you haven&apos;t logged
            in.
          </p>
          <p className="description">
            Click the button to go back to the login page.
          </p>
          <Link to="/">
            <Button>Back to Login</Button>
          </Link>
        </div>
        <img src={unauthenticatedImage} alt="Unauthenticated" />
      </div>
    </StyledSection>
  );
};
export default NotAuthenticatedPage;
