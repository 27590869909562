import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ClinicPage from './components/pages/clinic/clinic.page';
import ClinicsPage from './components/pages/clinics-page/clinics.page';
import CalendarPage from './components/pages/calendar/calendar.page';
import DashboardPage from './components/pages/dashboardPage/dashboard.page';
import NotificationsPage from './components/pages/notifications/notifications.page';
import DoctorProfilePage from './components/pages/profile-page/doctorProfile.page';
import PatientProfilePage from './components/pages/profile-page/patientProfile.page';
import ProfilePage from './components/pages/profile-page/profile.page';
import SettingsPage from './components/pages/settings/settings.page';
import UsersPage from './components/pages/users-page/users.page';
import { ProtectedRoute } from './components/shared/global';
import { useSessionContext } from './components/shared/global/sessionContext';
import LandingPage from './components/pages/landing-page/landing-page';
import ErrorPage from './components/pages/error-page/error-page';
import ForgotPasswordPage from './components/pages/forgot-password-page/forgotPassword.page';
import LoginPage from './components/pages/login-page/login-page';
import LogoutPage from './components/pages/logout/logout.page';
import NotAuthenticatedPage from './components/pages/not-authenticated/notAuthenticated.page';
import NotAuthorizedPage from './components/pages/not-authorized-page/notAuthorized.page';
import NotFoundPage from './components/pages/notFoundPage/notFound.page';
import RegisterPage from './components/pages/register/register.page';
import ResetPasswordPage from './components/pages/resetPasswordPage/resetPassword.page';
import UsersStatsPage from './components/pages/analytics/usersAnalytics.page';
import OnboardingPage from './components/pages/onboarding/onboarding.page';
import HipaaConsentPage from './components/pages/hipaaConsent/hippaConsent.page';
import PatientAnalyticsPage from './components/pages/analytics/patientAnalytics';

// Make sure we redirect them away from unprotected routes if they're logged in
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RedirectToLastPage() {
  const [sessionInfo] = useSessionContext();
  return (
    <Navigate
      to={{
        pathname: sessionInfo.redirectPathOnAuthentication || '/',
      }}
    />
  );
}

export function AppRoutes() {
  const [sessionInfo] = useSessionContext();

  /**
   * Determine what the home page view should be depending on the session
   * Also determine the redirect if need be
   */
  const getRootPage = () => {
    if (!sessionInfo.isAuthenticated) {
      return <LandingPage />;
    }
    // if (
    //   sessionInfo.redirectPathOnAuthentication &&
    //   sessionInfo.redirectPathOnAuthentication !== '/home' &&
    //   [...PAGES_NOT_REQUIRING_AUTHENTICATION].indexOf(
    //     sessionInfo.redirectPathOnAuthentication
    //   ) === -1
    // ) {
    //   return <RedirectToLastPage/>;
    // }
    /**
     * TODO: 2021/01/06 - figure this out for all urls
     *    the problem is we only want to redirect once, and if the route doesnt exist for a different view
     *     we should gracefully handle that
     */
    if (
      sessionInfo.redirectPathOnAuthentication &&
      sessionInfo.redirectPathOnAuthentication === '/billing' &&
      sessionInfo.isPatientView
    ) {
      return <RedirectToLastPage />;
    }
    if (sessionInfo.isDoctorView) {
      return <DashboardPage />;
    }
    if (sessionInfo.isPatientView) {
      if (sessionInfo.patient?.hasHipaaConsent()) {
        return <CalendarPage />;
      }
      return <HipaaConsentPage />;
    }
    if (sessionInfo.isAdmin) {
      return <UsersPage />;
    }
    return <SettingsPage />;
  };

  const getProtectedRoutes = () => {
    const routes: React.ReactElement[] = [];

    let showAuthenticatedRoutes = true;

    // TODO: might be better to move the ifs here into the protected route or something
    if (sessionInfo.isAdmin) {
      routes.push(
        <Route key="admin" path="/admin" element={<ProtectedRoute />}>
          <Route path="users" element={<UsersPage />} />
          <Route path="users/analytics" element={<UsersStatsPage />} />
          <Route path="clinics" element={<ClinicsPage />} />
        </Route>,
        <Route
          key="user-settings"
          path="/user/:userId/settings"
          element={<ProtectedRoute />}
        >
          <Route
            path="/user/:userId/settings"
            element={<SettingsPage isBilling={false} />}
          />
        </Route>,
        <Route key="doctor" path="/doctor" element={<ProtectedRoute />}>
          <Route path=":doctorId/dashboard" element={<DashboardPage />} />
        </Route>
      );
    } else if (sessionInfo.isPatientView) {
      routes.push(
        <Route key="root" path="/" element={<ProtectedRoute />}>
          <Route path="onboarding" element={<OnboardingPage />} />
          <Route path="hipaa-consent" element={<HipaaConsentPage />} />
        </Route>
      );
      if (sessionInfo.patient?.hasHipaaConsent()) {
        routes.push(
          <Route key="root" path="/" element={<ProtectedRoute />}>
            <Route path="billing" element={<SettingsPage isBilling />} />
            <Route path="calendar" element={<CalendarPage />} />
          </Route>
        );
      } else {
        showAuthenticatedRoutes = false;
      }
    } else if (sessionInfo.isDoctorView) {
      routes.push(
        <Route key="dashboard" path="/dashboard" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardPage />} />
        </Route>
      );
    }
    if (showAuthenticatedRoutes) {
      routes.push(
        /** -------------------------- CURRENT USER ROUTES -------------------------- */
        <Route key="root" path="/" element={<ProtectedRoute />}>
          <Route path="profile" element={<ProfilePage title="" />} />
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="settings" element={<SettingsPage isBilling={false} />} />
        </Route>,
        /** -------------------------- USER ROUTES -------------------------- */
        // TODO: shouldnt this only be available to providers
        <Route
          key="user-notifications"
          path="/user/:userId/notifications"
          element={<ProtectedRoute />}
        >
          <Route
            path="/user/:userId/notifications"
            element={<NotificationsPage />}
          />
        </Route>,

        /** -------------------------- CLINIC ROUTES -------------------------- */
        <Route
          key="clinic-id"
          path="/clinic/:clinicId"
          element={<ProtectedRoute />}
        >
          <Route path="/clinic/:clinicId" element={<ClinicPage />} />
        </Route>,

        /** -------------------------- PATIENT ROUTES -------------------------- */

        // TODO: shouldnt this only be available to providers
        <Route key="patient" path="/patient" element={<ProtectedRoute />}>
          <Route path=":patientId" element={<PatientProfilePage />} />
          <Route path=":patientId/calendar" element={<CalendarPage />} />
          <Route
            path=":patientId/analytics"
            element={<PatientAnalyticsPage />}
          />
        </Route>,
        /** -------------------------- DOCTOR ROUTES -------------------------- */
        <Route
          key="doctor-id"
          path="/doctor/:doctorId"
          element={<ProtectedRoute />}
        >
          <Route path="/doctor/:doctorId" element={<DoctorProfilePage />} />
        </Route>
      );
    }

    return routes;
  };

  return (
    <Routes>
      <Route path="/" element={getRootPage()} />
      <Route path="/home" element={getRootPage()} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route
        path="/forgot-password"
        element={
          sessionInfo.isAuthenticated ? (
            <RedirectToLastPage />
          ) : (
            <ForgotPasswordPage />
          )
        }
      />
      <Route
        path="/reset-password"
        element={
          sessionInfo.isAuthenticated ? (
            <RedirectToLastPage />
          ) : (
            <ResetPasswordPage />
          )
        }
      />
      <Route
        path="/login"
        element={
          sessionInfo.isAuthenticated ? <RedirectToLastPage /> : <LoginPage />
        }
      />
      <Route
        path="/register"
        element={
          sessionInfo.isAuthenticated ? (
            <RedirectToLastPage />
          ) : (
            <RegisterPage />
          )
        }
      />
      {sessionInfo.isAuthenticated && getProtectedRoutes()}
      <Route path="/unauthenticated" element={<NotAuthenticatedPage />} />
      <Route path="/unauthorized" element={<NotAuthorizedPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
