/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import './userTableItem.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { User } from '../../../models';
import { BillingStatus } from '../../../models/billing.model';

type UserTableItemProps = {
  user: User & {
    patientId?: string;
    doctorId?: string;
  };
};

export const UserTableItem: React.FC<UserTableItemProps> = ({ user }) => {
  const navigate = useNavigate();
  const billingStatus = user.billing?.billingStatus || undefined;

  const handleOnClick = () => {
    if (user.hasCompletedRegistration && !user.isAdmin()) {
      if (user.isPatient()) {
        navigate(`/patient/${user.patientId}`);
      } else if (user.isDoctor()) {
        navigate(`/doctor/${user.doctorId}`);
      }
    } else {
      navigate(`/user/${user.getId()}/settings`);
    }
  };

  const getTooltipText = (): string | undefined => {
    if (billingStatus === BillingStatus.FROZEN) {
      return 'This account has been frozen';
    }
    if (billingStatus === BillingStatus.DUNNING) {
      return 'This account is behind on payment and is in dunning.';
    }
    if (billingStatus === BillingStatus.PRECONFIRMATION) {
      return 'This user is awaiting provider approval';
    }
    if (user.billing?.isCancelled) {
      return 'This account has been cancelled';
    }
    return 'Good';
  };

  return (
    <tr className="user-table-item" onClick={handleOnClick}>
      <td className="billing-status">
        {!user.isDummyUser && (
          <span
            className={classNames(
              `indicator ${billingStatus || 'not-available'}`,
              {
                'has-tooltip':
                  !!billingStatus && billingStatus !== BillingStatus.HEALTHY,
              }
            )}
            data-tooltip={getTooltipText()}
          />
        )}
        {user.isDummyUser && <i className="fas fa-user-graduate" />}
      </td>
      <td
        className={classNames('name', {
          'has-tooltip': !user.hasCompletedRegistration,
        })}
        data-tooltip="This user has not finished their registration yet."
      >
        {!user.hasCompletedRegistration && <i className="fas fa-exclamation" />}
        {user.getFullName()}
      </td>
      <td className="email desktop-only left-align">{user.email}</td>
      <td className="user-type">{user.userType}</td>
      <td className="created-date desktop-only">
        {moment(user.createdDate).fromNow()}
      </td>
      <td className="last-login">{moment(user.lastLoginDate).fromNow()}</td>
    </tr>
  );
};
