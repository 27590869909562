/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  CollectionCount,
  PaginatedSearchRequest,
  PaginationInfo,
} from '../types';
import { ADMIN_URL } from '../util/constants';
import { User, Doctor, ProspectiveDoctor } from '../models';
import { SortDirection, UserType } from '../types/enums';
import { addPaginationToRequestQuery } from '../util/helpers';
import { Clinic } from '../models/clinic.model';
import { ProspectiveDoctorStatus } from '../models/prospectiveDoctor.model';
import { GraphData } from '../types/apiResponse.types';

export const approveDoctor = async (doctorId: string): Promise<void> => {
  await axios.post(`${ADMIN_URL}/doctor/${doctorId}/approve`);
};

export const getUnapprovedDoctors = async (): Promise<Doctor[]> => {
  const response = await axios.get(`${ADMIN_URL}/doctor/unapproved`);
  return response.data.map((d: Doctor) => new Doctor(d));
};

export type ProspectiveDoctorsSearchRequest = PaginationInfo & {
  searchTerm: string;
  includeArchived?: boolean;
};

export type UsersSearchRequest = PaginationInfo & {
  searchTerm: string;
  activeOnly?: boolean;
  onlyDummyUsers?: boolean;
};

export const getAllUsers = async ({
  sortBy = 'alphabetical',
  searchTerm = '',
  sortDirection = SortDirection.ASC,
  pageNumber = 1,
  pageSize = 10,
  activeOnly = false,
  onlyDummyUsers = false,
}: UsersSearchRequest): Promise<
  CollectionCount<
    User & {
      doctorIsApproved: boolean;
      completedRegistration: boolean;
    }
  >
> => {
  const result = await axios.get<
    CollectionCount<
      User & {
        doctorIsApproved: boolean;
        completedRegistration: boolean;
      }
    >
  >(
    `${ADMIN_URL}/users?${addPaginationToRequestQuery({
      sortBy,
      searchTerm,
      sortDirection,
      pageNumber,
      pageSize,
    })}&activeOnly=${activeOnly}&onlyDummyUsers=${onlyDummyUsers}`
  );
  const { total, results } = result.data;

  return {
    results: results.map((r) => {
      const u: any = new User(r);
      u.doctorIsApproved = r.doctorIsApproved;
      u.hasCompletedRegistration = r.completedRegistration;
      return u;
    }),
    total,
  };
};

export const getClinicsSummary = async ({
  sortBy = 'alphabetical',
  searchTerm = '',
  sortDirection = SortDirection.ASC,
  pageNumber = 1,
  pageSize = 10,
}: PaginatedSearchRequest): Promise<
  CollectionCount<Clinic & { doctorCount: number }>
> => {
  const result = await axios.get<
    CollectionCount<Clinic & { doctorCount: number }>
  >(
    `${ADMIN_URL}/clinics?${addPaginationToRequestQuery({
      sortBy,
      searchTerm,
      sortDirection,
      pageNumber,
      pageSize,
    })}`
  );
  const { total, results } = result.data;

  return {
    results: results.map((r) => {
      const c: any = new Clinic(r);
      c.doctorCount = r.doctorCount;
      return c;
    }),
    total,
  };
};

export const toggleFeatureFlag = async ({
  userId,
  featureFlag,
}: {
  userId: string;
  featureFlag: string;
}): Promise<string[]> => {
  const results = await axios.post(`${ADMIN_URL}/users/${userId}`, {
    featureFlag,
  });
  return results.data;
};

export const getTimedBasedUserAnalytics = async ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): Promise<{
  usersRegisteredOverTime: GraphData[];
  treatmentChangelogsCreatedOverTime: GraphData[];
  invitedPatientsOverTime: GraphData[];
}> => {
  const url = `startDate=${startDate}&endDate=${endDate}&type=time`;
  const results = await getUsersAnalytics(url);
  return results;
};

export const getOverallUsersAnalytics = async (): Promise<{
  howDidUsersHearAboutUs: { userType: UserType; source: string }[];
}> => {
  const url = `type=overall`;
  const results = await getUsersAnalytics(url);
  return results;
};

const getUsersAnalytics = async (queryString: string) => {
  const results = await axios.get(
    `${ADMIN_URL}/users/analytics?${queryString}`
  );
  return results.data;
};

// TODO: 2021/01/25 - this is a temporary stop gap as we deal with constant contacts being crap
export const getAllUsersForCSV = async (type: string): Promise<User[]> => {
  const results = await axios.get(`${ADMIN_URL}/users/csv?type=${type}`);
  return results.data.users.map((u: any) => new User(u));
};

export const getProspectiveDoctors = async ({
  sortBy = 'alphabetical',
  searchTerm = '',
  sortDirection = SortDirection.ASC,
  pageNumber = 1,
  pageSize = 10,
  includeArchived = false,
}: ProspectiveDoctorsSearchRequest): Promise<
  CollectionCount<ProspectiveDoctor>
> => {
  const result = await axios.get<CollectionCount<ProspectiveDoctor>>(
    `${ADMIN_URL}/doctor/prospects?${addPaginationToRequestQuery({
      sortBy,
      searchTerm,
      sortDirection,
      pageNumber,
      pageSize,
    })}&includeArchived=${includeArchived}`
  );
  const { total, results } = result.data;

  return {
    results: results.map((r: any) => new ProspectiveDoctor(r)),
    total,
  };
};

export const updateProspectiveDoctorStatus = async ({
  prospectId,
  status,
}: {
  prospectId: number;
  status: ProspectiveDoctorStatus;
}): Promise<void> => {
  const results = await axios.post(
    `${ADMIN_URL}/doctor/prospects/${prospectId}`,
    {
      status,
    }
  );
  return results.data;
};

export const getPatientsOfProspectiveDoctors = async (): Promise<
  {
    doctorFirstName: string;
    doctorLastName: string;
    firstName: string;
    lastName: string;
    id: string;
  }[]
> => {
  const results = await axios.get(`${ADMIN_URL}/patients/prospects`);
  return results.data.patients;
};

export const generateResetPasswordLink = async (
  userId: string
): Promise<string> => {
  const results = await axios.post(
    `${ADMIN_URL}/users/${userId}/reset-password-link`
  );
  return results.data.token;
};
