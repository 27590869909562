import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FormContext, useForm } from 'react-hook-form';
import { setPageTitle } from '../../shared/header/header';
import { useQuery } from '../../../util/helpers';
import AddCreditCardForm from '../billing/addCreditCardForm';
import PaymentMethodBlock from '../billing/paymentMethodBlock';
import {
  updateDefaultPaymentMethodForCustomer,
  applyPromoCode,
  restartSubscription,
  renewSubscription,
  getBillingSummary,
  onSuccessToast,
  onApiErrorToast,
} from '../../../services';
import './billingSettings.scss';
import ValidatePromoCode from '../billing/validatePromoCode';
import { useSessionContext } from '../../shared/global/sessionContext';
import { BillingStatus, User } from '../../../models';
import { useAppContext } from '../../shared/global/appContext';
import BillingAddressForm from '../billing/billingAddressForm';
import { Button, Loader } from '../../shared';
import { CancelOrDeleteAccount } from './cancelOrDeleteAccount';

type BillingSettingsProps = {
  user: User;
};

const BillingSettings: React.FC<BillingSettingsProps> = ({ user }) => {
  useEffect(() => {
    setPageTitle('Billing');
  });

  const methods = useForm<{ promoCode: string }>();

  const paymentStatus = useQuery().get('intent');
  const checkoutSessionId = useQuery().get('session_id');
  const navigate = useNavigate();
  const [billingInfo, setBillingInfo] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [sessionInfo] = useSessionContext();
  const [isLoadingRestartSubscription, setIsLoadingRestartSubscription] =
    useState(false);
  const [isRenewingSubscription, setIsRenewingSubscription] = useState(false);
  const { resetAppState } = useAppContext();

  // Clear the query params so the sessionID does not remain visible after a successful/cancelled payment
  const clearQueryParams = () => {
    navigate({ search: '' });
  };

  const renewUserSubscription = () => {
    setIsRenewingSubscription(true);
    renewSubscription()
      .then(() => {
        onSuccessToast('Subscription Renewed!');
        getBillingInfo();
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsRenewingSubscription(false));
  };

  const onUpdateDefaultPaymentMethod = (billingData: Record<string, any>) => {
    if (!billingData?.hasDefaultPaymentMethod) {
      updateDefaultPaymentMethodForCustomer(checkoutSessionId!).then(
        ({ paymentMethod }) => {
          onSuccessToast('Default Payment Method Updated!');
          // Do this update here so we dont need to make another fetch to the DB to get the updated info
          billingData.paymentMethods.forEach((pm: Record<string, any>) => {
            if (pm.id === paymentMethod) {
              // eslint-disable-next-line no-param-reassign
              pm.isDefault = true;
            }
          });
          setBillingInfo({ ...billingData, hasDefaultPaymentMethod: true });
        }
      );
    }
  };
  const getBillingInfo = () => {
    getBillingSummary()
      .then((info) => {
        setBillingInfo(info);
        if (paymentStatus === 'success') {
          onSuccessToast('New Card added Succesfully!');
          onUpdateDefaultPaymentMethod(info);
        }
        clearQueryParams();
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  const pricePerMonth = billingInfo?.planDetails.hasStarted
    ? `$${billingInfo ? (billingInfo.planDetails.price / 100).toFixed(2) : 'N/A'
    } ${(
      billingInfo?.planDetails.currency as string
    )?.toLocaleUpperCase()} per month`
    : null;

  let highlightBlock;
  if (billingInfo?.isCancelled) {
    highlightBlock = (
      <>
        <p className="action-required">
          <i className="fas fa-times" /> Your account has been cancelled.
          Restart your paid subscription to get back full access to the app.
          Restarting your subscription will trigger a payment being made.
        </p>
        {!billingInfo.hasDefaultPaymentMethod && (
          <p className="action-required">
            <i className="fas fa-times" /> A Default Payment Method is required
            to restart your subscription. Add one below to get started.
          </p>
        )}

        {billingInfo.hasDefaultPaymentMethod && (
          <Button
            className="is-primary"
            isLoading={isLoadingRestartSubscription}
            onClick={() => {
              setIsLoadingRestartSubscription(true);
              restartSubscription()
                .then(() => {
                  onSuccessToast('Your subscription has been restarted!');
                  resetAppState();
                  const newData = {
                    ...billingInfo,
                    billingStatus: BillingStatus.HEALTHY,
                  };
                  setBillingInfo(newData);
                })
                .catch((error) => {
                  onApiErrorToast(error);
                })
                .finally(() => {
                  setIsLoadingRestartSubscription(false);
                });
            }}
          >
            Restart Your Subscription
          </Button>
        )}
      </>
    );
  } else if (billingInfo?.planDetails.status === 'trialing') {
    highlightBlock = (
      <p className="info-blurb">
        <i className="fas fa-info" /> You are on a trial until{' '}
        {moment
          .utc(billingInfo.planDetails.trialEnd * 1000)
          .format('MMMM DD, YYYY')}
        . After this date,{' '}
        {billingInfo.planDetails.cancelAtPeriodEnd
          ? 'your subscription will be cancelled'
          : `your pricing will change to ${pricePerMonth}`}
        .
      </p>
    );
  } else if (billingInfo?.planDetails.cancelAtPeriodEnd) {
    highlightBlock = (
      <>
        <p className="action-required">
          <i className="fas fa-times" /> After{' '}
          {billingInfo
            ? moment(billingInfo.planDetails.currentPeriodEnd * 1000).format(
              'MMMM DD, YYYY'
            )
            : 'N/A'}{' '}
          your subscription will be cancelled.
        </p>
        <Button
          isDisabled={isRenewingSubscription}
          onClick={renewUserSubscription}
        >
          Renew Subscription
        </Button>
      </>
    );
  }

  return (
    <section className="billing">
      {billingInfo === undefined && <Loader />}

      {billingInfo && (
        <>
          <section className="plan-summary">
            <h4>Plan Details</h4>
            {billingInfo !== undefined &&
              !billingInfo?.planDetails.hasStarted &&
              !billingInfo?.isCancelled && (
                <p className="info-blurb">
                  <i className="fas fa-info" />{' '}
                  {sessionInfo.isSelfManagedView
                    ? 'Your Trial will end at the beginning of your next billing period'
                    : 'Your Trial period will officially start once your provider approves your request for treatment.'}
                </p>
              )}
            {billingInfo.planDetails.hasStarted && (
              <div className="details">
                <p className="part">
                  <span className="name">Plan</span>
                  <span className="value">Patient Standard</span>
                </p>
                <p className="part">
                  <span className="name">Price</span>
                  <span className="value">{pricePerMonth}</span>
                </p>
                <p className="part">
                  <span className="name">Next Payment Date</span>
                  <span className="value">
                    {billingInfo.planDetails.cancel_at_period_end
                      ? 'N/A'
                      : moment
                        .utc(billingInfo.planDetails.currentPeriodEnd * 1000)
                        .format('MMMM DD, YYYY')}
                  </span>
                </p>
              </div>
            )}
            {highlightBlock}
            <a className="button" href={billingInfo.customerPortalLink}>
              Manage Billing and Payments
            </a>
          </section>
          <hr />
          <section className="payment-methods">
            <h4>Payment Methods</h4>
            {!billingInfo.paymentMethods.length && (
              <p className="action-required">
                <i className="fas fa-times" /> You do not have a credit card on
                file!
              </p>
            )}
            {!!billingInfo.paymentMethods.length &&
              !billingInfo.hasDefaultPaymentMethod && (
                <p className="action-required">
                  <i className="fas fa-times" /> You don&apos;t have a default
                  credit card set. Add a new card, or click{' '}
                  <a
                    className="external-link"
                    href={billingInfo.customerPortalLink}
                  >
                    here
                  </a>{' '}
                  to set a default card now.
                </p>
              )}
            <div className="payment-methods-container">
              {billingInfo.paymentMethods.map((pm: any) => (
                <PaymentMethodBlock paymentMethod={pm} key={pm.id} />
              ))}
            </div>

            <AddCreditCardForm />
          </section>
          <hr />
          <section className="billing-address">
            <h4>Billing Address</h4>
            <br />
            <BillingAddressForm address={billingInfo.billingAddress || {}} />
          </section>
          <hr />
          {billingInfo.customerPortalLink && (
            <section className="other">
              {sessionInfo.user.billing?.billingStatus ===
                BillingStatus.HEALTHY &&
                !billingInfo.planDetails.cancelAtPeriodEnd && (
                  <FormContext {...methods}>
                    <form
                      className="promo-code-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <ValidatePromoCode
                        onApplyPromoCode={(code) => {
                          applyPromoCode(code)
                            .then(() => {
                              onSuccessToast('Promotion applied!');
                            })
                            .catch((error) => {
                              onApiErrorToast(error);
                            });
                        }}
                      />
                    </form>
                    <hr />
                  </FormContext>
                )}
            </section>
          )}
          <CancelOrDeleteAccount
            user={user}
            billingInfo={billingInfo}
            onCancelAccount={getBillingInfo}
          />
        </>
      )}
    </section>
  );
};

export default BillingSettings;
