/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorPage from '../../pages/error-page/error-page';
import { withSession } from '../global';
import { withSessionProp } from '../global/withSession';

type ErrorBoundaryState = {
  hasError: boolean;
};

class errorBoundary extends React.Component<
  withSessionProp,
  ErrorBoundaryState
> {
  constructor(props: withSessionProp) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: any): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // TODO: figure out the types here
    console.error(errorInfo);
    Sentry.captureException(error);
    if (errorInfo?.componentStack) {
      Sentry.captureMessage(errorInfo.componentStack);
    }
    Sentry.captureMessage(`Error Boundary: ${error.message}`);
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export const ErrorBoundary = withSession(errorBoundary);
