import React, { useEffect } from 'react';
import { TitleBlock, Button } from '..';
import './modal.scss';

export type ModalProps = {
  title: string;
  isOpen: boolean;
  containerClassName?: string;
  onClose: () => void;
  onBack?: () => void; // In case we want specific back navigation if the modal is multi paged
};
// TODO: when modal is open remove scrolling  in the main page
export const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  isOpen,
  containerClassName,
  title,
  onBack,
}) => {
  const onOutsideClick = (e: any) => {
    if (isOpen && !e.target.closest('.modal-content')) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener('click', onOutsideClick);
    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }
  return (
    <section className={`modal-container ${containerClassName}`}>
      <div className="modal-background" />
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <header>
          <TitleBlock title={title} />
          <Button
            className="close is-icon has-tooltip"
            onClick={onClose}
            tooltipText="Close modal"
          >
            <i className="fas fa-times" />
          </Button>
        </header>
        <div className="modal-body">{children}</div>
        {onBack && (
          <footer>
            <Button onClick={onBack}>Back</Button>
          </footer>
        )}
      </div>
    </section>
  );
};
