import React from 'react';
import './calendarSetup.scss';
import moment from 'moment-timezone';
import { TreatmentLog } from '../../../models';
import { Treatment } from '../../../types';
import { uuidv4 } from '../../../util/helpers';
import { withSession } from '../../shared/global';
import { withSessionProp } from '../../shared/global/withSession';
import './calendarDayListItem.scss';
// eslint-disable-next-line import/order
import classNames from 'classnames';
import { useSessionContext } from '../../shared/global/sessionContext';
import { RATING_PULSE_VALUES } from '../../../util';

type CalendarDayListItemProps = {
  treatmentLog: TreatmentLog;
  dayNumber: number;
  onClick: (log: TreatmentLog) => void;
};

const CalendarDayListItem: React.FC<
  CalendarDayListItemProps & withSessionProp
> = ({ treatmentLog: log, session: [sessionInfo], onClick, dayNumber }) => {
  const {
    cycleDayNumber,
    isTemporaryLog,
    hasBloodDrawAppointment,
    isExpectedBleedPeriod,
  } = log;

  const [sessionContext] = useSessionContext();

  const todaysDate = moment().startOf('day');

  // TODO: is there a more efficient way
  const isSameMonth = todaysDate.month() === moment.utc(log.date).month();
  const isSameYear = todaysDate.year() === moment.utc(log.date).year();
  const todaysNumDate = +todaysDate.format('D');

  const treatments = log
    .sortTreatments()
    .filter((t) => t.frequency > 0)
    .map((l: Treatment) => (
      <p className={`treatment ${l.substance}`} key={uuidv4()}>
        <strong className="substance" data-substance={l.substance}>
          {l.substance}({l.frequencyUnit})
        </strong>
        <span className="dosage">
          <strong>{l.dosage}</strong> {l.dosageUnit}
        </span>
        <span className="frequency">
          <strong>{l.frequency}</strong>x/{l.frequencyUnit}
        </span>
      </p>
    ));

  const emoji = RATING_PULSE_VALUES.find(([number]) => number === log.pulse);

  return (
    <div
      className={classNames('calendar-list-item has-box-shadow', {
        'past-day': todaysDate.isAfter(moment.utc(log.date)),
        'frozen-day': log.isFrozen,
        today: todaysNumDate === dayNumber && isSameMonth && isSameYear,
      })}
      onClick={() => {
        if (!log.isFrozen) {
          onClick(log);
        }
      }}
    >
      <div
        className={classNames('overview', {
          today: todaysNumDate === dayNumber && isSameMonth && isSameYear,
        })}
      >
        <strong className="date">
          {moment.utc(log.date).format('dddd, MMMM D, YYYY')}
        </strong>
        {!log.isFrozen && (
          <strong className="sub-details">
            {log.calendarType} Calendar, Cycle Day{' '}
            {cycleDayNumber === 0 ? '1*' : cycleDayNumber}
          </strong>
        )}
      </div>

      <div className="treatments">{treatments}</div>
      {log.isFrozen && (
        <div className="frozen-icon-container">
          <i className="fas fa-lock" />
        </div>
      )}
      <div className="additional-info">
        {!!log.symptoms.length && (
          <span className="block">
            <span className="color-code symptom">
              <i className="fas fa-exclamation" />
            </span>
            <span className="text">Symptom</span>
          </span>
        )}
        {log.hasPeriod() && (
          <span className="block">
            <span className="color-code period">
              <i className="fas fa-tint" />
            </span>
            <span className="text">Period</span>
          </span>
        )}
        {isExpectedBleedPeriod && (
          <span className="block">
            <span className="color-code expected-period">
              <i className="fas fa-tint" />
            </span>
            <span className="text">Expected Bleed</span>
          </span>
        )}
        {(log.patientNotes || log.doctorNotes) && (
          <span className="block">
            <span className="color-code">
              <i className="fa fa-sticky-note note" />
            </span>
            <span className="text">
              {sessionContext.isSelfManagedView
                ? 'Your Notes'
                : 'Provider/Patient Note'}
            </span>
          </span>
        )}
        {!sessionInfo.isPatientView && log.hasOneTimeDosageChanges && (
          <span className="block">
            <span className="color-code">
              <i className="fa fa-bolt one-time-change" />
            </span>
            <span className="text">Single Dosage Change</span>
          </span>
        )}
        {hasBloodDrawAppointment && (
          <span className="block">
            <span className="color-code">
              <i className="fas fa-syringe blood-draw" />
            </span>
            <span className="text">Blood Draw</span>
          </span>
        )}
        {!isTemporaryLog && (
          <span className="block">
            <span className="color-code">
              <i className="fas fa-save saved-log" />
            </span>
            <span className="text">Saved Log</span>
          </span>
        )}
        {!!log.pulse && emoji && (
          <span className="block">
            <i className={`${emoji[1]}`} /> {emoji[2]}
          </span>
        )}
      </div>
    </div>
  );
};

export default withSession(CalendarDayListItem);
