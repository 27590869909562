import React from 'react';
import moment from 'moment-timezone';
import './calendar.page.css';
// eslint-disable-next-line import/no-unresolved
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Calendar from '../../features/calendar/calendar';
import {
  getTreatmentHistory,
  editTreatmentLog,
  saveTreatmentLog,
} from '../../../services';
import { TreatmentLogDosageDelta } from '../../../types';
import TreatmentLogView from '../../features/calendar/treatmentLog';
import {
  withSessionProp,
  withSession,
  withAppContext,
  withAppContextProps,
} from '../../shared/global';
import {
  onSuccessToast,
  onApiErrorToast,
  onErrorToast,
} from '../../../services/toasts.service';
import CalendarSetup from '../../features/calendar/calendarSetup';
import { getTreatmentLog, getPatient } from '../../../services/patient.service';
import { Patient, PatientStatus, TreatmentLog } from '../../../models';
import { setPageTitle } from '../../shared/header/header';
import { updateCalendarSettings } from '../../../services/doctor.service';
import { CalendarSetupForm } from '../../../types/form.types';
import { PAGE_NOT_FOUND, PAGE_UNAUTHORIZED } from '../../../util/constants';
import { Loader, Modal } from '../../shared';
import { PatientQuickNav } from '../../features/patient/patientQuickNav';
import {
  withNavigation,
  withNavigationProp,
} from '../../shared/global/withNavigation';

type CalendarPageState = {
  currentDisplayMonth: moment.Moment;
  treatmentLogs: TreatmentLog[];
  selectedTreatment?: TreatmentLog;
  isModalOpen: boolean;
  patient: Patient;
  showEditCalendarSettings: boolean;
  isLoading: boolean;
  isSavingLog: boolean;
};

class CalendarPage extends React.Component<
  withSessionProp &
    withNavigationProp & { patientId: string } & withAppContextProps,
  CalendarPageState
> {
  constructor(
    props: withSessionProp &
      withNavigationProp &
      withAppContextProps & { patientId: string }
  ) {
    super(props);
    this.state = {
      currentDisplayMonth: moment(),
      treatmentLogs: [],
      isModalOpen: false,
      patient: new Patient(),
      showEditCalendarSettings: false,
      isLoading: true,
      isSavingLog: false,
    };
    this.prevMonth = this.prevMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.saveOrEditTreatmentLog = this.saveOrEditTreatmentLog.bind(this);
    this.onSelectCalendarDay = this.onSelectCalendarDay.bind(this);
    this.onEditCalendarSettings = this.onEditCalendarSettings.bind(this);
    this.onChangeCalendarType = this.onChangeCalendarType.bind(this);
    this.onModalBack = this.onModalBack.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.loadPatientData(true);
  }

  async onGetAllPatientInfo(
    patient: Patient,
    isFirstLoad = false
  ): Promise<void> {
    const { treatmentLogs } = await this.getHistoryForMonth();

    // eslint-disable-next-line react/destructuring-assignment
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamsLogId = queryParams.get('log');
    // TODO: need to deal with if log isnt a number or some random crap
    // From notifications, directly going to a treatmentLog view
    let matchedTreatmentLog;
    if (queryParamsLogId && isFirstLoad) {
      matchedTreatmentLog = treatmentLogs.find(
        (log) => log.getId() === queryParamsLogId!
      );
      matchedTreatmentLog = await this.getMissingTreatmentLog(
        queryParamsLogId as string
      );
    }

    this.setState({
      treatmentLogs,
      isLoading: false,
      showEditCalendarSettings: false,
      selectedTreatment: matchedTreatmentLog,
      isModalOpen: !!matchedTreatmentLog,
    });
    const { params } = this.props;
    if (params.patientId) {
      setPageTitle(`${patient.user.getFullName()}'s Calendar`);
    }
  }

  async loadPatientData(isFirstLoad = false): Promise<void> {
    const {
      session: [sessionInfo],
      params,
    } = this.props;
    //  TODO: some sketchiness here
    const patientId = params.patientId || sessionInfo.patient!.getId();
    // TODO: 2023-03-01 Why did i make this two calls?
    return getPatient(patientId)
      .then((patient) => {
        this.setState({ patient }, async () => {
          await this.onGetAllPatientInfo(patient, isFirstLoad);
        });
      })
      .catch((error) => {
        const { navigate } = this.props;
        if (error.response?.status === 404) {
          onErrorToast('That patient does not exist.');
          navigate(PAGE_NOT_FOUND);
        } else if (error.response?.status === 403) {
          navigate(PAGE_UNAUTHORIZED);
        } else {
          onApiErrorToast(error);
        }
      });
  }

  /**
   * Specifically used when we want to go directly to a treatment log, when they've clicked from a notification
   * @param treatmentLogId
   */
  async getMissingTreatmentLog(treatmentLogId: string): Promise<TreatmentLog> {
    const { patient } = this.state;
    const treatmentLog = await getTreatmentLog(patient.getId(), treatmentLogId);
    return treatmentLog;
  }

  async onEditCalendarSettings(values: CalendarSetupForm) {
    const { patient } = this.state;
    const {
      session: [sessionInfo],
    } = this.props;

    const doctorId = sessionInfo.isSelfManagedView
      ? patient?.doctorId
      : sessionInfo.doctor!.getId();
    this.setState({ isSavingLog: true });
    updateCalendarSettings(doctorId!, patient.getId(), values)
      .then(async () => {
        onSuccessToast('Calendar Type Changed!');
        await this.loadPatientData();
        this.setState({
          isModalOpen: false,
        });
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => this.setState({ isSavingLog: false }));
  }

  async getHistoryForMonth(): Promise<{
    treatmentLogs: TreatmentLog[];
  }> {
    const { navigate } = this.props;
    const { patient, currentDisplayMonth } = this.state;

    const { treatmentLogs } = await getTreatmentHistory(
      patient.getId(),
      currentDisplayMonth.format('YYYY-MM-DD')
    ).catch((error) => {
      onApiErrorToast(error);
      navigate('/error');
      return { treatmentLogs: [] };
    });

    return {
      treatmentLogs,
    };
  }

  /**
   *
   * @param treatment
   * @param dosageDeltas
   */
  async saveOrEditTreatmentLog(
    treatment: TreatmentLog,
    dosageDeltas: TreatmentLogDosageDelta[]
  ): Promise<void> {
    const { patient } = this.state;
    const {
      session: [sessionInfo],
    } = this.props;

    // As soon as a patient saves the log, it becomes a permanent log
    // UNLESS WERE TALKING ABOUT SELF MANAGED
    if (sessionInfo.isPatientView) {
      // Basically, if self managed, and thetyre editing future logs for dosing or whatever, dont make it the permanent log
      // eslint-disable-next-line no-param-reassign
      treatment.isTemporaryLog =
        sessionInfo.isSelfManagedView && new Date(treatment.date) > new Date();
    }

    // eslint-disable-next-line no-param-reassign
    treatment = Object.assign(new TreatmentLog(), treatment);

    // Make sure we're not sending empty keys
    if (!treatment.getId()) {
      treatment.removeEmptyKey();
    }
    const requestData = TreatmentLog.removeFrontEndOnlyKeys(treatment) as any;

    const dosageDeltasToSave =
      sessionInfo.isDoctorView || sessionInfo.isSelfManagedView
        ? dosageDeltas
        : undefined;
    (requestData.getId()
      ? editTreatmentLog(
          patient.getId(),
          requestData.getId(),
          requestData,
          dosageDeltasToSave
        )
      : saveTreatmentLog(patient.getId(), requestData, dosageDeltasToSave)
    )
      .then(async () => {
        await this.loadPatientData(false);
        onSuccessToast('Changes saved!');
      })
      .catch((error: any) => {
        if (error.response.status === 409) {
          // TODO: should reload the calendar
        }

        onApiErrorToast(error);
      });
  }

  /**
   * Basically retrieves the new information for the new month
   */
  changeMonthCallback(): void {
    this.getHistoryForMonth().then(({ treatmentLogs }) => {
      this.setState({
        treatmentLogs,
      });
    });
  }

  prevMonth(): void {
    const { currentDisplayMonth } = this.state;
    this.setState(
      {
        currentDisplayMonth: currentDisplayMonth.subtract(1, 'month'),
        treatmentLogs: [],
      },
      this.changeMonthCallback
    );
  }

  nextMonth(): void {
    const { currentDisplayMonth } = this.state;
    this.setState(
      {
        currentDisplayMonth: currentDisplayMonth.add(1, 'month'),
        treatmentLogs: [],
      },
      this.changeMonthCallback
    );
  }

  onSelectCalendarDay(treatmentLog: TreatmentLog): void {
    this.setState({
      selectedTreatment: treatmentLog,
      isModalOpen: true,
    });
  }

  onClose = () => {
    this.setState({ isModalOpen: false, showEditCalendarSettings: false });
  };

  onModalBack = () => {
    const { selectedTreatment, showEditCalendarSettings } = this.state;
    if (showEditCalendarSettings && selectedTreatment) {
      this.setState({
        showEditCalendarSettings: false,
      });
    } else {
      this.onClose();
    }
  };

  onChangeCalendarType() {
    this.setState({ showEditCalendarSettings: true });
  }

  render() {
    const {
      currentDisplayMonth,
      treatmentLogs,
      selectedTreatment,
      isModalOpen,
      patient,
      showEditCalendarSettings,
      isLoading,
      isSavingLog,
    } = this.state;

    const {
      session: [sessionInfo],
    } = this.props;

    // We need the patient to at least be loaded to show the right information
    if (!patient.getId()) {
      return <Loader />;
    }

    // Patients who have yet to be accpted by a doctor are unable to interact with their calendar
    const canViewCalendar =
      sessionInfo.isSelfManagedView ||
      (sessionInfo.isPatientView &&
        patient.getPatientStatus() === PatientStatus.ACCEPTED) ||
      sessionInfo.isDoctorView ||
      sessionInfo.isAdmin;

    const loadedView = (
      <>
        {!canViewCalendar ? (
          <h3 className="restricted-view text-centered">
            {patient.getPatientStatus() === PatientStatus.PENDING && (
              <>
                <p>
                  Before you can get started using your calendar, your provider
                  needs to review your information.
                </p>
                <p>
                  We have notified your chosen provider of your request and we
                  will email you when they&apos;ve reviewed your information!
                </p>
              </>
            )}
            {patient.getPatientStatus() === PatientStatus.REJECTED && (
              <>
                <p>Your patient request has been rejected by your Provider.</p>
                <br />
                <Link className="button" to="/profile">
                  Request a new Provider
                </Link>
              </>
            )}
          </h3>
        ) : (
          <Calendar
            onClick={this.onSelectCalendarDay}
            prevMonth={this.prevMonth}
            nextMonth={this.nextMonth}
            currentDisplayMonth={currentDisplayMonth}
            treatmentLogs={treatmentLogs}
            patient={patient}
          />
        )}
        {(selectedTreatment || showEditCalendarSettings) && (
          <Modal
            isOpen={isModalOpen}
            onClose={this.onClose}
            onBack={this.onModalBack}
            title={
              showEditCalendarSettings
                ? 'Edit Calendar Settings'
                : 'Treatment Log'
            }
          >
            {showEditCalendarSettings && (
              <CalendarSetup
                onSubmit={this.onEditCalendarSettings}
                patient={patient}
                isSaving={isSavingLog}
              />
            )}
            {selectedTreatment && !showEditCalendarSettings && (
              <TreatmentLogView
                treatmentLog={selectedTreatment}
                onSave={this.saveOrEditTreatmentLog}
                onChangeCalendarType={this.onChangeCalendarType}
                // TODO: its probably a cheat to do this, but whatever
                onResetCycleDayNumber={(log: TreatmentLog) =>
                  this.saveOrEditTreatmentLog(log, [])
                }
                patient={patient}
              />
            )}
          </Modal>
        )}
      </>
    );

    return (
      <section className="calendar-page">
        {!isLoading && !sessionInfo.isPatientView && (
          <div className="page-wrapper">
            <PatientQuickNav patient={patient} />
          </div>
        )}
        <div className={classNames('page-wrapper')}>
          {isLoading ? <Loader /> : loadedView}
        </div>
      </section>
    );
  }
}

export default withSession(withNavigation(withAppContext(CalendarPage)));
