import React from 'react';
import { SessionContext } from './sessionContext';
import { ElixirSession } from '../../../models';

export type withSessionProp = {
  session: [ElixirSession, (sess: ElixirSession) => void];
};

export function withSession(Component: any) {
  return function SessionComponent(props: any) {
    return (
      <SessionContext.Consumer>
        {(session) => <Component {...props} session={session} />}
      </SessionContext.Consumer>
    );
  };
}
