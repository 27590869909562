import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectOption, RegisterPatientData } from '../../../types/form.types';

import './register-form.scss';
import { FORM_VALIDATION } from '../../../util';
import { countries, states } from '../../../assets';
import { TextField, Dropdown } from '../../shared';

// react-select.com/async
const countryOptions: SelectOption<string>[] = countries.map(
  (c: { country: string; code: string }) => ({
    value: c.country,
    label: c.country,
  })
);

const stateOptions: SelectOption<string>[] = states.map((state: string) => {
  return {
    value: state,
    label: state,
  };
});

// TODO: the error states for selects not working
const ProspectiveDoctorForm: React.FC = () => {
  const { watch } = useFormContext<RegisterPatientData>();

  return (
    <>
      <h3>Tell Us About Your Provider</h3>

      <p>
        Fill in any information you know for your provider and we will reach out
        directly.{' '}
      </p>
      <div className="form-group half-width">
        <TextField
          name="prospectiveDoctorFirstName"
          placeholder="John"
          required
          min={1}
          max={80}
          validationOptions={{
            min: 1,
            maxLength: 80,
            required: 'Required',
          }}
        >
          First Name
        </TextField>
      </div>
      <div className="form-group half-width">
        <TextField
          name="prospectiveDoctorLastName"
          placeholder="Doe"
          min={1}
          max={80}
          validationOptions={{
            min: 1,
            maxLength: 80,
          }}
        >
          Last Name
        </TextField>
      </div>
      <div className="form-group half-width">
        <TextField name="prospectiveDoctorClinicName" placeholder="Clinic Name">
          Clinic
        </TextField>
      </div>
      <div className="form-group half-width">
        <TextField name="prospectiveDoctorCity" placeholder="City">
          City
        </TextField>
      </div>
      <div className="form-group half-width">
        <Dropdown
          name="prospectiveDoctorCountry"
          options={countryOptions}
          required
          validationOptions={{
            required: 'Required',
          }}
        >
          Country
        </Dropdown>
      </div>

      <div className="form-group half-width">
        {(watch('prospectiveDoctorCountry') as unknown as SelectOption<string>)
          ?.value === 'United States' && (
          <Dropdown name="prospectiveDoctorState" options={stateOptions}>
            State
          </Dropdown>
        )}
        {(watch('prospectiveDoctorCountry') as unknown as SelectOption<string>)
          ?.value !== 'United States' && (
          <TextField name="prospectiveDoctorState" placeholder="State">
            State/Province
          </TextField>
        )}
      </div>
      <div className="form-group half-width">
        <TextField
          name="prospectiveDoctorTelephone"
          validationOptions={{
            pattern: {
              value: FORM_VALIDATION.VALID_PHONE_NUMBER,
              message: 'invalid phone number',
            },
          }}
          placeholder="Provider/Clinic Number"
        >
          Telephone
        </TextField>
      </div>
      <div className="form-group half-width">
        <TextField
          name="prospectiveDoctorEmail"
          type="email"
          placeholder="Provider/Clinic Email"
          validationOptions={{
            pattern: {
              value: FORM_VALIDATION.VALID_EMAIL,
              message: 'invalid email address',
            },
          }}
        >
          Email
        </TextField>
      </div>
    </>
  );
};

export default ProspectiveDoctorForm;
