import React from 'react';
import {
  Controller,
  ErrorMessage,
  useFormContext,
  ValidationOptions,
} from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import Select from 'react-select';
import { SelectOption } from '../../../../types/form.types';
import './dropdown.scss';

export type DropdownProps = {
  name: string;
  options: SelectOption<string | number>[];
  disabled?: boolean;
  required?: boolean;
  initialValue?: string | number;
  placeholder?: string;
  validationOptions?: ValidationOptions;
  isCreatable?: boolean;
  isMulti?: boolean;
  className?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
};

export const Dropdown: React.FC<DropdownProps> = ({
  name,
  required = false,
  children,
  options = [],
  initialValue,
  placeholder,
  validationOptions,
  disabled = false,
  isCreatable = false,
  isMulti = false,
  menuPlacement = 'auto',
  className = '',
}) => {
  const { errors, control } = useFormContext();
  const SelectComponent: any = isCreatable ? CreatableSelect : Select;

  return (
    <label
      className={classNames(`${className} dropdown`, {
        required,
      })}
      htmlFor={name}
    >
      {required && <span className="asterisk">*</span>}
      {children}
      <Controller
        as={
          <SelectComponent
            classNamePrefix="react-select"
            className="react-select-container"
            options={options}
          />
        }
        isDisabled={disabled}
        placeholder={placeholder}
        rules={validationOptions}
        control={control}
        defaultValue={options.find((option) => option.value === initialValue)}
        onChange={([selected]) => selected}
        name={name}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        menuPlacement={menuPlacement}
      />
      {errors[name] && (
        <span className="error">
          <ErrorMessage errors={errors} name={name}>
            {({ message }) => message}
          </ErrorMessage>
        </span>
      )}
    </label>
  );
};
