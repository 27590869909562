import React from 'react';

type SVGProps = {
  height: number;
  width: number;
  label: string;
  iconName: string;
  style?: Record<string, any>;
};

export function SVGIcon({ height, width, label, iconName, style }: SVGProps) {
  return (
    <svg
      height={height}
      width={width}
      role="img"
      aria-label={label}
      style={style}
    >
      <use xlinkHref={`#${iconName}`} />
    </svg>
  );
}
