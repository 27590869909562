import React from 'react';
import ReactDOM from 'react-dom';

// Global CSS
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr/_internal';
import App from './components/App';
// TODO: service worker setup
import * as serviceWorker from './serviceWorker';

import { momentTimezones } from './assets';
import {
  AppContextProvider,
  SessionContextProvider,
} from './components/shared/global';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME,
    release: `artemis-calendar-website@${process.env.npm_package_version}`,
    environment: process.env.REACT_APP_ENV,
    autoSessionTracking: false,
  });
}

// Enable sending authorization cookies
axios.defaults.withCredentials = true;

// Load in Timezones for moment
// TODO: this probably can be shrunk because we dont need ALL The timezones
moment.tz.add(momentTimezones);
// Cache browser timezone for future access
// https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
moment.tz.guess();
ReactDOM.render(
  <BrowserRouter basename="/">
    <SWRConfig>
      <SessionContextProvider>
        <AppContextProvider>
          <ThemeProvider theme={{}}>
            <App />
          </ThemeProvider>
        </AppContextProvider>
      </SessionContextProvider>
    </SWRConfig>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
