/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import React from 'react';
import './banner.css';

export type BannerProps = {
  children?: any;
  icon?: string;
  className?: string;
  isWarning?: boolean;
  onClick?: () => void;
  isSuccess?: boolean;
  onClose?: () => void;
  isFixed?: boolean;
};

export const Banner: React.FC<BannerProps> = ({
  children,
  icon,
  className,
  isWarning,
  onClick,
  isSuccess,
  onClose,
  isFixed = true,
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <aside
      role={onClick ? 'button' : 'banner'}
      className={classNames(`banner ${className || ''}`, {
        warning: isWarning,
        success: isSuccess,
        interactable: !!onClick,
        'is-fixed': isFixed,
      })}
      onClick={handleOnClick}
    >
      <div
        className={classNames(
          `content flex flex--wrap justify--space-between w100`
        )}
      >
        {icon && (
          <span className="icon">
            <i className={icon} />
          </span>
        )}
        <div className="content--inner">{children}</div>
        {onClose && (
          <button
            className="is-icon close"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <i className="fas fa-times" />
          </button>
        )}
      </div>
    </aside>
  );
};

Banner.defaultProps = {};
