/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/prefer-default-export */
/* eslint-disable global-require */
// React throws a fit for these extensions because it's expecting modules
const assetDirectory = './assets';

// General Page Images
export const maintenanceLogo = require(`${assetDirectory}/images/data_maintenance_monochromatic.svg`);
export const unauthenticatedImage = require(`${assetDirectory}/images/authentication.svg`);
export const landingPageImage = require(`${assetDirectory}/images/home-background.svg`);

export const momentTimezones = require(`${assetDirectory}/moment-timezones.json`);
export const states = require(`${assetDirectory}/states.json`);
export const countries = require(`${assetDirectory}/countryCodesISO2.json`);
