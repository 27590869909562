/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { UserTableItem } from './userTableItem';
import { SortDirection, PaginationInfo } from '../../../types';
import { User } from '../../../models';
import { UsersSearchRequest } from '../../../services';
import { TableHeader, Table, Checkbox } from '../../shared';

type UsersTableProps = {
  users: (User & { doctorIsApproved: boolean })[];
  isLoadingUsers: boolean;
  paginationInfo: PaginationInfo & { searchTerm: string }; // 2021/01/25 - this should be an extended type
  setPaginationInfo: (p: UsersSearchRequest) => void;
};

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  isLoadingUsers,
  paginationInfo,
  setPaginationInfo,
}) => {
  const methods = useForm<{ activeOnly: boolean; onlyDummyUsers: boolean }>({
    defaultValues: {
      activeOnly: false,
      onlyDummyUsers: false,
    },
  });

  const tableHeaders = [
    { label: '' },
    { label: 'User', sortKey: 'alphabetical', className: 'left-align' },
    { label: 'Email', sortKey: 'email', className: 'desktop-only left-align' },
    { label: 'User Type', sortKey: 'userType' },
    {
      label: 'Register Date',
      sortKey: 'createdDate',
      className: 'desktop-only',
    },
    { label: 'Last Login', sortKey: 'lastLogin' },
  ] as TableHeader[];

  return (
    <Table
      headers={tableHeaders}
      collection={users.map((user) => ({
        id: user.getId(),
        user,
      }))}
      component={UserTableItem}
      onSort={(
        sortBy: string,
        sortDirection: SortDirection,
        searchTerm?: string
      ) =>
        setPaginationInfo({
          ...paginationInfo,
          sortBy,
          sortDirection,
          pageNumber: 1,
          searchTerm: searchTerm || '',
        })
      }
      onSearch={(searchTerm: string) =>
        setPaginationInfo({
          ...paginationInfo,
          searchTerm,
          pageNumber: 1,
        })
      }
      isFetchingData={isLoadingUsers}
      paginationInfo={paginationInfo}
      itemLabel="users"
      searchPlaceholder="Search by email, or first and/or last name"
      onPageChange={(newPageNumber: number, search?: string) => {
        setPaginationInfo({
          ...paginationInfo,
          pageNumber: newPageNumber,
          searchTerm: search || '',
        });
      }}
    >
      <FormContext {...methods}>
        <form>
          <Checkbox
            isChecked={methods.watch('activeOnly')}
            inputName="activeOnly"
            isRequired
            reversed
            inline
            label="Active Patients Only"
            onChange={(e) => {
              methods.setValue('activeOnly', e.target.checked);
              setPaginationInfo({
                ...paginationInfo,
                activeOnly: e.target.checked,
              });
            }}
          />
          <Checkbox
            isChecked={methods.watch('onlyDummyUsers')}
            inputName="onlyDummyUsers"
            isRequired
            reversed
            inline
            label="Only Dummy users"
            onChange={(e) => {
              methods.setValue('onlyDummyUsers', e.target.checked);
              setPaginationInfo({
                ...paginationInfo,
                onlyDummyUsers: e.target.checked,
              });
            }}
          />
        </form>
      </FormContext>
    </Table>
  );
};

export default UsersTable;
