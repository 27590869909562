import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Banner } from '../../shared';
import { useAppContext } from '../../shared/global';

/**
 * Permanent banner that is shown on a frozen account or a dunning account
 * Cannot be removed until the account is unfrozen or out of dunning
 */
const InactiveAccountBanner: React.FC = () => {
  const { appState } = useAppContext();
  const navigate = useNavigate();

  if (
    !appState.isFrozenAccount &&
    !appState.isInDunning &&
    !appState.isCancelled
  ) {
    return null;
  }
  let text = '';
  if (appState.isCancelled) {
    text =
      'Your account has been cancelled. Click here to manage your billing and restart your subscription to restore full access to your account.';
  } else if (appState.isFrozenAccount) {
    text =
      'Your account has been frozen due to a missed payment. Click here to make a payment and restore full access to your account.';
  } else if (appState.isInDunning) {
    text =
      "Don't Lose Access. Subscription Payment is Past due and your account will be frozen soon. Click here to make payment before your account loses access to future calendar updates. ";
  }

  return (
    <Banner
      icon="fas fa-exclamation-circle"
      className="frozen-dunning"
      isWarning
      onClick={() => navigate('/billing')}
      isFixed
    >
      {text}
    </Banner>
  );
};

export default InactiveAccountBanner;
