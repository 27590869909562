import { Billing, NotificationType, BaseModel } from '.';
import { UserType, Gender } from '../types';
import { capitalize } from '../util/helpers';
import { NotificationSettings } from '../types/form.types';

export class User extends BaseModel {
  firstName!: string;

  lastName!: string;

  password!: string;

  email!: string;

  userType!: UserType;

  gender!: Gender;

  timezone?: string;

  hasCompletedRegistration!: boolean;

  lastLoginDate?: Date;

  notificationSettings?: NotificationSettings;

  featureFlags?: string[];

  dateOfBirth?: Date;

  billing?: Billing;

  allowFullStory!: boolean;

  isDummyUser?: boolean;

  getFullName(): string {
    return `${capitalize(this.firstName)} ${capitalize(this.lastName)}`;
  }

  constructor(json?: User) {
    super();
    if (json) {
      const user = User.fromJSON<User>(json);
      if (json.billing) {
        user.billing = new Billing(json.billing);
      }
      return user;
    }
  }

  hasFeatureEnabled(flag: string): boolean {
    if (this.isAdmin()) return true;
    if (!this.featureFlags) return false;
    return this.featureFlags.includes(flag);
  }

  isAdmin(): boolean {
    return this.userType === UserType.admin;
  }

  isDoctor(): boolean {
    return this.userType === UserType.doctor;
  }

  isPatient(): boolean {
    return this.userType === UserType.patient;
  }

  getNotificationSettingStatus(notification: NotificationType): boolean {
    if (!this.notificationSettings) {
      return true;
    }
    if (this.notificationSettings[notification] === undefined) {
      return true;
    }
    return !!this.notificationSettings[notification];
  }
}
