import React, { useState } from 'react';
import './dashboard.page.css';
import { Tabs } from '../../shared';
import { AcceptedAndPendingPatientTable } from '../../features/dashboard/acceptedAndPendingPatientTable';
import { InvitePatientsTable } from '../../features/dashboard/invitePatientsTable';

function DashboardPage() {
  const [activeTabView, setActiveTabView] = useState('activePatients');

  const tabs = [
    {
      label: 'Active Patients',
      id: 'activePatients',
    },
    {
      label: 'New Patients',
      id: 'newPatients',
    },
    {
      label: 'Invite a Patient',
      id: 'invitePatients',
    },
  ];

  return (
    <section className="dashboard-page">
      <div className="page-wrapper is-flex-column">
        <Tabs
          tabs={tabs}
          onTabChange={(tab) => {
            setActiveTabView(tab.id);
          }}
          activeTabId={activeTabView}
        />
        {(activeTabView === 'activePatients' ||
          activeTabView === 'newPatients') && (
          <AcceptedAndPendingPatientTable activeTabId={activeTabView} />
        )}
        {activeTabView === 'invitePatients' && <InvitePatientsTable />}
      </div>
    </section>
  );
}
export default DashboardPage;
