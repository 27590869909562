import React, { useState, useEffect } from 'react';
import './users.page.scss';
import moment from 'moment';
import { SortDirection } from '../../../types';
import { User } from '../../../models/user.model';
import {
  getAllUsers,
  approveDoctor,
  getUnapprovedDoctors,
  getAllUsersForCSV,
  getProspectiveDoctors,
  getPatientsOfProspectiveDoctors,
  UsersSearchRequest,
  ProspectiveDoctorsSearchRequest,
} from '../../../services/admin.service';
import { changeDoctor, onApiErrorToast } from '../../../services';
import { onErrorToast, onSuccessToast } from '../../../services/toasts.service';
import { Doctor, ProspectiveDoctor } from '../../../models';
import DoctorTableItem from '../../features/admin/doctorTableItem';
import UsersTable from '../../features/admin/usersTable';
import { AsyncReturnType, downloadJSONAsCSV } from '../../../util/helpers';
import ProspectiveDoctorsTable from '../../features/admin/prospectiveDoctorsTable';
import PatientsForProspectiveDoctorsTable from '../../features/admin/patientsForProspectiveDoctorsTable';
import { TableHeader, TitleBlock, Tabs, Table, Button } from '../../shared';

// TODO: 2021/01/25 - make this an admin dashboard page, split up the differnet views
const UsersPage: React.FC = () => {
  const [users, setUsers] = useState(
    [] as (User & { doctorIsApproved: boolean })[]
  );
  const [activeTab, setActiveTab] = useState('users');
  const [doctors, setDoctors] = useState([] as Doctor[]);
  const [patients, setPatients] = useState(
    [] as AsyncReturnType<typeof getPatientsOfProspectiveDoctors>
  );
  const [prospectiveDoctors, setProspectiveDoctors] = useState(
    [] as ProspectiveDoctor[]
  );
  const [isLoadingProspectiveDoctors, setIsLoadingProspectiveDoctors] =
    useState(true);

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingDoctors, setIsLoadingDoctors] = useState(true);
  const [isLoadingPatients, setIsLoadingPatients] = useState(true);

  const [paginationInfo, setPaginationInfo] = useState({
    sortDirection: SortDirection.DESC,
    sortBy: 'createdDate',
    pageNumber: 1,
    pageSize: 20,
    searchTerm: '',
  } as UsersSearchRequest);

  const [prospectiveDoctorPaginationInfo, setProspectiveDoctorPaginationInfo] =
    useState({
      sortDirection: SortDirection.DESC,
      sortBy: 'createdDate',
      pageNumber: 1,
      pageSize: 10,
      searchTerm: '',
      includeArchived: false,
    } as ProspectiveDoctorsSearchRequest);

  const downloadAsCsv = (type: string): void => {
    getAllUsersForCSV(type)
      .then((csvUsers) => {
        if (
          !downloadJSONAsCSV(
            csvUsers,
            ['firstName', 'lastName', 'email', 'userType'],
            `All ${type} - ${moment().format('MMMM-DD-YYYY')}`
          )
        ) {
          onErrorToast("Sorry, it looks like we couldn't download this CSV.");
        }
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  const fetchUsers = (params: UsersSearchRequest): void => {
    setIsLoadingUsers(true);
    getAllUsers(params)
      .then((newUsers) => {
        setUsers(newUsers.results);
        setPaginationInfo({
          ...paginationInfo,
          total: newUsers.total,
        });
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  };

  const fetchProspectiveDoctors = (): void => {
    setIsLoadingProspectiveDoctors(true);
    getProspectiveDoctors(prospectiveDoctorPaginationInfo)
      .then((prospects) => {
        setProspectiveDoctors(prospects.results);
        setProspectiveDoctorPaginationInfo({
          ...prospectiveDoctorPaginationInfo,
          total: prospects.total,
        });
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => {
        setIsLoadingProspectiveDoctors(false);
      });
  };

  const fetchUnapprovedDoctors = (): void => {
    setIsLoadingDoctors(true);
    getUnapprovedDoctors()
      .then((doctorsResponse) => {
        setDoctors(doctorsResponse);
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => {
        setIsLoadingDoctors(false);
      });
  };

  const onApproveDoctor = (doctorId: string) => {
    approveDoctor(doctorId)
      .then(() => {
        onSuccessToast('Provider has been approved.');
        fetchUnapprovedDoctors();
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  const fetchPatientsOfProspectiveDoctors = (): void => {
    setIsLoadingPatients(true);
    getPatientsOfProspectiveDoctors()
      .then((results) => {
        setPatients(results);
      })
      .catch((error) => onApiErrorToast(error))
      .finally(() => setIsLoadingPatients(false));
  };

  useEffect(() => {
    if (activeTab === 'patients') {
      fetchPatientsOfProspectiveDoctors();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'doctors') {
      fetchUnapprovedDoctors();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'doctors') {
      fetchProspectiveDoctors();
    }
  }, [
    prospectiveDoctorPaginationInfo.pageNumber,
    prospectiveDoctorPaginationInfo.sortBy,
    prospectiveDoctorPaginationInfo.sortDirection,
    prospectiveDoctorPaginationInfo.searchTerm,
    prospectiveDoctorPaginationInfo.includeArchived,
    activeTab,
  ]);

  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers({
        ...paginationInfo,
      });
    }
  }, [
    paginationInfo.pageNumber,
    paginationInfo.sortBy,
    paginationInfo.sortDirection,
    paginationInfo.searchTerm,
    paginationInfo.onlyDummyUsers,
    paginationInfo.activeOnly,
    activeTab,
  ]);

  const doctorTableHeaders = [
    { label: 'Doctor' },
    { label: 'Clinic' },
    { label: 'Register Date' },
    { label: '' },
  ] as TableHeader[];

  return (
    <section className="users-page">
      <div className="page-wrapper is-flex-column">
        <TitleBlock title="Users" />

        <Tabs
          tabs={[
            {
              label: 'Users',
              id: 'users',
            },
            {
              label: 'Doctors',
              id: 'doctors',
            },
            {
              label: 'Patients',
              id: 'patients',
            },
            {
              label: 'Analytics',
              id: 'analytics',
              href: '/admin/users/analytics',
            },
            {
              label: 'Other',
              id: 'other',
            },
          ]}
          onTabChange={(tab) => {
            setActiveTab(tab.id);
          }}
          activeTabId={activeTab}
        />

        {activeTab === 'users' && (
          <UsersTable
            paginationInfo={paginationInfo}
            setPaginationInfo={setPaginationInfo}
            isLoadingUsers={isLoadingUsers}
            users={users}
          />
        )}
        {activeTab === 'doctors' && (
          <>
            <br />
            <h3 className="title text-centered">Doctors Awaiting Approval</h3>
            <br />
            <Table
              headers={doctorTableHeaders}
              collection={doctors.map((doctor) => ({
                id: doctor.getId(),
                doctor,
                approveDoctor: onApproveDoctor,
              }))}
              component={DoctorTableItem}
              isFetchingData={isLoadingDoctors}
              itemLabel="doctors"
            />
            <hr />
            <ProspectiveDoctorsTable
              paginationInfo={prospectiveDoctorPaginationInfo}
              setPaginationInfo={setProspectiveDoctorPaginationInfo}
              isLoadingUsers={isLoadingProspectiveDoctors}
              doctors={prospectiveDoctors}
            />
          </>
        )}
        {activeTab === 'patients' && (
          <>
            <br />
            <PatientsForProspectiveDoctorsTable
              patients={patients}
              isLoading={isLoadingPatients}
              onChangeDoctor={(doctorId, patientId) => {
                changeDoctor(patientId, doctorId)
                  .then(() => {
                    onSuccessToast('New Provider Assigned to Patient');
                    fetchPatientsOfProspectiveDoctors();
                  })
                  .catch((e) => onApiErrorToast(e));
              }}
            />
          </>
        )}
        {activeTab === 'other' && (
          <>
            <br />
            <h3 className="title text-centered">Constant Contacts</h3>
            <p className="description">
              Download as CSV Files, for import into Constant Contacts.
            </p>
            <p className="description">
              These files exclude deleted/cancelled accounts.
            </p>
            <section className="download-csvs">
              <Button
                onClick={() => {
                  downloadAsCsv('users');
                }}
              >
                Download All Users
              </Button>
              <Button
                onClick={() => {
                  downloadAsCsv('patients');
                }}
              >
                Download All Patients
              </Button>
              <Button
                onClick={() => {
                  downloadAsCsv('doctors');
                }}
              >
                Download All Doctors
              </Button>
            </section>
          </>
        )}
      </div>
    </section>
  );
};
export default UsersPage;
