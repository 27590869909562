/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { User } from '../../../models';
import { FEATURE_FLAGS } from '../../../util';
import './moreSettings.scss';
import { useSessionContext } from '../../shared/global/sessionContext';
import { CheckboxList } from '../../shared';

type FeatureFlagProps = {
  user: User;
  onChange: (e: any, flagName: string) => void;
};

const FeatureFlagList: React.FC<FeatureFlagProps> = ({ user, onChange }) => {
  const [sessionContext] = useSessionContext();
  const checkboxes = FEATURE_FLAGS.map(({ key, label }) => ({
    label,
    inputName: key,
    onChange,
    isChecked: user.hasFeatureEnabled(key),
  }));

  if (!sessionContext.user.isAdmin()) {
    return null;
  }
  return (
    <div className="more-settings-container">
      <h3 className="text-centered">Feature Flag Settings</h3>
      <p className="description">Toggle access to gated features.</p>
      <CheckboxList checkboxes={checkboxes} />
    </div>
  );
};

export default FeatureFlagList;
