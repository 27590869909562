import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormContext, useForm } from 'react-hook-form';
import { useSessionContext } from '../../shared/global/sessionContext';
import {
  onApiErrorToast,
  onSuccessToast,
  getPatientsForDoctor,
  updatePatientStatus,
} from '../../../services';
import { Patient, PatientStatus } from '../../../models';
import AcceptedPatientTableItem from './acceptedPatientTableItem';
import {
  PaginationInfo,
  SortDirection,
  PaginatedSearchRequest,
} from '../../../types';
import PendingPatientTableItem from './pendingPatientTableItem';
import { Table, Checkbox, TableHeader } from '../../shared';

export type AcceptedAndPendingPatientTableProps = {
  activeTabId: string;
};

export function AcceptedAndPendingPatientTable({
  activeTabId,
}: AcceptedAndPendingPatientTableProps) {
  const [session] = useSessionContext();
  const [patients, setPatients] = useState([] as Patient[]);
  const [isLoading, setIsLoading] = useState(true);
  const [newPatients, setNewPatients] = useState([] as Patient[]);
  const [paginationInfo, setPaginationInfo] = useState({
    sortDirection: 'ASC',
    sortBy: 'alphabetical',
    pageNumber: 1,
    pageSize: 30,
    searchTerm: '',
  } as PaginationInfo & { searchTerm: string });

  const methods = useForm<{ activeOnly: boolean }>();

  const pageRouteDoctorId = useParams<{ doctorId: string }>().doctorId;

  const doctorIdToUse = session.isAdmin
    ? pageRouteDoctorId
    : session.doctor?.getId();

  const fetchPatients = (params: PaginatedSearchRequest): void => {
    setIsLoading(true);
    getPatientsForDoctor(
      doctorIdToUse!,
      params,
      methods.getValues('activeOnly') || false
    )
      .then((doctorPatients) => {
        setPatients(doctorPatients.results);
        setPaginationInfo({
          ...paginationInfo,
          total: doctorPatients.total,
        });
        setNewPatients(doctorPatients.newPatients);
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => setIsLoading(false));
  };

  const activeOnlyWatch = methods.watch('activeOnly');
  useEffect(() => {
    fetchPatients({
      ...paginationInfo,
      searchTerm: paginationInfo.searchTerm,
    });
  }, [
    paginationInfo.pageNumber,
    paginationInfo.sortBy,
    paginationInfo.sortDirection,
    paginationInfo.searchTerm,
    activeOnlyWatch,
  ]);

  const tableHeaders = [
    { label: 'patient', sortKey: 'alphabetical', className: 'name' },
    { label: 'treatment' },
    { label: 'calendar' },
    { label: '', className: 'desktop-only' },
  ] as TableHeader[];

  const onClickStatus = (
    patient: Patient,
    newStatus: PatientStatus
  ): Promise<void> => {
    return updatePatientStatus(
      session.doctor!.getId(),
      patient.getId(),
      newStatus
    )
      .then(() => {
        fetchPatients({
          ...paginationInfo,
          searchTerm: '',
        });
        onSuccessToast(
          newStatus === PatientStatus.ACCEPTED
            ? 'Patient Request Accepted.'
            : 'Patient request rejected.'
        );
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  let componentToShow;
  if (activeTabId === 'activePatients') {
    componentToShow = AcceptedPatientTableItem;
  } else if (activeTabId === 'newPatients') {
    componentToShow = PendingPatientTableItem;
  }
  const tabPatientData =
    activeTabId === 'activePatients'
      ? patients.filter((p) => p.getPatientStatus() === PatientStatus.ACCEPTED)
      : newPatients;

  return (
    <>
      {!!newPatients?.length && (
        <p className="description">
          You have {newPatients.length} new patients waiting for approval.
        </p>
      )}
      <Table
        headers={tableHeaders}
        collection={tabPatientData.map((patient) => ({
          id: patient.getId(),
          patient,
          onClickStatus,
        }))}
        component={componentToShow}
        onSort={(sortBy: string, sortDirection: SortDirection) =>
          setPaginationInfo({ ...paginationInfo, sortBy, sortDirection })
        }
        onPageChange={(newPageNumber: number, search?: string) => {
          setPaginationInfo({
            ...paginationInfo,
            pageNumber: newPageNumber,
            searchTerm: search || '',
          });
        }}
        onSearch={(searchTerm: string) =>
          setPaginationInfo({ ...paginationInfo, searchTerm, pageNumber: 1 })
        }
        searchPlaceholder="Search by First and or Last Name"
        isFetchingData={isLoading}
        paginationInfo={paginationInfo}
        itemLabel="patients"
      >
        {activeTabId === 'activePatients' && (
          <FormContext {...methods}>
            <form>
              <Checkbox
                isChecked={methods.watch('activeOnly')}
                inputName="activeOnly"
                isRequired
                reversed
                label="Active Patients Only"
                onChange={(e) => {
                  methods.setValue('activeOnly', e.target.checked);
                }}
              />
            </form>
          </FormContext>
        )}
      </Table>
    </>
  );
}
