import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import { resetPassword } from '../../../services';
import {
  onSuccessToast,
  onErrorToast,
  onApiErrorToast,
} from '../../../services/toasts.service';
import { isTokenExpired } from '../../../util/helpers';
import { LogoBlock, PasswordInput, Button } from '../../shared';

export type JWTResetPassword = {
  email: string;
  iat: number;
  exp: number;
};

const ResetPasswordPage: React.FC = () => {
  const methods = useForm<{ password: string; confirmPassword: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = queryString.parse(location.search);
  const [, setTokenData] = useState(null as JWTResetPassword | null);
  useEffect(() => {
    try {
      const token = jwtDecode(queryParams.token as string) as JWTResetPassword;
      if (isTokenExpired(token.exp) || !token.email) {
        onErrorToast(
          'Sorry, this reset password link has expired and is no longer valid.'
        );
        navigate('/login');
      } else {
        setTokenData(token);
      }
    } catch (error) {
      onErrorToast(
        'Sorry, this reset password link has expired and is no longer valid.'
      );
      navigate('/login');
    }
  }, [queryParams.token]);

  const onSubmit = (values: { password: string; confirmPassword: string }) => {
    setIsLoading(true);
    resetPassword(queryParams.token as string, values.password)
      .then(() => {
        onSuccessToast('Password changed!');
        navigate('/login');
      })
      .catch((error) => {
        const { response } = error;
        if (response && response.data?.error === 'Token is expired') {
          onErrorToast(
            'Sorry, this reset password link has expired and is no longer valid.'
          );
          navigate('/login');
        } else {
          onApiErrorToast(error);
        }
        setIsLoading(false);
      });
  };

  return (
    <section className="reset-password-page">
      <div className="page-wrapper is-flex-column full-page">
        <LogoBlock />
        <div className="wrapper has-box-shadow">
          <FormContext {...methods}>
            <form
              className="reset-password-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <h4>Reset Password</h4>
              <hr />
              <div className="form-group">
                <PasswordInput customPasswordLabel="New Password" isRequired />
              </div>
              <div className="form-group">
                <PasswordInput isConfirmPassword isRequired />
              </div>
              <Button type="submit" isLoading={isLoading}>
                Submit
              </Button>
            </form>
          </FormContext>
          <Link to="/" className="button is-link">
            Back
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordPage;
