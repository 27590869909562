import React from 'react';
import {
  useParams,
  useNavigate,
  NavigateFunction,
  Params,
  useLocation,
} from 'react-router-dom';

export type withNavigationProp = {
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
  location: Location;
};
export function withNavigation(Component: any) {
  return function (props: any) {
    return (
      <Component
        navigate={useNavigate()}
        location={useLocation}
        params={useParams()}
        {...props}
      />
    );
  };
}
