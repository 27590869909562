import React from 'react';
import './register-form.scss';
import moment from 'moment-timezone';

import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { RegisterFormData, UserType } from '../../../types';
import {
  FORM_VALIDATION,
  HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS,
} from '../../../util';
import { convertSelectOptionsToValue } from '../../../util/helpers';
import { ZONE_OPTIONS } from '../../../util/constants';
import { Radio } from '../../shared/forms/radio/radio';
import {
  TextField,
  PasswordInput,
  Dropdown,
  Checkbox,
  Button,
  ExternalLink,
} from '../../shared';

type UserDetailsProps = {
  onSubmit: (formData: RegisterFormData) => void;
  isLoading: boolean;
};

// These are the values which are SelectOption values, which we should transform into strings before sending
const selectValuesArray = ['howDidYouHearAboutUs'];

const UserDetails: React.FC<UserDetailsProps> = ({
  onSubmit,
  isLoading,
}: UserDetailsProps) => {
  const { errors, handleSubmit, watch, setValue } =
    useFormContext<RegisterFormData>();
  const guessedTimeZoneName = moment.tz.guess();
  const guessedTimezone = moment.tz.zone(guessedTimeZoneName);
  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        // eslint-disable-next-line no-param-reassign
        data = convertSelectOptionsToValue(data, selectValuesArray);
        // eslint-disable-next-line no-param-reassign
        delete data.termsAndConditions;
        // eslint-disable-next-line no-param-reassign
        data.howDidYouHearAboutUs = data.howDidYouHearAboutUs?.join(', ');
        // eslint-disable-next-line no-param-reassign
        data.timezone = ZONE_OPTIONS.find((option) =>
          guessedTimezone?.abbrs.includes(option.value)
        )?.value;
        if (!data.timezone) {
          Sentry.captureMessage(
            '[REGISTRATION] Could not find matching timezone for user',
            {
              extra: {
                timezone: guessedTimeZoneName,
              },
            }
          );
          // Fallback plan
          // eslint-disable-next-line no-param-reassign
          data.timezone = ZONE_OPTIONS[0].value;
        }
        onSubmit(data);
      })}
    >
      <h3>CREATE A NEW ACCOUNT</h3>
      <hr />
      <div className="form-group">
        <strong>Sign up as:</strong>
        <div className="wrapper--usertype">
          <Radio
            name="userType"
            value="patient"
            reverse
            isRequired
            isChecked={watch('userType') === 'patient'}
          >
            <span>
              <i className="fas fa-user" />
              Patient
            </span>
          </Radio>
          <Radio
            name="userType"
            value="doctor"
            reverse
            isRequired
            isChecked={watch('userType') === 'doctor'}
          >
            <span>
              <i className="fas fa-user-md" />
              Provider
            </span>
          </Radio>
          <div className="error">
            {errors.userType && errors.userType.message}
          </div>
        </div>
      </div>
      <br />
      <div className="form-group">
        <TextField
          name="email"
          required
          type="email"
          placeholder="Email"
          validationOptions={{
            required: 'Required',
            pattern: {
              value: FORM_VALIDATION.VALID_EMAIL,
              message: 'invalid email address',
            },
          }}
        >
          Email
        </TextField>
      </div>
      <div className="form-group half-width">
        <TextField
          name="firstName"
          placeholder="John"
          required
          min={2}
          validationOptions={{
            required: 'Required',
            min: 2,
            maxLength: 80,
          }}
        >
          First Name
        </TextField>
      </div>
      <div className="form-group half-width">
        <TextField
          name="lastName"
          required
          placeholder="Doe"
          min={2}
          validationOptions={{
            required: 'Required',
            min: 2,
            maxLength: 80,
          }}
        >
          Last Name
        </TextField>
      </div>
      <div className="form-group">
        <PasswordInput />
      </div>
      <div className="form-group">
        <PasswordInput isConfirmPassword />
      </div>
      <div className="form-group">
        <Dropdown
          isCreatable
          options={HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS}
          placeholder="Choose an option or type your answer directly"
          isMulti
          name="howDidYouHearAboutUs"
        >
          How did you hear about us?
        </Dropdown>
      </div>
      <hr />
      <div className="form-group terms-and-conditions">
        <Checkbox
          isChecked={watch('termsAndConditions')}
          inputName="termsAndConditions"
          isRequired
          onChange={(e) => {
            setValue('termsAndConditions', e.target.checked);
          }}
        />
        <div className="message">
          <p className="description">
            By clicking <strong>Sign Up</strong>, you agree to our{' '}
            <ExternalLink
              href={`https://artemiscalendar.com/terms-of-service${watch('userType') === UserType.doctor ? '-providers' : ''
                }`}
            >
              Terms of Service
            </ExternalLink>
            , and{' '}
            <ExternalLink href="https://artemiscalendar.com/privacy-policy">
              Privacy Policy
            </ExternalLink>
          </p>
          {watch('userType') !== 'doctor' && (
            <p className="description">
              Monthly membership fees required after initial 30 day free trial
              ends. No credit card required for trial. <br />
              For questions, visit our{' '}
              <ExternalLink href="https://artemiscalendar.com/pricing">
                Pricing
              </ExternalLink>{' '}
              Page.
            </p>
          )}
        </div>
      </div>
      <div className="button-container">
        <Link to="/">
          <Button className="is-link is-dark">Back to Login</Button>
        </Link>
        <Button type="submit" isLoading={isLoading}>
          Sign Up
        </Button>
      </div>
    </form>
  );
};

export default UserDetails;
