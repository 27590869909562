import { BaseModel, Doctor } from '.';

export class Clinic extends BaseModel {
  website?: string;

  telephone?: string;

  state?: string;

  name?: string;

  country?: string;

  address?: string;

  doctors?: Doctor[];

  constructor(json?: Clinic) {
    super();
    if (json) {
      const clinic = Clinic.fromJSON<Clinic>(json, {});
      if (json.doctors) {
        clinic.doctors = json.doctors.map((d) => new Doctor(d));
      }
      return clinic;
    }
  }

  // TODO: make this a generic if entity has address
  get fullAddress() {
    return `${this.address}, ${this.state}, ${this.country}`;
  }
}
