import React, { useEffect, useState } from 'react';
import { getOverallUsersAnalytics } from '../../../../services/admin.service';
import { Graph } from '../../../shared/graph/graph';

function randomColorGenerator() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export const OverallAnalytics: React.FC = () => {
  const [howDidUsersHearAboutUs, setHowDidUsersHearAboutUs] = useState(
    [] as { source: string; userType: string }[]
  );
  async function getOverallData() {
    const { howDidUsersHearAboutUs: data } = await getOverallUsersAnalytics();
    // TODO: make it so its a multi source response is separated for each term
    setHowDidUsersHearAboutUs(data);
  }

  const labels = new Set<string>();
  howDidUsersHearAboutUs.forEach(({ source }) => labels.add(source));

  const labelsAsArray = new Array(...labels);

  useEffect(() => {
    getOverallData();
  }, []);

  return (
    <Graph
      labels={labelsAsArray}
      datasets={[
        {
          label: 'Dataset 1',
          data: labelsAsArray.map((label) => {
            const data = howDidUsersHearAboutUs.filter(
              ({ source }) => label === source || label.includes(source)
            );

            return data.length;
          }),
          backgroundColor: labelsAsArray.map(() => randomColorGenerator()),
        },
      ]}
      graphTitle="How did users hear about us?"
      graphType="pie"
    />
  );
};
