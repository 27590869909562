/* eslint-disable no-param-reassign */
import axios from 'axios';
import { CLINIC_URL } from '../util/constants';
import { Clinic } from '../models';
import { EditClinicFormData } from '../types/form.types';

export const getClinics = async (): Promise<Clinic[]> => {
  const response = await axios.get<Clinic[]>(CLINIC_URL);
  const clinics = response.data.map((c) => new Clinic(c));
  return clinics;
};

export const getClinic = async (clinicId: string): Promise<Clinic> => {
  const response = await axios.get<Clinic>(`${CLINIC_URL}/${clinicId}`);
  const clinic = new Clinic(response.data);
  return clinic;
};

export const editClinic = async (
  clinicId: string,
  clinicData: EditClinicFormData
): Promise<Clinic> => {
  const result = await axios.put<Clinic>(
    `${CLINIC_URL}/${clinicId}`,
    clinicData
  );
  return new Clinic(result.data);
};
