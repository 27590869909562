import React, { useState } from 'react';
import './passwordInput.scss';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import classNames from 'classnames';
import { Button } from '../..';

export type PasswordInputProps = {
  mainPasswordFormName?: string; // The name of the form element we're trying to compare against
  isConfirmPassword?: boolean;
  isRequired?: boolean;
  customPasswordLabel?: string; // If you dont want it to just say Confirm Password or Password
  customInputName?: string;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  mainPasswordFormName = 'password',
  isConfirmPassword = false,
  isRequired = true,
  customPasswordLabel = '',
  customInputName = '',
}: PasswordInputProps) => {
  const { watch, errors, register } = useFormContext();

  const [viewPassword, setViewPassword] = useState(false);

  const id =
    customInputName || (isConfirmPassword ? 'confirmPassword' : 'password');

  // TODO: make unique identifier for id
  return (
    <label
      htmlFor={id}
      className={classNames('text-field password-input', {
        required: isRequired,
      })}
    >
      {isRequired && <span className="asterisk">*</span>}
      {customPasswordLabel ||
        (isConfirmPassword ? 'Confirm Password' : 'Password')}
      <div className="password-toggle">
        <input
          id={id}
          type={viewPassword ? 'text' : 'password'}
          name={id}
          ref={register(
            isConfirmPassword
              ? {
                  validate: (value: string) =>
                    value === watch(mainPasswordFormName) ||
                    'The passwords do not match',
                  required: isRequired ? 'Required' : undefined,
                }
              : {
                  minLength: {
                    value: 8,
                    message: 'Must be at least 8 characters',
                  },
                  required: isRequired ? 'Required' : undefined,
                }
          )}
          className="is-textbox "
          autoComplete=""
          placeholder="********"
          required={isRequired}
        />
        <Button
          className="is-icon"
          onClick={(): void => setViewPassword(!viewPassword)}
          tooltipText={viewPassword ? 'Hide Password' : 'Show Password'}
        >
          <i className={viewPassword ? 'fas fa-eye' : 'fas fa-eye-slash'} />
        </Button>
      </div>
      <ErrorMessage errors={errors} name={id}>
        {({ message }) => <span className="error">{message}</span>}
      </ErrorMessage>
    </label>
  );
};
