import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

export type CheckboxProps = {
  name: string;
  isChecked: boolean;
  value: any;
  isRequired?: boolean;
  label?: string;
  children?: React.ReactElement;
  disabled?: boolean;
  reverse?: boolean;
};

const Label = styled.label`
  &.reverse {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: left;
  }
`;

// TODO: need to pass in a control
export const Radio: React.FC<CheckboxProps> = ({
  label,
  name,
  isChecked = false,
  children,
  isRequired,
  value,
  disabled,
  reverse,
}) => {
  const { errors, register } = useFormContext();

  return (
    <Label
      className={classNames('radio', {
        checked: isChecked,
        disabled,
        reverse,
      })}
    >
      {children || <span className="text">{label}</span>}
      <input
        type="radio"
        name={name}
        ref={register({})}
        required={isRequired}
        value={value}
        disabled={disabled}
      />
      <span className="error">
        {errors && errors![name] && errors![name].message}
      </span>
    </Label>
  );
};
