import React from 'react';

import './toastList.scss';
import { Subscription } from 'rxjs';
import { onNewToast } from '../../../services/toasts.service';
import { Toast } from '..';
import { ToastProps } from './toast';

type ToastListState = {
  toasts: ToastProps[];
};

export class ToastList extends React.Component<
  Record<string, any>,
  ToastListState
> {
  subscription?: Subscription;

  constructor(props: any) {
    super(props);
    this.state = {
      toasts: [],
    };
  }

  componentDidMount() {
    this.subscription = onNewToast().subscribe((toast) => {
      const { toasts } = this.state;
      this.setState({
        toasts: [...toasts, toast],
      });
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClose = (id: string) => {
    const { toasts } = this.state;
    this.setState({
      toasts: toasts.filter((toast) => toast.id !== id),
    });
  };

  render() {
    const { toasts } = this.state;
    return (
      <div className="toast-wrapper is-flex-column">
        {toasts.map((toast) => {
          return <Toast key={toast.id} {...toast} onClose={this.onClose} />;
        })}
      </div>
    );
  }
}
