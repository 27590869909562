import React, { useState } from 'react';
import './usersAnalytics.page.css';
import { TitleBlock } from '../../shared';
import { Tabs } from '../../shared/tabs/tabs';
import { TimeBasedAnalytics, OverallAnalytics } from '../../features/admin';

const UsersAnalyticsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('time');

  return (
    <section className="users-analytics-page">
      <div className="page-wrapper is-flex-column">
        <TitleBlock title="Users Analytics" />
        <Tabs
          tabs={[
            {
              label: 'Over Time',
              id: 'time',
            },
            {
              label: 'Overall',
              id: 'overall',
            },
          ]}
          onTabChange={(tab) => setActiveTab(tab.id)}
          activeTabId={activeTab}
        />
        {activeTab === 'time' && <TimeBasedAnalytics />}
        {activeTab === 'overall' && <OverallAnalytics />}
      </div>
    </section>
  );
};

export default UsersAnalyticsPage;
