import React, { useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import { ElixirSession, Patient, Doctor, User } from '../../../models';
import { JWTData } from '../../../types';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// In memory token to pass around, in case they've disabled cookies
const inMemorySessionInfo: {
  token?: string;
  expiresAt?: Date;
} = {};

/**
 * Decodes the JWT token from the server, and auto sets the appropriate values with
 * React Models to use functions
 * @param token
 */
export function parseJwtToken(token: string): JWTData {
  const decodedToken = jwtDecode(token) as JWTData;
  const { doctor, patient } = decodedToken.additionalInfo;
  if (patient) {
    decodedToken.additionalInfo.patient = new Patient(patient);
  } else if (doctor) {
    decodedToken.additionalInfo.doctor = new Doctor(doctor);
  }
  const user = new User(decodedToken.user);
  decodedToken.user = user;
  inMemorySessionInfo.token = token;
  return decodedToken;
}

/**
 * Fallback for situations where cookies are disabled.
 * We use in app memory to store the token information so that it can be appended to headers for use
 */
export function setInMemorySessionToken(
  token: string | null,
  expiresIn: number
): void {
  if (token === null) {
    delete inMemorySessionInfo.token;
    delete inMemorySessionInfo.expiresAt;
  } else {
    inMemorySessionInfo.token = token;
    inMemorySessionInfo.expiresAt = new Date(Date.now() + expiresIn);
  }
}

export function getInMemorySessionToken(): typeof inMemorySessionInfo {
  return inMemorySessionInfo;
}

export const initialSession: ElixirSession = new ElixirSession();

export const SessionContext = React.createContext<
  [ElixirSession, (session: ElixirSession) => void]
  // eslint-disable-next-line indent
>([initialSession, (): void => {}]);
export const useSessionContext = () => React.useContext(SessionContext);

/**
 * Shared Global Context throughout the app for session information
 * @param props
 */
export const SessionContextProvider: React.FC<{
  children?: Element | any;
  initialSessionContext?: ElixirSession;
}> = ({ children, initialSessionContext }) => {
  const [sessionState, setSessionState] = React.useState(
    initialSessionContext || initialSession
  );
  const defaultSessionContext: [ElixirSession, typeof setSessionState] =
    useMemo(() => [sessionState, setSessionState], [sessionState]);

  return (
    <SessionContext.Provider value={defaultSessionContext}>
      {children}
    </SessionContext.Provider>
  );
};
