/* eslint-disable no-shadow */
export enum TreatmentType {
  estrogen = 'estrogen',
  testerone = 'testerone',
}
export enum UserType {
  patient = 'patient',
  doctor = 'doctor',
  admin = 'admin',
}

export enum CalendarType {
  personal = 'personal',
  lunar = 'lunar',
}
export enum ChangeType {
  all = 'all',
  repeat = 'repeat',
  single = 'single',
  restOfCycle = 'restOfCycle',
  custom = 'custom',
  fromBeginningToDay = 'fromBeginningToDay',
}
export enum Gender {
  male = 'male',
  female = 'female',
  other = 'other',
}

export enum TreatmentSubstance {
  estrogen = 'estrogen',
  progesterone = 'progesterone',
  testosterone = 'testosterone',
}

export enum DosageUnit {
  lines = 'lines',
  clicks = 'clicks',
  capsules = 'capsules',
  drops = 'drops',
  pills = 'pills',
}

export enum FrequencyUnit {
  day = 'day',
  am = 'AM',
  pm = 'PM',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
