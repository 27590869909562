import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/browser';
import { createCheckoutSession } from '../../../services';
import { onErrorToast } from '../../../services/toasts.service';
import { Button } from '../../shared';

const AddCreditCardForm: React.FC = () => {
  const stripe = useStripe();

  const setupPaymentIntent = async () => {
    const sessionId = await createCheckoutSession();
    const { error } = await stripe!.redirectToCheckout({
      sessionId,
    });
    if (error) {
      Sentry.captureException(error);
      onErrorToast("Sorry, something went wrong, and we're looking into it!");
    }
  };

  return (
    <Button
      className="add-new-card is-primary"
      role="button"
      onClick={setupPaymentIntent}
    >
      Add New Card
    </Button>
  );
};

export default AddCreditCardForm;
