/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { SortDirection, PaginationInfo } from '../../../types';
import { ProspectiveDoctor, ProspectiveDoctorStatus } from '../../../models';
import {
  updateProspectiveDoctorStatus,
  onSuccessToast,
  onApiErrorToast,
} from '../../../services';

import { TableHeader, Table, Checkbox } from '../../shared';

import { ProspectiveDoctorTableItem } from './prospectiveDoctorItem';

type ProspectiveDoctorsTableProps = {
  doctors: ProspectiveDoctor[];
  isLoadingUsers: boolean;
  paginationInfo: PaginationInfo & { searchTerm: string }; // 2021/01/25 - this should be an extended type
  setPaginationInfo: (
    p: PaginationInfo & { searchTerm: string; includeArchived: boolean }
  ) => void;
};

const ProspectiveDoctorsTable: React.FC<ProspectiveDoctorsTableProps> = ({
  doctors,
  isLoadingUsers,
  paginationInfo,
  setPaginationInfo,
}) => {
  const methods = useForm<{ includeArchived: boolean }>({
    defaultValues: {
      includeArchived: false,
    },
  });
  const tableHeaders = [
    { label: 'Name', sortKey: 'alphabetical', className: 'left-align' },
    { label: 'Clinic', sortKey: 'clinic', className: 'left-align' },
    { label: 'Location', className: 'left-align' },
    { label: 'Referred By', className: '' },
    { label: 'Referred Date', className: '', sortKey: 'referredDate' },
    { label: 'Status', sortKey: 'status' },
  ] as TableHeader[];

  const onStatusChange = (newStatus: ProspectiveDoctorStatus, id: number) => {
    updateProspectiveDoctorStatus({ status: newStatus, prospectId: id })
      .then(() => {
        onSuccessToast('Prospect Status Updated');
      })
      .catch((e) => {
        onApiErrorToast(e);
      });
  };

  return (
    <>
      <h3 className="text-centered">Prospective Doctors</h3>
      <Table
        headers={tableHeaders}
        collection={doctors.map((doctor) => ({
          id: doctor.getId(),
          prospectiveDoctor: doctor,
          onStatusChange,
        }))}
        component={ProspectiveDoctorTableItem}
        onSort={(
          sortBy: string,
          sortDirection: SortDirection,
          searchTerm?: string
        ) =>
          setPaginationInfo({
            ...paginationInfo,
            sortBy,
            sortDirection,
            pageNumber: 1,
            searchTerm: searchTerm || '',
            includeArchived: methods.getValues('includeArchived'),
          })
        }
        onSearch={(searchTerm: string) =>
          setPaginationInfo({
            ...paginationInfo,
            searchTerm,
            pageNumber: 1,
            includeArchived: methods.getValues('includeArchived'),
          })
        }
        isFetchingData={isLoadingUsers}
        paginationInfo={paginationInfo}
        itemLabel="prospective doctors"
        searchPlaceholder="Search by first or last name"
        onPageChange={(newPageNumber: number, search?: string) => {
          setPaginationInfo({
            ...paginationInfo,
            pageNumber: newPageNumber,
            searchTerm: search || '',
            includeArchived: methods.getValues('includeArchived'),
          });
        }}
      >
        <FormContext {...methods}>
          <form>
            <Checkbox
              isChecked={methods.watch('includeArchived')}
              inputName="includeArchived"
              reversed
              inline
              label="Include Accepted and Declined Doctors"
              onChange={(e) => {
                methods.setValue('includeArchived', e.target.checked);
                setPaginationInfo({
                  ...paginationInfo,
                  includeArchived: e.target.checked,
                });
              }}
            />
          </form>
        </FormContext>
      </Table>
    </>
  );
};

export default ProspectiveDoctorsTable;
