import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { SelectOption, RegisterDoctorData } from '../../../types/form.types';

import './register-form.scss';
import { TREATMENT_OPTIONS } from '../../../util';
import { states } from '../../../assets';
import { getClinics } from '../../../services/clinic.service';
import { onApiErrorToast } from '../../../services/toasts.service';
import { Clinic } from '../../../models/clinic.model';
import { convertSelectOptionsToValue } from '../../../util/helpers';
import { Button } from '../../shared';

type DoctorRegisterProps = {
  onSubmit: (formData: RegisterDoctorData) => void;
  isLoading: boolean;
};

// react-select.com/async
const countryOptions: SelectOption<string>[] = [
  {
    value: 'usa',
    label: 'United States',
  },
];

const stateOptions: SelectOption<string>[] = states.map((state: string) => {
  return {
    value: state,
    label: state,
  };
});

const selectValuesArray = [
  'clinicCountry',
  'clinicState',
  'treatmentsOffered',
  'clinicId',
];

// TODO: the error states for selects not working
const DoctorRegister: React.FC<DoctorRegisterProps> = ({
  onSubmit,
  isLoading,
}: DoctorRegisterProps) => {
  const { register, errors, control, handleSubmit, setValue, watch } =
    useForm<RegisterDoctorData>();
  const [clinicOptions, setClinicOptions] = useState<SelectOption<string>[]>(
    []
  );

  const [fetchedClinics, setFetchedClinics] = useState<SelectOption<string>[]>(
    []
  );
  const clinicId = (watch('clinicId') as any as SelectOption<string>)?.value;

  useEffect(() => {
    getClinics()
      .then((clinicsResponse) => {
        const options = clinicsResponse.map((clinic: Clinic) => {
          return {
            label: clinic.name!,
            value: clinic.getId(),
          };
        });
        setClinicOptions(options);
        setFetchedClinics(options);
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  }, []);

  const handleCreate = (newOptionValue: string) => {
    setClinicOptions([
      ...fetchedClinics,
      { label: newOptionValue, value: newOptionValue },
    ]);
    setValue('clinicName', newOptionValue);
    setValue('clinicId', { label: newOptionValue, value: 'tempId' } as any);
  };

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        // eslint-disable-next-line no-param-reassign
        data = convertSelectOptionsToValue(data, selectValuesArray);
        // eslint-disable-next-line no-param-reassign
        data.treatmentsOffered = [data.treatmentsOffered];
        onSubmit(data);
      })}
    >
      <h3>Provider Information</h3>
      <hr />
      <div className="form-group">
        <label>
          The name of your practice
          <Controller
            as={
              <CreatableSelect
                classNamePrefix="react-select"
                className="react-select-container"
                options={clinicOptions}
              />
            }
            control={control}
            rules={{
              required: {
                value: true,
                message: 'required',
              },
            }}
            onCreateOption={handleCreate}
            onChange={([selected]) => selected}
            name="clinicId"
          />
          <p className="description">
            Select from an existing clinic, or start typing to register a new
            one.
          </p>
          <span className="error">
            {errors?.clinicId && errors.clinicId.message}
          </span>
        </label>
      </div>
      {(!clinicId || clinicId === 'tempId') && (
        <>
          <div className="form-group is-hidden">
            <label>
              clinic name
              <input
                type="text"
                name="clinicName"
                className="is-textbox"
                placeholder="Newmarket Clinic"
                ref={register({
                  required: 'Required',
                })}
              />
              <span className="error">
                {errors.clinicName && errors.clinicName.message}
              </span>
            </label>
          </div>
          <div className="address-container">
            <div className="form-group country">
              <label>
                Where is your practice located?
                <Controller
                  as={
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      options={countryOptions}
                    />
                  }
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'required',
                    },
                  }}
                  onChange={([selected]) => selected}
                  name="clinicCountry"
                />
                <span className="error">
                  {errors.clinicCountry && errors.clinicCountry.message}
                </span>
              </label>
            </div>
            <div className="form-group state">
              <label>
                state
                <Controller
                  as={
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      options={stateOptions}
                    />
                  }
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'required',
                    },
                  }}
                  onChange={([selected]) => selected}
                  name="clinicState"
                />
                <span className="error">
                  {errors.clinicState && errors.clinicState.message}
                </span>
              </label>
            </div>
            <div className="form-group address">
              <label>
                address
                <input
                  type="text"
                  name="clinicAddress"
                  className="is-textbox"
                  placeholder="1234 main st"
                  ref={register({
                    required: 'Required',
                  })}
                />
                <span className="error">
                  {errors.clinicAddress && errors.clinicAddress.message}
                </span>
              </label>
            </div>
          </div>
        </>
      )}
      <br />
      <h3>Tell us about your Credentials</h3>
      <hr />
      <div className="form-group">
        <label>
          For HIPAA reasons, please confiirm your National Provider Identifier
          (NPI)
          <input
            type="text"
            name="nip"
            className="is-textbox"
            placeholder="###-###-###"
            ref={register({
              required: 'Required',
            })}
          />
          <span className="error">{errors.nip && errors.nip.message}</span>
        </label>
      </div>
      <div className="form-group">
        <label>
          treatments offered
          <Controller
            as={
              <Select
                classNamePrefix="react-select"
                className="react-select-container"
                options={TREATMENT_OPTIONS}
              />
            }
            control={control}
            rules={{
              required: {
                value: true,
                message: 'required',
              },
            }}
            onChange={([selected]) => selected}
            name="treatmentsOffered"
          />
          <span className="error">
            <ErrorMessage errors={errors} name="treatmentsOffered" />
          </span>
        </label>
      </div>
      <div className="button-container">
        <Button type="submit" isLoading={isLoading}>
          Next
        </Button>
      </div>
    </form>
  );
};

export default DoctorRegister;
