import React from 'react';
import { User } from '../../../models';
import { CancelAccount } from './cancelAccount';
import { DeleteAccount } from './deleteAccount';

type CancelOrDeleteAccountProps = {
  user: User;
  billingInfo: Record<string, any>;
  onCancelAccount: () => void;
};

export const CancelOrDeleteAccount: React.FC<CancelOrDeleteAccountProps> = ({
  user,
  billingInfo,
  onCancelAccount,
}) => {
  const showCancelAccount =
    !billingInfo.planDetails.cancelAtPeriodEnd &&
    !user.billing!.isCancelled &&
    user.billing?.billingStatus !== 'preconfirmation';
  return (
    <section className="delete-or-cancel">
      {showCancelAccount && (
        <CancelAccount
          user={user}
          billingInfo={billingInfo}
          onCancelAccount={onCancelAccount}
        />
      )}
      <DeleteAccount user={user} />
    </section>
  );
};
