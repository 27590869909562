/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { SyntheticEvent } from 'react';
import './userTableItem.scss';
import './doctorTableItem.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Doctor } from '../../../models';
import { Button } from '../../shared';

type DoctorTableItemProps = {
  doctor: Doctor;
  approveDoctor: (doctorId: string) => void;
};

const DoctorTableItem: React.FC<DoctorTableItemProps> = ({
  doctor,
  approveDoctor,
}) => {
  const navigate = useNavigate();
  const handleOnClick = () => navigate(`/doctor/${doctor.getId()}`);

  return (
    <tr className="doctor-table-item" onClick={handleOnClick}>
      <td className="name">{doctor.user.getFullName()}</td>
      <td className="clinic">{doctor.clinic!.name}</td>
      <td className="created-date">
        {moment(doctor.user.createdDate).fromNow()}
      </td>
      <td>
        <Button
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
            approveDoctor(doctor.getId());
          }}
          className="approve"
        >
          Approve
        </Button>
      </td>
    </tr>
  );
};

export default DoctorTableItem;
