import { BaseModel } from '.';

// eslint-disable-next-line no-shadow
export enum ProspectiveDoctorStatus {
  CONTACTED = 'contacted',
  NO_RESPONSE = 'no response',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
  FOLLOW_UP = 'follow up',
  NEW = 'new',
}

export class ProspectiveDoctor extends BaseModel {
  clinicName!: string;

  telephone!: string;

  email!: string;

  country!: string;

  state!: string;

  city!: string;

  firstName!: string;

  lastName!: string;

  status!: string;

  referredByUser!: string;

  referredDate!: string;

  constructor(json?: ProspectiveDoctor) {
    super();
    if (json) {
      const doctor = ProspectiveDoctor.fromJSON<ProspectiveDoctor>(json);
      return doctor;
    }
  }
}
