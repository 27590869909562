import Papa from 'papaparse';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';
import { PaginatedSearchRequest, SortDirection, SelectOption } from '../types';

/* eslint-disable no-bitwise */
/**
 * should avoid using this function for collections if possible because react
 * will not be able to render against dynamic objects well since its auto generated
 */
export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function capitalize(s: string | undefined): string {
  if (!s) return '';
  return s[0].toUpperCase().concat(s.slice(1, s.length));
}

/**
 * Retrieves only a subset of keys in an object
 */
export function pick<T>(
  values: Record<string, any>,
  keysToPick: string[]
): Partial<T> {
  const returnVal: { [key: string]: any } = {};
  keysToPick.forEach((key) => {
    returnVal[key] = values[key];
  });
  return returnVal as T;
}

/**
 * Returns the object without a set of keys
 *
 */
export function excludePick(
  values: Record<string, any>,
  keysToExclude: string[]
): Record<string, any> {
  const returnVal: { [key: string]: any } = { ...values };
  keysToExclude.forEach((key) => {
    delete returnVal[key];
  });
  return returnVal;
}

/**
 * https://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object
 * Sets a value of nested key string descriptor inside a Object.
 * It changes the passed object.
 * Ex:
 *    let obj = {a: {b:{c:'initial'}}}
 *    setNestedKey(obj, ['a', 'b', 'c'], 'changed-value')
 *    assert(obj === {a: {b:{c:'changed-value'}}})
 *
 * @param {[Object]} obj   Object to set the nested key
 * @param {[Array]} path  An array to describe the path(Ex: ['a', 'b', 'c'])
 * @param {[Object]} value Any value
 */
export const setNestedKey = (
  obj: Record<string, any>,
  path: string[],
  value: any
): any => {
  if (path.length === 1) {
    const p = path[0];
    // eslint-disable-next-line no-param-reassign
    obj[p] = value;
    return value;
  }
  return setNestedKey(obj[path[0]], path.slice(1), value);
};

/**
 * Converts a SelectOption to its value, or an array of selectOption into an array of values
 * @param values
 * @param selectOptionKeys
 */
export function convertSelectOptionsToValue(
  values: Record<string, any>,
  selectOptionKeys: string[]
): Record<string, any> {
  const alteredVals = { ...values };
  selectOptionKeys.forEach((key) => {
    if (values[key] instanceof Array) {
      alteredVals[key] = values[key].map(
        (val: SelectOption<string>) => val.value
      );
    } else if (values[key]) {
      alteredVals[key] = values[key].value;
    } else if (key.split('.').length > 1) {
      const nestedKeys = key.split('.');
      let currVal: any = values;
      nestedKeys.forEach((nestedKey) => {
        const nestedVal = currVal[nestedKey];
        currVal = nestedVal;
      });
      setNestedKey(alteredVals, key.split('.'), currVal.value);
    }
  });

  return alteredVals;
}

export function getOppositeSortDirection(
  direction?: SortDirection
): SortDirection {
  return direction === SortDirection.ASC
    ? SortDirection.DESC
    : SortDirection.ASC;
}

/**
 * @param tokenExpiryDate
 */
export function isTokenExpired(tokenExpiryDate: number): boolean {
  try {
    const date = new Date(0);
    date.setUTCSeconds(tokenExpiryDate);
    return new Date() > date;
  } catch (err) {
    return true;
  }
}

export function addPaginationToRequestQuery({
  sortDirection,
  pageNumber,
  pageSize,
  sortBy,
  searchTerm,
}: PaginatedSearchRequest): string {
  let queryString = '';
  if (sortDirection) queryString += `sortDirection=${sortDirection}&`;
  if (sortBy) queryString += `sortBy=${sortBy}&`;
  if (pageNumber)
    queryString += `pageNumber=${pageNumber}&pageSize=${pageSize}&`;
  if (searchTerm) queryString += `search=${searchTerm}`;
  return queryString;
}

export function getDeviceBrowser(): {
  isChrome: boolean;
  isSafari: boolean;
  isFirefox: boolean;
} {
  const isFirefox = navigator.userAgent?.indexOf('Firefox') > -1;
  const isChrome =
    navigator.vendor?.indexOf('Google') > -1 &&
    navigator.userAgent?.indexOf('Chrome') > -1;
  const isSafari =
    navigator.vendor?.indexOf('Apple') > -1 &&
    navigator.userAgent?.indexOf('CriOS') === -1 &&
    navigator.userAgent?.indexOf('FxiOS') === -1;
  return { isChrome, isSafari, isFirefox };
}

/**
 * Creates a CSV File that can be download from a given JSON Object using PapaParse
 * @param data
 * @param columns
 * @param filename
 */
export function downloadJSONAsCSV(
  data: Record<string, any>[],
  columns: string[],
  filename: string
): boolean {
  try {
    const csv = Papa.unparse(data, {
      columns,
      header: true,
    });

    // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
    const blob = new Blob([csv]);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return false;
  }
  return true;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export type AsyncReturnType<T extends (...args: any) => Promise<any>> =
  T extends (...args: any) => Promise<infer R> ? R : any;
