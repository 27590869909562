/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { NotificationType, User } from '../../../models';
import { DOCTOR_NOTIFICATION_SETTINGS } from '../../../util';
import { CheckboxList } from '../../shared';
import './moreSettings.scss';

type NotificationSettingsProps = {
  user: User;
  onChange: (notification: NotificationType, value: boolean) => void;
};

const NotificationSettings: React.FC<NotificationSettingsProps> = ({
  user,
  onChange,
}) => {
  if (!user.isDoctor()) {
    return null;
  }
  const checkboxes = DOCTOR_NOTIFICATION_SETTINGS.map((setting) => ({
    label: setting.label,
    inputName: setting.name,
    onChange: (e: any) => onChange(setting.name, e.target.checked),
    isChecked: user.getNotificationSettingStatus(setting.name),
  }));

  return (
    <div className="more-settings-container">
      <h3 className="text-centered">Notification Settings</h3>
      <p className="description">
        Toggle on or off whether you want to receive specific notifications
      </p>
      <CheckboxList checkboxes={checkboxes} />
    </div>
  );
};

export default NotificationSettings;
