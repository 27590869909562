import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Banner } from '..';
import { useAppContext, useSessionContext } from '../global';

export const Announcements = () => {
  const { appState, isInGoodStanding, hideAnnouncements } = useAppContext();
  const [sessionInfo] = useSessionContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [showAnnouncements, setShowAnnouncements] = useState(
    appState.showAnnouncements
  );

  useEffect(() => {
    setShowAnnouncements(appState.showAnnouncements);
  }, [appState.showAnnouncements]);

  if (!showAnnouncements) {
    return null;
  }

  // Handles frozen, cancelled, etc
  if (!isInGoodStanding) {
    return null;
  }

  // update this for whatever is needed
  if (!sessionInfo.isPatientView || location.pathname.includes('analytics')) {
    return null;
  }

  return (
    <Banner
      icon="fas fa-exclamation-circle"
      isSuccess
      onClose={() => {
        hideAnnouncements();
      }}
      onClick={() => {
        hideAnnouncements();
        navigate(`/patient/${sessionInfo.patient?.getId()}/analytics`);
      }}
    >
      Exciting news! We just released our beta feature for Patient Analytics!
      You can take a look at it now by clicking here!
    </Banner>
  );
};
