import { Doctor } from './index';
import { BaseModel } from './base.model';

// eslint-disable-next-line no-shadow
export enum InviteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
}
export class InvitedPatient extends BaseModel {
  email!: string;

  referringDoctor!: Doctor;

  referringDoctorId!: string;

  inviteStatus!: InviteStatus;

  lastInviteSentDate!: Date;

  constructor(json?: InvitedPatient) {
    super();
    if (json) {
      const invitedPatient = InvitedPatient.fromJSON<InvitedPatient>(json, {
        referringDoctor: new Doctor(json.referringDoctor),
      });
      return invitedPatient;
    }
  }
}
