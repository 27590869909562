import React from 'react';
import {
  Controller,
  ErrorMessage,
  useFormContext,
  ValidationOptions,
} from 'react-hook-form';
import classNames from 'classnames';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import './datepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

export type DatepickerProps = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  initialValue?: Date;
  validationOptions?: ValidationOptions;
} & Partial<Omit<ReactDatePickerProps, 'onChange' | 'onFocus' | 'onBlur'>>;

export const Datepicker: React.FC<DatepickerProps> = (props) => {
  const { errors, control } = useFormContext();
  const {
    name,
    required = false,
    initialValue = null,
    placeholder,
    validationOptions,
    label,
  } = props;

  return (
    <label
      className={classNames('datepicker text-field', { required })}
      htmlFor={name}
    >
      {required && <span className="asterisk">*</span>}
      {label}
      <Controller
        as={ReactDatePicker}
        dateFormat="MMMM d, yyyy"
        showTimeSelect={false}
        isClearable
        showYearDropdown
        defaultValue={initialValue}
        showMonthDropdown
        shouldCloseOnSelect
        dropdownMode="select"
        control={control}
        placeholderText={placeholder}
        valueName="selected" // DateSelect value's name is selected
        onChange={([selected]) => selected}
        key={name}
        rules={validationOptions}
        {...props}
      />
      {errors[name] && (
        <span className="error">
          <ErrorMessage errors={errors} name={name}>
            {({ message }) => message}
          </ErrorMessage>
        </span>
      )}
    </label>
  );
};
