import React, { useState, useEffect } from 'react';
import { useForm, Controller, FormContext } from 'react-hook-form';
import './patientProfile.scss';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { Doctor } from '../../../models';
import { getDoctor } from '../../../services';
import {
  onApiErrorToast,
  onSuccessToast,
} from '../../../services/toasts.service';
import {
  TREATMENT_OPTIONS,
  PAGE_NOT_FOUND,
  PAGE_UNAUTHORIZED,
} from '../../../util/constants';
import { TreatmentType } from '../../../types';
import { pick, convertSelectOptionsToValue } from '../../../util/helpers';
import { EditDoctorFormData } from '../../../types/form.types';
import { editDoctor } from '../../../services/doctor.service';
import { setPageTitle } from '../../shared/header/header';
import { withSession } from '../../shared/global';
import { withSessionProp } from '../../shared/global/withSession';
import { TextField, Button, Loader } from '../../shared';

type DoctorProfileProps = {
  doctorId: string;
};

const DoctorProfile: React.FC<DoctorProfileProps & withSessionProp> = ({
  doctorId,
  session: [sessionInfo],
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [doctor, setDoctorInfo] = useState<Doctor>(new Doctor());
  const navigate = useNavigate();
  const methods = useForm<EditDoctorFormData>();
  const {
    handleSubmit,
    formState: { dirtyFields },
    control,
  } = methods;

  useEffect(() => {
    const getDoctorProfile = async () => {
      getDoctor(doctorId)
        .then((doctorResponse) => {
          setDoctorInfo(new Doctor(doctorResponse));
          setPageTitle(`${doctorResponse.user.getFullName()}'s Profile`);
          setHasLoaded(true);
        })
        .catch((error) => {
          onApiErrorToast(error);
          if (error.response?.status === 403) {
            navigate(PAGE_UNAUTHORIZED);
          } else if (error.response?.status === 404) {
            navigate(PAGE_NOT_FOUND);
          }
        });
    };
    getDoctorProfile();
  }, [doctorId]);

  const onSubmit = (values: EditDoctorFormData) => {
    // eslint-disable-next-line no-param-reassign
    values = convertSelectOptionsToValue(values, ['treatmentsOffered']);
    editDoctor(doctor.getId(), pick(values, [...dirtyFields]))
      .then((_response) => {
        onSuccessToast('Changes Saved!');
      })
      .catch((error) => {
        onApiErrorToast(error);
      });
  };

  if (!hasLoaded) {
    return (
      <div className="page-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-wrapper">
      <div className="profile-container">
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group half-width">
              <TextField
                name="school"
                placeholder="school"
                initialValue={doctor.school}
                validationOptions={{
                  required: 'Required',
                }}
              >
                School
              </TextField>
            </div>
            <div className="form-group half-width">
              <TextField
                name="graduationYear"
                placeholder="Graduation Year"
                type="number"
                initialValue={doctor.graduationYear}
                validationOptions={{
                  required: 'Required',
                  min: {
                    value: 1950,
                    message: 'Must be greater than 1950',
                  },
                  max: {
                    value: 2050,
                    message: 'Cannot be greater than 2050',
                  },
                }}
              >
                Graduation Year
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                name="nip"
                placeholder=""
                initialValue={doctor.nip}
                validationOptions={{
                  required: 'Required',
                }}
                disabled
              >
                National Provider Identifier (NPI)
              </TextField>
            </div>
            <div className="form-group">
              <TextField
                name="website"
                placeholder=""
                initialValue={doctor.website}
              >
                Website
              </TextField>
            </div>
            <div className="form-group">
              <label>
                Treatments Offered
                <Controller
                  as={
                    <Select
                      classNamePrefix="react-select"
                      className="react-select-container"
                      options={TREATMENT_OPTIONS}
                      isDisabled
                    />
                  }
                  control={control}
                  defaultValue={TREATMENT_OPTIONS.find((option) =>
                    doctor.treatmentsOffered.includes(
                      option.value as TreatmentType
                    )
                  )}
                  onChange={([selected]) => ({ value: selected })}
                  name="treatmentsOffered"
                />
              </label>
            </div>
            <Button type="submit">Update</Button>
            <Link
              to={`/clinic/${doctor.clinicId}`}
              className="button is-link text-centered"
            >
              View Clinic
            </Link>
            {sessionInfo.isAdmin && (
              <>
                <Link
                  to={`/user/${doctor.user.getId()}/settings`}
                  className="button is-link text-centered"
                >
                  <strong>[ADMIN]</strong> View User Settings
                </Link>
                <Link
                  to={`/doctor/${doctor.getId()}/dashboard`}
                  className="button is-link text-centered"
                >
                  <strong>[ADMIN]</strong> View Patients
                </Link>
              </>
            )}
          </form>
        </FormContext>
      </div>
    </div>
  );
};
export default withSession(DoctorProfile);
