import React from 'react';
import './calendarFooter.scss';
import { CalendarType } from '../../../types';
import { withSession } from '../../shared/global';
import { withSessionProp } from '../../shared/global/withSession';
import { TreatmentLog, TreatmentSchedule } from '../../../models';
import { useSessionContext } from '../../shared/global/sessionContext';

type CalendarFooterProps = {
  calendarType: CalendarType;
  treatmentLogs: TreatmentLog[];
  treatmentSchedules: TreatmentSchedule[];
};
const CalendarFooter: React.FC<CalendarFooterProps & withSessionProp> = ({
  calendarType,
  treatmentLogs,
  treatmentSchedules,
  session: [sessionInfo],
}) => {
  const [sessionContext] = useSessionContext();

  // Returns the total dosages applied for each substance
  const accumulator: { [key: string]: number } = {};
  treatmentSchedules
    .filter((s) => s.frequency > 0)
    .forEach((s) => {
      accumulator[`${s.substance}-${s.frequencyUnit}`] = 0;
    });
  const totalDosages = treatmentLogs.reduce((accum, log) => {
    if (log.isFrozen) {
      return accumulator;
    }
    return log.treatment.reduce((acc, t) => {
      // Hide substances where its not being used
      if (t.frequency === 0) {
        return acc;
      }
      acc[`${t.substance}-${t.frequencyUnit}`] += t.dosage * t.frequency;
      return acc;
    }, accum);
  }, accumulator);

  return (
    <footer className="legend">
      <h4>Legend</h4>
      <section className="block-list">
        <span className="block circular">
          <span className="color-code symptom" />
          <span className="text">symptom</span>
        </span>
        <span className="block">
          <span className="color-code period" />
          <span className="text ">period</span>
        </span>
        {calendarType === CalendarType.personal && (
          <span className="block">
            <span className="color-code expected-period" />
            <span className="text">expected bleed</span>
          </span>
        )}

        <span className="block">
          <span className="color-code">
            <i className="fa fa-sticky-note note" />
          </span>
          <span className="text">
            {sessionContext.isSelfManagedView
              ? 'Your Notes'
              : 'Provider/Patient Note'}
          </span>
        </span>

        {sessionInfo.canAccessAdditionalViews && (
          <span className="block">
            <span className="color-code">
              <i className="fa fa-bolt one-time-change" />
            </span>
            <span className="text">Single Dosage Change</span>
          </span>
        )}
        <span className="block">
          <span className="color-code">
            <i className="fas fa-syringe blood-draw" />
          </span>
          <span className="text">Blood Draw</span>
        </span>
        <span className="block">
          <span className="color-code">
            <i className="fas fa-save saved-log" />
          </span>
          <span className="text">Saved Log</span>
        </span>
      </section>
      {sessionInfo.isDoctorView && !!Object.entries(totalDosages).length && (
        <>
          <hr />
          <h4>Monthly Dosage Summary</h4>
          <section className="block-list dosage-summary">
            {Object.entries(totalDosages).map(([key, value]) => {
              const [substance, frequencyUnit] = key.split('-');
              const matchingSchedule = treatmentSchedules.find(
                (s) =>
                  s.substance === substance && s.frequencyUnit === frequencyUnit
              );
              if (!matchingSchedule) return null;
              if (!matchingSchedule.frequency) return null;
              return (
                <span className="block" key={key}>
                  <span className="text">
                    {substance} ({frequencyUnit}) - {value}{' '}
                    {matchingSchedule.dosageUnit} ({matchingSchedule.frequency}x
                    / {matchingSchedule.frequencyUnit})
                  </span>
                </span>
              );
            })}
          </section>
        </>
      )}
    </footer>
  );
};

export default withSession(CalendarFooter);
