/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Doctor } from '../../../models';
import './doctorClinicTableItem.scss';
import { useSessionContext } from '../../shared/global/sessionContext';

type DoctorClinicTableItemProps = {
  doctor: Doctor;
};

const DoctorClinicTableItem: React.FC<DoctorClinicTableItemProps> = ({
  doctor,
}) => {
  const [session] = useSessionContext();
  const navigate = useNavigate();
  const handleOnClick = () => {
    // TODO: 2020/12/28 - need to handle this for other doctors in the clinc cuz of permission issues
    // trying to access others profiles
    if (session.isAdmin) {
      navigate(`/doctor/${doctor.getId()}`);
    }
  };

  return (
    <tr className="doctor-clinic-table-item" onClick={handleOnClick}>
      <td className="name">{doctor.user.getFullName()}</td>
      <td className="treatments-offered">
        {doctor.treatmentsOffered.join(', ')}
      </td>
      <td className="total-patients">{doctor.totalPatients}</td>
    </tr>
  );
};

export default DoctorClinicTableItem;
