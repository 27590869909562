export abstract class BaseModel {
  protected id?: string;

  public createdDate?: Date;

  public lastUpdateDate?: Date;

  /**
   * Converts a JSON object to a specified class
   * @param json JSON data to convert
   * @param className a Class to convert To, (Ex: User, Doctor, Patient)
   * @param customProperties (custom properties to set in a Object)
   */
  static fromJSON<classToConvertTo>(
    json?: classToConvertTo,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customProperties?: Record<string, any>
  ): classToConvertTo {
    const newObject = Object.create(this.prototype);
    if (!json) return newObject;
    return Object.assign(newObject, json, customProperties);
  }

  // TODO: change these to get/set instead
  public getId(): string {
    return this.id || '';
  }

  public setId(id: string): string {
    this.id = id;
    return this.getId();
  }

  public removeEmptyKey(): void {
    if (!this.id) {
      delete this.id;
    }
  }
}
