import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSessionContext } from '../../shared/global/sessionContext';
import { ElixirSession } from '../../../models';
import { logout } from '../../../services';
import { useAppContext } from '../../shared/global/appContext';

function LogoutPage() {
  const [, updateSessionContext] = useSessionContext();
  const { resetAppState } = useAppContext();

  useEffect(() => {
    const session = new ElixirSession();
    logout();
    updateSessionContext(session);
    resetAppState();
    // Force the page to reload to make sure they get any updates they might need after regular auth logouts
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }, []);

  // onSuccessToast('Logged Out!');
  return <Navigate to="/" />;
}

export default LogoutPage;
