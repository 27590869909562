import React, { useState, useEffect, Fragment } from 'react';
import {
  useForm,
  Controller,
  ErrorMessage,
  FormContext,
} from 'react-hook-form';
import './patientProfile.scss';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  PatientProfileFormData,
  SelectOption,
} from '../../../types/form.types';
import { CalendarType } from '../../../types';
import { getDoctors, getPatient, editPatient } from '../../../services';
import { Patient, Doctor, PatientStatus, BillingStatus } from '../../../models';
import { TREATMENT_OPTIONS } from '../../../util';
import {
  CALENDAR_TYPE_OPTIONS,
  PERIOD_MARGIN_OPTIONS,
  PAGE_NOT_FOUND,
  PAGE_UNAUTHORIZED,
} from '../../../util/constants';
import {
  onApiErrorToast,
  onErrorToast,
  onSuccessToast,
  onWarningToast,
} from '../../../services/toasts.service';
import {
  pick,
  convertSelectOptionsToValue,
  downloadJSONAsCSV,
} from '../../../util/helpers';
import { useSessionContext } from '../../shared/global/sessionContext';
import { DosageUnit, FrequencyUnit } from '../../../types/enums';
import { getAllTreatmentLogs } from '../../../services/patient.service';
import { ApiGetDoctorsResponse } from '../../../types/apiResponse.types';
import {
  Loader,
  Dropdown,
  Button,
  ConfirmModal,
  setPageTitle,
} from '../../shared';
import { PatientQuickNav } from '../patient/patientQuickNav';
import { Checkbox } from '../../shared/forms/checkbox/checkbox';

type PatientProfileProps = {
  patientId: string;
};

const dosageUnitOptions: SelectOption<string>[] = [
  { label: 'Clicks', value: DosageUnit.clicks },
  { label: 'Capsules', value: DosageUnit.capsules },
  { label: 'Drops', value: DosageUnit.drops },
  { label: 'Lines', value: DosageUnit.lines },
  { label: 'Pills', value: DosageUnit.pills },
];

const frequencyOptions: SelectOption<number>[] = [
  { label: 'None', value: 0 },
  { label: '1x daily', value: 1 },
  { label: '2x daily', value: 2 },
  { label: '3x daily', value: 3 },
];

function DosagePreferences({
  patient,
  canEditPatientFields,
}: {
  patient: Patient;
  canEditPatientFields: boolean;
}) {
  return (
    <>
      {patient
        .treatmentSchedules!.sort((a, b) => {
          if (a.substance > b.substance) return 1;
          if (a.substance < b.substance) return -1;
          if (a.frequencyUnit > b.frequencyUnit) return 1;
          if (a.frequencyUnit < b.frequencyUnit) return -1;
          return 0;
        })
        .map((schedule) => {
          let frequencyOptionsToUse = frequencyOptions;
          // to make life less complicated, if on AM/PM, only show 1x or 0x
          if (
            [FrequencyUnit.am, FrequencyUnit.pm].includes(
              schedule.frequencyUnit
            )
          ) {
            frequencyOptionsToUse = frequencyOptions.filter(
              (o) => o.value <= 1
            );
          }
          return (
            <Fragment
              key={`${schedule.substance}-${schedule.frequencyUnit}-frequency`}
            >
              <details open>
                <summary>
                  <strong className="capitalized">
                    {schedule.substance}</strong>
                  <hr /></summary>
                <div className="content">


                  <div className="form-group half-width--mobile-up">
                    <Dropdown
                      name={`dosagePreferences.${schedule.substance}.${schedule.frequencyUnit}.frequency`}
                      options={frequencyOptionsToUse}
                      required
                      validationOptions={{
                        required: {
                          value: true,
                          message: 'required',
                        },
                      }}
                      disabled={!canEditPatientFields}
                      initialValue={
                        frequencyOptions.find(
                          (option) => option.value === schedule.frequency
                        )?.value
                      }
                    >
                      {schedule.frequencyUnit} Dose Frequency
                    </Dropdown>
                  </div>
                  <div
                    className="form-group half-width--mobile-up"
                    key={`${schedule.substance}-${schedule.frequencyUnit}-dosage`}
                  >
                    <Dropdown
                      name={`dosagePreferences.${schedule.substance}.${schedule.frequencyUnit}.dosageUnit`}
                      options={dosageUnitOptions}
                      required
                      validationOptions={{
                        required: {
                          value: true,
                          message: 'required',
                        },
                      }}
                      disabled={!canEditPatientFields}
                      initialValue={
                        dosageUnitOptions.find(
                          (option) => option.value === schedule.dosageUnit
                        )?.value
                      }
                    >
                      {schedule.frequencyUnit} Dose Unit
                    </Dropdown>
                  </div>
                </div>
              </details>
            </Fragment>
          );
        })}
    </>
  );
}

const PatientProfile: React.FC<PatientProfileProps> = ({ patientId }) => {
  const methods = useForm<PatientProfileFormData>({
    defaultValues: {
      selfManagement: false,
    },
  });
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    formState: { dirtyFields },
  } = methods;
  const [patient, setPatientInfo] = useState<Patient>(new Patient());
  const [isConfirming, setIsConfirming] = useState(false);
  const [doctorOptions, setDoctorOptions] = useState<SelectOption<string>[]>(
    []
  );
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

  const [hasLoaded, setHasLoaded] = useState(false);
  const [showExtendedCalendarOptions, setExtendedCalendarOptions] =
    useState(false);
  const [session] = useSessionContext();

  const [canEditDoctor, setCanEditDoctor] = useState(false);

  const [isConfirmEditDoctor, setIsConfirmEditDoctor] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [canEditPatientFields, setCanEditPatientFields] = useState(true);

  const [isConfirmSwitchSelfManagement, setIsConfirmSwitchSelfManagement] =
    useState(false);

  const navigate = useNavigate();

  const getDoctorsOptions = async (patientResponse: Patient) => {
    getDoctors()
      .then((doctorsResponse: ApiGetDoctorsResponse) => {
        let { doctors } = doctorsResponse;
        doctors = doctors.filter((d) => {
          // TODO: really dont like how this is done
          // Only show the prospective doctor here only if they already are a patient of a prospective doctor
          if (
            d.user.email === 'prospectivedoctor@artemiscalendar.com' &&
            d.getId() === patientResponse.getId()
          ) {
            return false;
          }
          return true;
        });
        const newOptions = doctors.map((doctor: Doctor) => {
          let label = `Dr. ${doctor.user.getFullName()}`;
          if (doctor.user.email === 'prospectivedoctor@artemiscalendar.com') {
            label = 'Prospective Provider';
          }
          // Note: if the doctor hasn't yet been approved, it wont show up in this list
          if (patientResponse.doctorId === doctor.getId()) {
            // eslint-disable-next-line default-case
            switch (patientResponse.getPatientStatus()) {
              case PatientStatus.PENDING: {
                label += ' - Pending';
                break;
              }
              case PatientStatus.ACCEPTED: {
                label += ' - Approved';
                break;
              }
              case PatientStatus.REJECTED: {
                label += ' - Rejected';
                break;
              }
            }
          }
          return {
            label,
            value: doctor.getId(),
          };
        });
        setDoctorOptions(newOptions);
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => {
        // TODO: 2020/12/21 - its kinda dirty but couldnt figure out how to get the default value for the select to be updated since the options are loaded in later
        // TODO: realistically, i should probably be looking at a form ref or something
        setHasLoaded(true);
      });
  };
  const getPatientProfile = async () => {
    getPatient(patientId)
      .then((patientResponse) => {
        setPatientInfo(patientResponse);
        setPageTitle(
          !session.isPatientView
            ? `${patientResponse.user.getFullName()}'s Profile`
            : 'My Profile'
        );
        if (patientResponse.calendarType === CalendarType.personal) {
          setExtendedCalendarOptions(true);
        }
        if (
          patientResponse.user.billing &&
          [
            BillingStatus.HEALTHY,
            BillingStatus.DUNNING,
            BillingStatus.PRECONFIRMATION,
          ].indexOf(patientResponse.user.billing?.billingStatus) < 0
        ) {
          setCanEditPatientFields(false);
        }
        methods.reset({
          selfManagement: patientResponse.selfManagement,
        });
        getDoctorsOptions(patientResponse);
      })
      .catch((error) => {
        onApiErrorToast(error);
        if (error.response?.status === 403) {
          navigate(PAGE_UNAUTHORIZED);
        } else if (error.response?.status === 404) {
          navigate(PAGE_NOT_FOUND);
        }
      });
  };
  useEffect(() => {
    getPatientProfile();
  }, [patientId]);

  const onSubmit = () => {
    // By default, getValues returns a flat array, so nest enables nested objects
    const values = getValues({ nest: true });
    // All this dosagePreferences is super hacky
    const prefix: string[] = patient.treatmentSchedules!.map(
      (schedule) =>
        `dosagePreferences.${schedule.substance}.${schedule.frequencyUnit}`
    );
    const dosageUnits = prefix.map((p) => `${p}.dosageUnit`);
    const dosageFrequencies = prefix.map((p) => `${p}.frequency`);

    // We convert all dosageFrequency work into one object for easier manipulation when sending to the server
    const combinedFormValues = convertSelectOptionsToValue(values, [
      'doctorId',
      'periodMargin',
      ...dosageUnits,
      ...dosageFrequencies,
    ]);

    const fieldsToCheckFor = [...dirtyFields];

    const dosagePreferences: Record<string, any>[] = [];
    if (combinedFormValues.dosagePreferences) {
      Object.entries(combinedFormValues.dosagePreferences).forEach(
        ([key, substanceFrequencyUnitObjects]) => {
          Object.entries(
            substanceFrequencyUnitObjects as Record<string, any>
          ).forEach(([frequencyUnit, scheduleValues]) => {
            const { frequency, dosageUnit } = scheduleValues as Record<
              string,
              any
            >;
            dosagePreferences.push({
              substance: key,
              frequency,
              dosageUnit,
              frequencyUnit,
            });
          });
        }
      );
    }

    const editValues = pick(combinedFormValues, fieldsToCheckFor) as any;
    if (dosagePreferences.length) {
      editValues.dosagePreferences = dosagePreferences;
    }
    if (Object.keys(editValues).length) {
      setIsSaving(true);
      editPatient(patient.getId(), editValues)
        .then((_updatedPatient: Patient) => {
          if (editValues.doctorId && editValues.doctorId !== patient.doctorId) {
            onSuccessToast(
              'Your new provider will receive a request to manage your treatment.'
            );
          }
          onSuccessToast('Changes saved!');
        })
        .catch((error) => onApiErrorToast(error))
        .finally(() => setIsSaving(false));
    } else {
      onWarningToast('No changes to save!');
    }
  };

  const handleDownloadCSV = () => {
    setIsDownloadingCSV(true);
    getAllTreatmentLogs(patient.getId())
      .then((logs) => {
        const filename = `${session.user.isAdmin() ? 'Anonymous' : patient.user.getFullName()
          } Treatment History ${moment().format('YYYY-MM-DD')}.csv`;

        const isSuccess = downloadJSONAsCSV(
          logs.logs.map((l) => l.convertToCSVReadable()),
          logs.fields,
          filename
        );
        if (!isSuccess) {
          onErrorToast("Sorry, it looks like we couldn't download this CSV.");
        }
        setIsDownloadingCSV(false);
      })
      .catch((error) => {
        setIsDownloadingCSV(false);
        onApiErrorToast(error);
      });
  };

  if (!hasLoaded) {
    return (
      <div className="page-wrapper">
        <Loader />
      </div>
    );
  }

  const selfManaged = (methods.watch('selfManagement') as boolean) || false;

  const shouldShowFrozenMessage =
    !session.isPatientView &&
    patient.user.billing?.billingStatus === BillingStatus.FROZEN;
  return (
    <>
      {!session.isPatientView && (
        <div className="page-wrapper">
          <PatientQuickNav patient={patient} />
        </div>
      )}
      <div className="page-wrapper">
        <div className="profile-container">
          <FormContext {...methods}>
            <form
              onSubmit={handleSubmit(() => {
                setIsConfirming(true);
              })}
            >
              <h4>Treatment Overview/Provider</h4>
              {session.isPatientView &&
                (
                  <div>
                    <Checkbox
                      isChecked={selfManaged}
                      inputName="selfManagement"
                      reversed
                      label="I want to manage my treatment myself"
                      onChange={(e) => {
                        setIsConfirmSwitchSelfManagement(true);
                      }}
                    />
                    <p className="description">
                      You wish to manage your own treatment without the oversight of
                      a provider. You can switch to a provider guided management at
                      any time.
                    </p>
                  </div>
                )}
              <div className="form-group doctor-select">
                {!selfManaged && (
                  <Dropdown
                    name="doctorId"
                    options={doctorOptions}
                    initialValue={
                      doctorOptions.find(
                        (option) => option.value === patient.doctorId
                      )?.value
                    }
                    disabled={!canEditDoctor}
                    validationOptions={
                      selfManaged
                        ? {}
                        : {
                          required: {
                            value: true,
                            message: 'required',
                          },
                        }
                    }
                  >
                    Who is your provider?
                  </Dropdown>
                )}
                {session.isPatientView && !canEditDoctor && !selfManaged && (
                  <Button
                    className="toggle-doctor-edit is-flat"
                    onClick={() => setIsConfirmEditDoctor(true)}
                  >
                    Update Provider
                  </Button>
                )}
              </div>
              <hr />
              <h4>Treatment Settings</h4>
              <br />
              <div className="form-group">
                <Dropdown
                  name="treatmentType"
                  options={TREATMENT_OPTIONS}
                  initialValue={
                    TREATMENT_OPTIONS.find(
                      (option) => option.value === patient.treatmentType
                    )?.value
                  }
                  disabled
                >
                  Treatment Type
                </Dropdown>
              </div>

              <DosagePreferences
                patient={patient}
                canEditPatientFields={canEditPatientFields}
              />

              <hr />
              <h4>Calendar Settings</h4>
              <br />
              <div className="form-group">
                <Dropdown
                  name="calendarType"
                  options={CALENDAR_TYPE_OPTIONS}
                  initialValue={
                    CALENDAR_TYPE_OPTIONS.find(
                      (option) => option.value === patient.calendarType
                    )?.value
                  }
                  disabled
                >
                  Calendar Type
                </Dropdown>
              </div>
              {showExtendedCalendarOptions && (
                <details>
                  <summary><strong>Details</strong><hr /></summary>
                  <div className='content'>
                    <div className="form-group">
                      <label>
                        Average Days of Bleed (in days)
                        <p className="description">
                          The average number of days your period lasts for
                        </p>
                        <input
                          type="number"
                          name="averagePeriodDuration"
                          className="is-textbox"
                          min={1}
                          max={10}
                          placeholder="5 Days"
                          disabled={!canEditPatientFields}
                          defaultValue={patient.averagePeriodDuration}
                          ref={register({
                            required: 'Required',
                            min: {
                              value: 1,
                              message: 'Must be greater than 1 day',
                            },
                            max: {
                              value: 10,
                              message: 'Cannot be greater than 10 days',
                            },
                          })}
                        />
                        <span className="error">
                          <ErrorMessage
                            errors={errors}
                            name="averagePeriodDuration"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="form-group">
                      <label>
                        Average Cycle Length
                        <p className="description">
                          The average number of days between periods
                        </p>
                        <b className="description warning-text">
                          {session.isPatientView && !session.isSelfManagedView && (
                            <>
                              Changing this will recalculate your future cycle
                              days based on the new length, and may impact
                              non-repeated changes or notes left by your Provider.
                            </>
                          )}
                          {session.canAccessAdditionalViews && (
                            <>
                              <strong>Warning:</strong> changing this number will
                              delete all non repeating dose changes and
                              recalculate future cycle day 1&apos;s going forward.
                            </>
                          )}
                        </b>
                        <input
                          type="number"
                          name="averagePeriodCycleLength"
                          className="is-textbox"
                          placeholder="28 days"
                          disabled={!canEditPatientFields}
                          ref={register({
                            required: 'Required',
                            min: {
                              value: 20,
                              message: 'Must be greater than 20 days',
                            },
                            max: {
                              value: 50,
                              message: 'Cannot be greater than 50 days',
                            },
                          })}
                          defaultValue={patient.averagePeriodCycleLength}
                          min={20}
                          max={50}
                        />
                        <span className="error">
                          <ErrorMessage
                            errors={errors}
                            name="averagePeriodCycleLength"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="profile-calendartype">
                        Period Margin
                        <p className="description">
                          Notify when {session.isSelfManagedView ? 'my ' : ''}period start or bleed is early or late by more than a set number of days.
                        </p>
                        <br />
                        <Controller
                          as={
                            <Select
                              classNamePrefix="react-select"
                              className="react-select-container"
                              options={PERIOD_MARGIN_OPTIONS}
                            />
                          }
                          isDisabled={
                            !canEditPatientFields ||
                            !session.canAccessAdditionalViews
                          }
                          control={control}
                          defaultValue={PERIOD_MARGIN_OPTIONS.find(
                            (option) => option.value === patient.periodMargin
                          )}
                          name="periodMargin"
                        />
                        <span className="error">
                          {errors.periodMargin && errors.periodMargin.message}
                        </span>
                      </label>
                    </div>
                  </div>
                </details>
              )}
              <Button
                type="submit"
                className=""
                isLoading={isSaving}
                isDisabled={shouldShowFrozenMessage}
              >
                Save
              </Button>
              {session.isAdmin && (
                <Link
                  to={`/user/${patient.user.getId()}/settings`}
                  className="button is-link text-centered"
                >
                  [ADMIN] View User Settings
                </Link>
              )}
            </form>
          </FormContext>
          <ConfirmModal
            onCancel={() => {
              setIsConfirming(false);
            }}
            onConfirm={() => {
              setIsConfirming(false);
              onSubmit();
            }}
            isOpen={isConfirming}
            message="Are you sure want to save these changes?"
          />
          {session.isPatientView && (
            <>
              <ConfirmModal
                onCancel={() => {
                  setIsConfirmEditDoctor(false);
                }}
                onConfirm={() => {
                  setIsConfirmEditDoctor(false);
                  setCanEditDoctor(true);
                }}
                isOpen={isConfirmEditDoctor}
                title="Are you sure?"
                message="You are trying to change your provider. After selecting a new provider and saving, you will NOT be able to access your calendar until the new provider approves your request."
              />
              <ConfirmModal
                onCancel={() => {
                  setIsConfirmSwitchSelfManagement(false);
                  methods.setValue('selfManagement', !selfManaged);
                }}
                onConfirm={() => {
                  setIsConfirmSwitchSelfManagement(false);
                  setCanEditDoctor(!selfManaged);
                }}
                isOpen={isConfirmSwitchSelfManagement}
                title="Are you sure?"
                message={
                  selfManaged
                    ? `Are you sure you want to switch to a self managed experience? After saving, your existing provider will be notified that you are switching to self managed. Be sure to click save at the bottom of the page after to save your changes.`
                    : `Are you sure you want to switch to a provider guided experience? After selecting a new provider and saving, you will NOT be able to access your calendar until the new provider approves your request. Be sure to click save at the bottom of the page after to save your changes.`
                }
              />
            </>
          )}
          <div className="profile-actions">
            <h3>Profile Actions</h3>
            <Button onClick={handleDownloadCSV} isLoading={isDownloadingCSV}>
              Download Treatment History
            </Button>
            <p className="description">
              Download a CSV with all of{' '}
              {patient.user.getId() !== session.user.getId()
                ? `${patient.user.getFullName()}'s `
                : 'your '}
              treatment history.
            </p>
            {session.isAdmin && (
              <Link to={`/doctor/${patient.doctorId}`}>
                <Button>Go to Doctor Page</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PatientProfile;
