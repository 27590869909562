import React, { useState, useEffect } from 'react';
import './clinics.page.css';
import { PaginationInfo, SortDirection } from '../../../types';
import { getClinicsSummary } from '../../../services/admin.service';
import { onApiErrorToast } from '../../../services';
import { PaginatedSearchRequest } from '../../../types/apiResponse.types';
import { Clinic } from '../../../models';
import ClinicTableItem from '../../features/admin/clinicTableItem';
import { TableHeader, TitleBlock, Table } from '../../shared';

function ClinicsPage() {
  const [clinics, setClinics] = useState(
    [] as (Clinic & { doctorCount: number })[]
  );

  const [isLoadingClinics, setIsLoadingClinics] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState({
    sortDirection: 'ASC',
    sortBy: 'alphabetical',
    pageNumber: 1,
    pageSize: 10,
    searchTerm: '',
  } as PaginationInfo & { searchTerm: string });

  const fetchUsers = (params: PaginatedSearchRequest): void => {
    setIsLoadingClinics(true);
    getClinicsSummary(params)
      .then((newClinics) => {
        setClinics(newClinics.results);
        setPaginationInfo({
          ...paginationInfo,
          total: newClinics.total,
        });
      })
      .catch((error) => {
        onApiErrorToast(error);
      })
      .finally(() => {
        setIsLoadingClinics(false);
      });
  };

  useEffect(() => {
    fetchUsers({
      ...paginationInfo,
    });
  }, [
    paginationInfo.pageNumber,
    paginationInfo.sortBy,
    paginationInfo.sortDirection,
    paginationInfo.searchTerm,
  ]);

  const tableHeaders = [
    { label: 'Clinic', sortKey: 'alphabetical' },
    { label: 'Total Doctors', sortKey: 'doctorCount' },
    { label: 'Created Date' },
  ] as TableHeader[];

  return (
    <section className="clinics-page">
      <div className="page-wrapper is-flex-column">
        <TitleBlock title="Clinics" />
        <Table
          headers={tableHeaders}
          collection={clinics.map((clinic) => ({
            id: clinic.getId(),
            clinic,
          }))}
          component={ClinicTableItem}
          onSort={(
            sortBy: string,
            sortDirection: SortDirection,
            searchTerm?: string
          ) =>
            setPaginationInfo({
              ...paginationInfo,
              sortBy,
              sortDirection,
              pageNumber: 1,
              searchTerm: searchTerm || '',
            })
          }
          onSearch={(searchTerm: string) =>
            setPaginationInfo({ ...paginationInfo, searchTerm, pageNumber: 1 })
          }
          isFetchingData={isLoadingClinics}
          paginationInfo={paginationInfo}
          itemLabel="clinics"
          searchPlaceholder="Search by clinic name"
          onPageChange={(newPageNumber: number, search?: string) => {
            setPaginationInfo({
              ...paginationInfo,
              pageNumber: newPageNumber,
              searchTerm: search || '',
            });
          }}
        />
      </div>
    </section>
  );
}
export default ClinicsPage;
