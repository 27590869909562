/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import './patientTableItem.scss';
import { Patient, PatientStatus } from '../../../models';
import { Button } from '../../shared';
import { useSessionContext } from '../../shared/global/sessionContext';

type PatientTableItemProps = {
  patient: Patient;
  onClickStatus: (patient: Patient, status: PatientStatus) => Promise<void>;
};

const PendingPatientTableItem: React.FC<PatientTableItemProps> = ({
  patient,
  onClickStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [session] = useSessionContext();
  const setOnClickAction = (status: PatientStatus) => {
    setIsLoading(true);
    onClickStatus(patient, status).finally(() => {
      setIsLoading(false);
    });
  };

  const actions = (
    <>
      <Button
        isLoading={isLoading}
        onClick={() => setOnClickAction(PatientStatus.ACCEPTED)}
      >
        <i className="fas fa-check" />
        <span className="icon-text">Accept</span>
      </Button>
      <Button
        isLoading={isLoading}
        onClick={() => setOnClickAction(PatientStatus.REJECTED)}
        className="danger"
      >
        <i className="fas fa-times" />
        <span className="icon-text">Reject</span>
      </Button>
    </>
  );

  return (
    <>
      <tr className="patient-table-item pending-patients">
        <td className="name">
          <strong>{patient.user.getFullName()}</strong>
          <p>
            Email:{' '}
            <a
              href={`mailto:${patient.user.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {patient.user.email}
            </a>
          </p>
          <p>Date of Birth: {patient.user.dateOfBirth || 'N/A'}</p>
          <p>
            Location: {patient.country || 'N/A'}, {patient.state || 'N/A'}
          </p>
          <p>Timezone: {patient.user.timezone || 'N/A'}</p>
        </td>
        <td className="treatment-type">
          {patient.getTreatmentTypeFormatted()}
        </td>
        <td className="calendar-type capitalized">{patient.calendarType}</td>
        <td className="actions desktop-only">
          {session.isDoctorView ? actions : null}
        </td>
      </tr>
      {/* TODO: pretty crummy solution */}
      <tr className="patient-table-item mobile-only">
        <td colSpan={4}>
          <span className="actions">
            {session.isDoctorView ? actions : null}
          </span>
        </td>
      </tr>
    </>
  );
};

export default PendingPatientTableItem;
