import axios from 'axios';
import { AUTH_URL, TIME_BEFORE_REFRESH_EXPIRE } from '../util/constants';
import { LoginFormData, RegisterFormData } from '../types';
import { setInMemorySessionToken } from '../components/shared/global/sessionContext';

// Last timeout id for generating a new access token
let generateNewAccessTokenTimeoutId: number | undefined;

const clearRetryTimeout = () => {
  if (generateNewAccessTokenTimeoutId) {
    window.clearTimeout(generateNewAccessTokenTimeoutId);
  }
};

/**
 * Creates a timeout for making a request to generate a new access + refresh token
 * We see when the access token is going to expire and send a request for a new token a short period before the expiry time
 * @param token
 */
const setTimerForNextRefresh = (token: API_ACCESS_TOKEN_RESPONSE) => {
  const nextRetry = token.expiresIn - TIME_BEFORE_REFRESH_EXPIRE;
  clearRetryTimeout();
  generateNewAccessTokenTimeoutId = window.setTimeout(
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    () => refreshToken(),
    nextRetry
  );
};

export type API_ACCESS_TOKEN_RESPONSE = {
  token: string;
  expiresIn: number;
};

const registerNewUser = async ({
  firstName,
  lastName,
  email,
  password,
  userType,
  timezone,
  howDidYouHearAboutUs,
}: RegisterFormData): Promise<API_ACCESS_TOKEN_RESPONSE> => {
  const result = await axios.post<API_ACCESS_TOKEN_RESPONSE>(
    `${AUTH_URL}/register`,
    {
      firstName,
      lastName,
      email,
      password,
      userType,
      timezone,
      howDidYouHearAboutUs,
    }
  );

  if (result.status === 201) {
    setInMemorySessionToken(result.data.token, result.data.expiresIn);
    setTimerForNextRefresh(result.data);
  }
  return result.data;
};

const login = async ({
  email,
  password,
}: LoginFormData): Promise<API_ACCESS_TOKEN_RESPONSE> => {
  const result = await axios.post<API_ACCESS_TOKEN_RESPONSE>(
    `${AUTH_URL}/login`,
    {
      email,
      password,
    }
  );
  if (result.status === 200) {
    setInMemorySessionToken(result.data.token, result.data.expiresIn);
    setTimerForNextRefresh(result.data);
  }

  return result.data;
};

const logout = async (): Promise<void> => {
  setInMemorySessionToken(null, 0);
  clearRetryTimeout();
  await axios.get<string>(`${AUTH_URL}/logout`);
};

/**
 * Make a call to regenerate a new access token. Requires a valid refresh token cookie header
 */
const refreshToken = async (): Promise<API_ACCESS_TOKEN_RESPONSE> => {
  const result = await axios.get<API_ACCESS_TOKEN_RESPONSE>(
    `${AUTH_URL}/refresh-token`
  );
  setInMemorySessionToken(result.data.token, result.data.expiresIn);
  setTimerForNextRefresh(result.data);

  return result.data;
};

const forgotPassword = async (email: string): Promise<void> => {
  await axios.post(`${AUTH_URL}/forgot-password`, {
    email,
  });
};

const resetPassword = async (
  token: string,
  password: string
): Promise<void> => {
  await axios.post(`${AUTH_URL}/reset-password`, {
    token,
    password,
  });
};

export {
  login,
  logout,
  refreshToken,
  registerNewUser,
  forgotPassword,
  resetPassword,
};
