import React from 'react';
import './modal.scss';
import './confirmModal.scss';
import classNames from 'classnames';
import { TitleBlock, Button } from '..';

export type ConfirmModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  message?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  reverseOrder?: boolean;
};
// TODO: when modal is open remove scrolling  in the main page
export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  children,
  onCancel,
  onConfirm,
  isOpen,
  message,
  title,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  // TODO: this reverse order thing is stupid
  reverseOrder,
}) => (
  <>
    {isOpen && (
      <section className="modal-container confirm-modal">
        <div className="modal-background" />
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <header>
            <TitleBlock title={title || 'Confirmation'} />
            <Button
              className="close is-icon"
              onClick={reverseOrder ? onConfirm : onCancel}
              tooltipText="Cancel"
            >
              <i className="fas fa-times" />
            </Button>
          </header>
          <div className="modal-body">
            <p className="confirm-message text-centered">{message}</p>
            {children}
            <div
              className={classNames('actions-wrapper', {
                reversed: reverseOrder,
              })}
            >
              <Button onClick={onCancel} className="danger">
                {cancelText}
              </Button>
              <Button onClick={onConfirm} className="is-primary">
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      </section>
    )}
  </>
);

export default ConfirmModal;
