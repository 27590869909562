import React, { useEffect } from 'react';
import './notAuthorized.page.css';
import { Link } from 'react-router-dom';
import { unauthenticatedImage } from '../../../assets';
import { setPageTitle } from '../../shared/header/header';
import { LogoBlock, Button } from '../../shared';

const NotAuthorizedPage: React.FC = () => {
  useEffect(() => {
    setPageTitle('Unauthorized');
  });

  return (
    <section className="not-authenticated">
      <div className="page-wrapper has-box-shadow">
        <div className="text text-left">
          <LogoBlock />
          <p className="description">
            You do not have the proper permissions to view this page or perform
            this action.
          </p>
          <p className="description">
            Click the button to go back to your homepage.
          </p>
          <Link to="/">
            <Button>Back to Home</Button>
          </Link>
        </div>
        <img src={unauthenticatedImage} alt="Unauthorized" />
      </div>
    </section>
  );
};
export default NotAuthorizedPage;
