import React, { useState, useEffect } from 'react';
import './toast.scss';
import classNames from 'classnames';
import { ToastType } from '../../../services/toasts.service';

// TODO: children
export type ToastProps = {
  id: string;
  message: string;
  type: ToastType;
  onClose: (id: string) => void;
  title?: string;
  autoRemove?: boolean;
  onClick?: () => void;
};

export const Toast: React.FC<ToastProps> = ({
  id,
  message,
  type = ToastType.error,
  title,
  onClose,
  onClick,
  autoRemove = true,
}) => {
  const [shouldSlideOut, setSlideOut] = useState(false);

  useEffect(() => {
    if (autoRemove) {
      setTimeout(() => {
        setSlideOut(true);
        setTimeout(() => {
          onClose(id);
        }, 1000);
      }, 4000);
    }
  }, [autoRemove, id, onClose]);

  const onCloseClick = () => {
    setSlideOut(true);
    setTimeout(() => {
      onClose(id);
    }, 1000);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNames('toast', type, {
        fadeout: shouldSlideOut,
        clickable: !!onClick,
      })}
      onClick={onClick}
    >
      <div className="wrapper">
        <div className="header">
          <span className="icon">
            {type === ToastType.success && (
              <i className="fas fa-check-circle" />
            )}
            {type === ToastType.error && (
              <i className="fas fa-exclamation-triangle" />
            )}
            {type === ToastType.warning && <i className="fas fa-exclamation" />}
            {type === ToastType.info && <i className="fas fa-info" />}
          </span>
          <strong className="title">{title || type}</strong>
        </div>
        <div className="message">{message}</div>
      </div>
      <button type="button" className="close is-icon" onClick={onCloseClick}>
        <i className="fas fa-times" />
      </button>
    </div>
  );
};
